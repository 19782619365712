import React from "react";

import { BasePageProps } from "./interfaces";
import useStyles from "./styles";

const BasePage: React.FC<BasePageProps> = props => {
    const classes = useStyles();
    const { headerComponent, contentComponent } = props;
    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>{headerComponent}</div>
            <div className={classes.content}>{contentComponent}</div>
        </div>
    );
};

export default BasePage;
