import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DocumentCard } from "components/molecules/DocumentCard";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useStore } from "stores";

import { onDownloadCallback } from "../FileLoading.utils";
import { onUploadCallback } from "./Agreement.utils";

const Agreement: React.FC<any> = props => {
    const { selectedClientId, disabled } = props;
    const { clientsStore, filesStore } = useStore();

    const [loaded, setLoaded] = useState<any>(false);
    const [documentData, serDocumentData] = useState<any>(false);
    const [url, setUrl] = useState<any>(false);

    const [message, setMessage] = useState("");
    const onCloseAlert = useCallback(() => {
        setMessage("");
    }, []);

    useEffect(() => {
        if (!loaded) {
            (async function () {
                setLoaded(false);
                const data = await clientsStore.getAgreement(selectedClientId);
                serDocumentData(data);
                const resUrl = await filesStore.getFile(data.id);
                setUrl(resUrl);
                setLoaded(true);
            })();
        }
    }, [loaded, selectedClientId]);

    const onDownload = onDownloadCallback(url, documentData);

    const onUpload = onUploadCallback(selectedClientId, setMessage, setLoaded);

    const onDelete = useCallback(async () => {
        const result = await clientsStore.deleteAgreement(selectedClientId);
        if (result) {
            setMessage("Agreement deleted.");
            setLoaded(false);
        }
    }, [selectedClientId]);

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="h6">{"Agreement"}</Typography>
            </Grid>
            <Grid item>
                <Button
                    onClick={onUpload}
                    color="primary"
                    variant="contained"
                    disabled={disabled}
                >
                    {"Upload agreement"}
                </Button>
            </Grid>
            <Grid item>
                {message && (
                    <Alert
                        variant="outlined"
                        severity="error"
                        icon={false}
                        onClose={onCloseAlert}
                    >
                        {message}
                    </Alert>
                )}
            </Grid>
            <Grid item>
                {loaded ? (
                    <>
                        {url ? (
                            <DocumentCard
                                documentName="Agreement"
                                onDownload={onDownload}
                                onDelete={onDelete}
                                disabled={disabled}
                            />
                        ) : (
                            <>{"Agreement not found"}</>
                        )}
                    </>
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        </Grid>
    );
};

export default observer(Agreement);
