import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getClients = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    searchString?: string
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/Clients${params}`);
};
export const getClient = (id: number) => {
    return $axios.get(`/api/Clients/${id}`);
};

export const setClient = (data: any) => {
    return $axios.post(`/api/Clients/SignUp`, data);
};

export const updateClient = (id: number, data: any) => {
    return $axios.put(`/api/Clients/${id}`, data);
};

export const deleteClient = (id: number) => {
    return $axios.delete(`/api/Clients/${id}`);
};

export const updatePassword = (data: any) => {
    return $axios.post(`/api/Clients/ChangePassword`, data);
};

export const getPhoto = (id: number) => {
    return $axios.get(`/api/Clients/${id}/photo`, {
        responseType: "blob"
    });
};

export const setPhoto = (id: number, data: any) => {
    return $axios.post(`/api/Clients/${id}/photo`, data);
};

export const deletePhoto = (id: number) => {
    return $axios.delete(`/api/Clients/${id}/photo`);
};

export const getAgreement = (id: number) => {
    return $axios.get(`/api/Clients/${id}/agreement`);
};

export const setAgreement = (id: number, data: any) => {
    return $axios.post(`/api/Clients/${id}/agreement`, data);
};

export const deleteAgreement = (id: number) => {
    return $axios.delete(`/api/Clients/${id}/agreement`);
};
