import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            padding: theme.spacing(1),
            display: "flex",
            overflow: "hidden",
            width: "100%"
        },
        imageList: {
            flexWrap: "nowrap"
        }
    })
);
