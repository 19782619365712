import { Box, Grid, IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import React, { useCallback, useState } from "react";

import { getChangePasswordFormControls } from "./ChangePasswordForm.utils";

const ChangePasswordForm: React.FC<any> = props => {
    const { selectedId, onSubmit, actionButtonText } = props;

    const { control, onFormSubmit, generateNewPassword } =
        getChangePasswordFormControls(selectedId, onSubmit);

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = useCallback(
        () => setShowPassword(prevState => !prevState),
        []
    );

    return (
        <form onSubmit={onFormSubmit}>
            <Grid item xs>
                <TextFieldController
                    control={control}
                    name="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleShowPassword}>
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextFieldController
                    control={control}
                    name="passwordConfirmation"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Confirm password"
                    type="password"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={generateNewPassword}
                >
                    {"Generate password"}
                </Button>
            </Grid>
            <Box mt={1}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {actionButtonText}
                </Button>
            </Box>
        </form>
    );
};
export default ChangePasswordForm;
