import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { EmployeesContent } from "./Components/EmployeesContent";
import { EmployeesHeader } from "./Components/EmployeesHeader";

const Employees: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<EmployeesHeader />}
                contentComponent={<EmployeesContent />}
            />
        </HeaderWrapper>
    );
};

export default Employees;
