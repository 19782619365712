import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { StylesProps } from "./interfaces";

export default makeStyles((theme: Theme) =>
    createStyles({
        drawerPaper: {
            position: "relative",
            whiteSpace: "nowrap",
            padding: theme.spacing(3, 2),
            width: (props: StylesProps) => props.drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        drawerPaperClose: {
            overflow: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: (props: StylesProps) => props.drawerCloseWidth
        },
        activeColor: {
            color: theme.palette.primary.main
        },
        hidden: {
            visibility: "hidden"
        }
    })
);
