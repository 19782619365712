import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getOrderReports = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    searchString?: string
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/OrderReports${params}`);
};
