import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router";
import Actions from "services/enums/Actions";
import SistemRole from "services/enums/SistemRole";
import { useStore } from "stores";

import { ChangeClientPasswordTab } from "../ChangeClientPasswordTab";
import { ClientProfileTab } from "../ClientProfileTab";
import { DocumentTab } from "./DocumentTab";
import { ClientProfileContentProps } from "./interfaces";

const ClientProfileContent: React.FC<ClientProfileContentProps> = props => {
    const { selectedClient } = props;

    const { loginStore } = useStore();
    const canChangePassword = loginStore.user?.role === SistemRole.ADMIN;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const { state } = useLocation<{ action?: Actions }>();
    const disabled = state?.action !== Actions.UPDATE;

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);

    return (
        <Paper elevation={3}>
            <Box pt={2} px={2}>
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Profile" />

                    <Tab label="Personal document" />
                    {canChangePassword && !disabled && (
                        <Tab label="Change password" />
                    )}
                </Tabs>
            </Box>
            <TabPanel value={selectedTabIndex} index={0}>
                <ClientProfileTab
                    selectedClient={selectedClient}
                    disabled={disabled}
                />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1}>
                <Box p={2}>
                    <DocumentTab
                        selectedClientId={selectedClient?.id}
                        disabled={disabled}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={2}>
                <ChangeClientPasswordTab selectedClient={selectedClient} />
            </TabPanel>
        </Paper>
    );
};

export default observer(ClientProfileContent);
