import { Grid, Typography } from "@material-ui/core";
import React from "react";

const ClientHeader: React.FC = props => {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{"Clients"}</Typography>
            <Grid item>
                {/*
        TODO add select date
        */}
            </Grid>
        </Grid>
    );
};

export default ClientHeader;
