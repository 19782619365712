import axios from "axios";
import { getAccessToken } from "services/utils/LocalStorageHelper";

const $axios = axios.create({
    withCredentials: true
});

$axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
    return config;
});

export default $axios;
