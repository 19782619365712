import {
    Box,
    Collapse,
    Grid,
    TableCell,
    TableRow,
    Typography
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import React from "react";

import { ImageReportList } from "../ImageReportList";

const ReportCollapseContent: React.FC<any> = props => {
    const {
        photoReports,
        comment,
        commentReport,
        commentFeedback,
        gradeFeedback
    } = props;

    const noInfo =
        !photoReports?.length && !comment && !commentReport && !commentFeedback;

    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                <Collapse in timeout="auto" unmountOnExit>
                    {!!photoReports?.length && (
                        <ImageReportList photoReports={photoReports} />
                    )}
                    <Box margin={1}>
                        {noInfo && (
                            <>
                                <Typography variant="body2">
                                    {
                                        "There are no photos and comments to the report."
                                    }
                                </Typography>
                            </>
                        )}
                        {comment && (
                            <>
                                <Typography variant="caption">
                                    {"Comment:"}
                                </Typography>
                                <Typography variant="body2">
                                    {comment}
                                </Typography>
                            </>
                        )}
                        {commentReport && (
                            <>
                                <Typography variant="caption">
                                    {"Report:"}
                                </Typography>
                                <Typography variant="body2">
                                    {commentReport}
                                </Typography>
                            </>
                        )}
                        {commentFeedback && (
                            <>
                                <Typography variant="caption">
                                    {"Feedback:"}
                                </Typography>
                                <Grid>
                                    <Rating
                                        value={gradeFeedback}
                                        readOnly
                                        size="small"
                                    />
                                </Grid>
                                <Typography variant="body2">
                                    {commentFeedback}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};
export default ReportCollapseContent;
