import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-hook-form";
import Actions, { getActionLabel } from "services/enums/Actions";
import { Training } from "services/models/TrainingsModels";
import { useStore } from "stores";
import * as yup from "yup";

import { TrainingsFormProps, TrainingsFormCallProps } from "./interfaces";

const TrainingsForm: React.FC<TrainingsFormProps & TrainingsFormCallProps> =
    props => {
        const { onSubmit, selectedTrainingId, action } = props;
        const disabled = action === Actions.VIEW;
        const { trainingsStore } = useStore();

        const validators = yup.object().shape({
            url: yup.string().trim().required("Field is required").nullable(),
            description: yup
                .string()
                .trim()
                .required("Field is required")
                .nullable(),
            name: yup.string().trim().required("Field is required").nullable(),
            hashtags: yup.string(),
            author: yup.string().trim().required("Field is required").nullable()
        });

        const defaultTraining = new Training(
            toJS(
                trainingsStore.trainings.find(i => i.id === selectedTrainingId)
            )
        );

        const { control, handleSubmit } = useForm({
            mode: "onBlur",
            resolver: yupResolver(validators),
            defaultValues: defaultTraining
        });

        const onFormSubmit = handleSubmit(data => {
            if (action === Actions.UPDATE) {
                trainingsStore.updateTraining(Number(selectedTrainingId), data);
            }
            if (action === Actions.CREATE) {
                trainingsStore.addTraining(data);
            }

            onSubmit();
        });

        return (
            <form onSubmit={onFormSubmit}>
                <TextFieldController
                    control={control}
                    disabled={disabled}
                    name="url"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="url"
                    label="Url"
                />
                <TextFieldController
                    control={control}
                    disabled={disabled}
                    name="description"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="description"
                    label="Description"
                />
                <TextFieldController
                    control={control}
                    disabled={disabled}
                    name="name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Name"
                />
                <TextFieldController
                    control={control}
                    disabled={disabled}
                    name="hashtags"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="hashtags"
                    label="Hashtags"
                />
                <TextFieldController
                    control={control}
                    disabled={disabled}
                    name="author"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="author"
                    label="Author"
                />
                {!disabled && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        {getActionLabel(action)}
                    </Button>
                )}
            </form>
        );
    };
export default observer(TrainingsForm);
