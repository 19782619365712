import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

const NfcTagsHeader: React.FC = () => {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{"Nfc tags"}</Typography>
        </Grid>
    );
};

export default observer(NfcTagsHeader);
