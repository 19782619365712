import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import React, { useCallback, useState } from "react";

import { ClientTab } from "../ClientTab";
import { ObjectTab } from "../ObjectTab";

const ClientContent: React.FC = props => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);

    return (
        <Paper elevation={3}>
            <Box pt={2} px={2}>
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Clients" />
                    <Tab label="Objects" />
                </Tabs>
            </Box>
            <TabPanel value={selectedTabIndex} index={0}>
                <ClientTab />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1}>
                <ObjectTab />
            </TabPanel>
        </Paper>
    );
};

export default ClientContent;
