import Rating from "@material-ui/lab/Rating";
import {
    BaseTable,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { Feedback } from "services/models/FeedbackModel";
import { useStore } from "stores";

const FeedbacksTable: React.FC<any> = props => {
    const { searchString } = props;
    const { feedbacksStore } = useStore();

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const clientObjectsHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "name", label: "Name", sorted: true },
        { id: "clientName", label: "Client", sorted: true },
        { id: "clientObjectAddress", label: "Address", sorted: true },
        { id: "comment", label: "Comment", sorted: true },
        { id: "grade", label: "Grade", sorted: true }
    ];

    const getClientObjectsAlertRowContent = useCallback(
        (row: Feedback) => ({
            rowContent: [
                row.id,
                row.name,
                row.clientName,
                row.clientObjectAddress,
                row.comment,
                <>
                    <Rating value={row.grade} readOnly size="small" />
                </>
            ]
        }),

        []
    );

    const getFeedbacks = useCallback(() => {
        feedbacksStore.getFeedbacks(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getFeedbacks();
    }, [getFeedbacks]);

    useEffect(() => {
        if (feedbacksStore.needUpdate) {
            getFeedbacks();
        }
    }, [getFeedbacks, feedbacksStore.needUpdate]);

    const clientObjectsRowCells = feedbacksStore.feedbacks.map(row =>
        getClientObjectsAlertRowContent(row)
    );

    return (
        <>
            <BaseTable
                headCells={clientObjectsHeadCells}
                rowCells={clientObjectsRowCells}
                // TODO add totalCount and pagination
                rowCellsСount={feedbacksStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
        </>
    );
};

export default observer(FeedbacksTable);
