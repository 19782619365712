import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import React, { useCallback } from "react";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";

const ConfirmActionDialogWrapper: React.FC<any> = props => {
    const {
        children,
        onConfirmClick,
        title = "",
        content = "Confirm action?",
        agreeButton = "Agree",
        disagreeButton = "Disagree"
    } = props;

    const [
        isConfirmActionDialogOpen,
        openConfirmActionDialog,
        closeConfirmActionDialog
    ] = useBooleanState(false);

    const сonfirmHendle = useCallback(() => {
        onConfirmClick();
        closeConfirmActionDialog();
    }, [onConfirmClick]);

    return (
        <>
            {children(openConfirmActionDialog)}
            <Dialog
                open={isConfirmActionDialogOpen}
                onClose={closeConfirmActionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmActionDialog}>
                        {disagreeButton}
                    </Button>
                    <Button onClick={сonfirmHendle} autoFocus color="primary">
                        {agreeButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ConfirmActionDialogWrapper;
