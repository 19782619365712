/* eslint-disable react/jsx-no-bind */
import { CircularProgress } from "@material-ui/core";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "stores";

import { ManagerProfileContent } from "./Components/ManagerProfileContent";
import { ManagerProfileHeader } from "./Components/ManagerProfileHeader";

const ManagerProfile: React.FC = () => {
    const { managersStore } = useStore();

    const { id } = useParams<any>();

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const result = managersStore.getSelectedManager(id);
        result.then(res => {
            setLoaded(true);
        });
    }, [id]);
    const selectedManager = managersStore.selectedManager;

    return (
        <HeaderWrapper>
            {loaded ? (
                <BasePage
                    headerComponent={
                        <ManagerProfileHeader header={selectedManager?.name} />
                    }
                    contentComponent={
                        <ManagerProfileContent
                            selectedManager={selectedManager}
                        />
                    }
                />
            ) : (
                <CircularProgress />
            )}
        </HeaderWrapper>
    );
};

export default observer(ManagerProfile);
