import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { NewManagerProfileContent } from "./Components/NewManagerProfileContent";
import { NewManagerProfileHeader } from "./Components/NewManagerProfileHeader";

const NewManagerProfile: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<NewManagerProfileHeader />}
                contentComponent={<NewManagerProfileContent />}
            />
        </HeaderWrapper>
    );
};

export default NewManagerProfile;
