import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        loginPage: {
            height: "100%",
            display: "flex",
            alignSelf: "stretch"
        },
        background: {
            width: "60%",
            background: `url(${"https://picsum.photos/1000/1200"})`,
            backgroundSize: "cover",

            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        },
        form: {
            width: "40%",
            padding: "0 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    })
);
