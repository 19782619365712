import LuxonUtils from "@date-io/luxon";
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React, { useCallback } from "react";
import { Controller } from "react-hook-form";
import { DisplayDateType } from "services/enums/DisplayDateType";

const DateFieldController: React.FC<any> = props => {
    const { name, control, type, ...rest } = props;

    const Component =
        type === DisplayDateType.DATE
            ? KeyboardDatePicker
            : KeyboardDateTimePicker;

    const format =
        type === DisplayDateType.DATE ? "dd/MM/yyyy" : "dd/MM/yyyy, HH:mm";

    const render = useCallback(
        ({ field: { onChange, value }, fieldState: { error } }) => {
            return (
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Component
                        {...rest}
                        format={format}
                        value={value}
                        onChange={onChange}
                        error={error}
                        helperText={error ? error.message : null}
                    />
                </MuiPickersUtilsProvider>
            );
        },
        [rest]
    );
    return <Controller control={control} name={name} render={render} />;
};
export default DateFieldController;
