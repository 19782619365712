import { Box, IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { PhoneTextFieldController } from "components/molecules/FormControls/PhoneTextFieldController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import React, { useCallback, useState } from "react";
import Actions, { getActionLabel } from "services/enums/Actions";

import { getNewClientFormControls } from "./NewClientForm.utils";

const NewClientForm: React.FC<any> = props => {
    const { onSubmit } = props;
    const { control, onFormSubmit, generateNewPassword } =
        getNewClientFormControls(onSubmit);

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = useCallback(
        () => setShowPassword(prevState => !prevState),
        []
    );

    return (
        <form onSubmit={onFormSubmit}>
            <TextFieldController
                control={control}
                name="name"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Name"
            />
            <TextFieldController
                control={control}
                name="email"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Email"
            />
            <PhoneTextFieldController
                control={control}
                name="contactPhone"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Contact phone"
            />
            <TextFieldController
                control={control}
                name="password"
                variant="outlined"
                margin="normal"
                fullWidth
                label="password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword}>
                                {showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <TextFieldController
                control={control}
                name="passwordConfirmation"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Confirm password"
                type="password"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={generateNewPassword}
            >
                {"Generate password"}
            </Button>

            <Box mt={1}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {getActionLabel(Actions.CREATE)}
                </Button>
            </Box>
        </form>
    );
};
export default NewClientForm;
