import { yupResolver } from "@hookform/resolvers/yup";
import { toJS } from "mobx";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Device } from "services/models/DeviceModel";
import { useStore } from "stores";
import * as yup from "yup";

function getFormControls(devices) {
    const validators = yup.object().shape({
        uid: yup.string().trim().nullable(),
        password: yup.string().trim().nullable(),
        name: yup.string().trim().nullable(),
        timeoutBeforeAlertMin: yup
            .number()
            .required("Field is required")
            .nullable(),
        timeoutAfterAlertMin: yup
            .number()
            .required("Field is required")
            .nullable(),
        freeTimeoutsAmountMin: yup
            .number()
            .required("Field is required")
            .nullable(),
        clientId: yup.number().nullable(),
        clientObjectId: yup.number().nullable()
    });

    const { control, handleSubmit, watch } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: devices
    });

    return {
        control,
        handleSubmit,
        watch
    };
}

function getSelectedDevice(devices, selectedDeviceId) {
    const selectedDevice = new Device(
        toJS(devices.find(i => i.id === selectedDeviceId))
    );
    return selectedDevice;
}

export function getDeviceFormControls(selectedDeviceId, onSubmit) {
    const { devicesStore, сlientObjectsStore, clientsStore } = useStore();

    useEffect(() => {
        сlientObjectsStore.getClientObjects();
        clientsStore.getClients();
    }, []);

    const selectedDevice = useMemo(
        () => getSelectedDevice(devicesStore.devices, selectedDeviceId),
        [devicesStore.devices, selectedDeviceId]
    );

    const { control, handleSubmit, watch } = getFormControls(selectedDevice);

    const onFormSubmit = handleSubmit(data => {
        devicesStore.updateDevice(Number(selectedDeviceId), data);
        onSubmit();
    });

    const clientSelectMenuItems = useMemo(
        () =>
            clientsStore.clients.map(i => {
                return { value: i.id, label: i.name };
            }),
        [clientsStore.clients]
    );

    const watchClientId = watch("clientId");

    const objectSelectMenuItems = useMemo(
        () =>
            сlientObjectsStore.clientObjects
                .filter(i => i.clientId === watchClientId)
                .map(i => {
                    return { value: i.id, label: i.name };
                }),
        [сlientObjectsStore.clientObjects, watchClientId]
    );

    const clientObjectIdDisabled = useMemo(
        () => objectSelectMenuItems.length === 0,
        [objectSelectMenuItems]
    );

    return {
        control,
        onFormSubmit,
        clientSelectMenuItems,
        objectSelectMenuItems,
        clientObjectIdDisabled
    };
}
