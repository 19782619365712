import { UserAvatar } from "components/atoms/UserAvatar";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useStore } from "stores";

const ClientProfileAvatar: React.FC<any> = props => {
    const { id, disabled, register, setValue } = props;

    const { clientsStore } = useStore();
    const [loaded, setLoaded] = useState<any>(false);
    const [avatarSrc, serAvatarSrc] = useState<any>(false);

    useEffect(() => {
        const result = clientsStore.getPhoto(id);
        result
            .then(blob => {
                if (blob) {
                    const blobUrl = URL.createObjectURL(blob);
                    serAvatarSrc(blobUrl);
                    const formData = new FormData();
                    formData.append("file", blob);
                    setValue("file", formData);
                }
            })
            .finally(() => {
                setLoaded(true);
            });
    }, [id]);

    const handleChange = useCallback(
        files => {
            const file = URL.createObjectURL(files[0]);
            serAvatarSrc(file);
            const formData = new FormData();
            formData.append("file", files[0]);
            setValue("file", formData);
        },
        [id]
    );

    const handleDelete = useCallback(() => {
        const result = clientsStore.deletePhoto(id);
        serAvatarSrc(false);
    }, [id]);

    return (
        <>
            <input
                name="file"
                {...register("file")}
                style={{ display: "none" }}
            />
            <UserAvatar
                loaded={loaded}
                avatarSrc={avatarSrc}
                disabled={disabled}
                handleChange={handleChange}
                handleDelete={handleDelete}
            />
        </>
    );
};
export default observer(ClientProfileAvatar);
