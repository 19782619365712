import LuxonUtils from "@date-io/luxon";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime } from "luxon";
import React, { useCallback, useState } from "react";

import useStyles from "./styles";

const FromToDatePicker: React.FC<any> = props => {
    const { selectedDate, setSelectedDate } = props;
    const classes = useStyles();

    const [localSelectedDate, setLocalSelectedDate] =
        useState<any>(selectedDate);

    const handleChange = useCallback(
        (pickerValue: MaterialUiPickersDate) => {
            setLocalSelectedDate(pickerValue);

            if (pickerValue?.isValid || pickerValue === null) {
                setSelectedDate(pickerValue);
            }
        },
        [setLocalSelectedDate, setSelectedDate]
    );

    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker
                value={localSelectedDate}
                onChange={handleChange}
                fullWidth
                format="dd/MM/yyyy"
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        root: classes.input
                    }
                }}
                helperText={null}
                variant="inline"
                autoOk
                PopoverProps={{
                    disableAutoFocus: true,
                    disableEnforceFocus: true
                }}
            />
        </MuiPickersUtilsProvider>
    );
};
export default FromToDatePicker;
