import { AuditTable } from "components/organisms/Tables/AuditTable";
import React from "react";

const AuditTab: React.FC<any> = props => {
    return (
        <AuditTable
            selectedEmployeeId={props.selectedEmployeeId}
            selectedDate={props.selectedAuditDate}
        />
    );
};

export default AuditTab;
