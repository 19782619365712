import { useCallback } from "react";

export const uploadFile = args => {
    const { onUpload } = args;

    const virtualInput = document.createElement("input");

    virtualInput.type = "file";
    virtualInput.accept = "image/*, .doc, .docx, .pdf, .xls, .xlsx, text/plain";

    virtualInput.onchange = () => {
        onUpload(Array.from(virtualInput.files ?? []));
    };

    virtualInput.click();
};

export function onDownloadCallback(url, documentData) {
    return useCallback(() => {
        const virtualA = document.createElement("a");
        virtualA.href = url;
        virtualA.download = documentData.name;
        virtualA.click();
    }, [documentData.name, url]);
}
