import { useCallback } from "react";
import { useStore } from "stores";

import { uploadFile } from "../FileLoading.utils";

export function onUploadCallback(selectedClientId, setMessage, setLoaded) {
    const { clientsStore } = useStore();
    const handleUpload = useCallback(
        async files => {
            const fileData = new FormData();
            fileData.append("file", files[0]);
            const result = await clientsStore.setAgreement(
                selectedClientId,
                fileData
            );
            if (result) {
                setMessage("Agreement uploaded successfully.");
                setLoaded(false);
            } else {
                setMessage("There was a problem with uploading the file.");
            }
        },
        [selectedClientId]
    );
    return useCallback(() => {
        uploadFile({ onUpload: handleUpload });
    }, [clientsStore, handleUpload]);
}
