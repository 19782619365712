import { Box, Drawer, Grid, Typography } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EcoIcon from "@material-ui/icons/Eco";
import NfcIcon from "@material-ui/icons/Nfc";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import WorkIcon from "@material-ui/icons/Work";
import { appUrls } from "App/routes/appUrls";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import SistemRole from "services/enums/SistemRole";
import { useStore } from "stores";

import DrawerMenuItem from "./DrawerMenuItem";
import { DrawerMenuProps } from "./interfaces";
import useStyles from "./styles";

const DrawerMenu: React.FC<DrawerMenuProps> = props => {
    const classes = useStyles({ drawerWidth: 260, drawerCloseWidth: 90 });

    const generalList = [
        {
            path: appUrls.dashboard,
            title: "Dashboard",
            icon: <DashboardIcon />,
            exact: true
        },
        {
            path: appUrls.clients,
            title: "Clients",
            icon: <SupervisorAccountIcon />,
            exact: true
        },
        {
            path: appUrls.employees,
            title: "Employees",
            icon: <WorkIcon />,
            exact: true
        }
        // {
        //     path: appUrls.analytics,
        //     title: "Analytics",
        //     icon: <AssessmentIcon />,
        //     exact: true
        // }
    ];

    const administrationList = [
        {
            path: appUrls.trainings,
            title: "Trainings",
            icon: <SupervisorAccountIcon />,
            exact: true
        },
        {
            path: appUrls.tools,
            title: "Tools",
            icon: <SettingsIcon />,
            exact: true
        },
        {
            path: appUrls.devices,
            title: "Devices",
            icon: <PhonelinkSetupIcon />,
            exact: true
        },
        {
            path: appUrls.nfcTags,
            title: "NfcTags",
            icon: <NfcIcon />,
            exact: false
        }
    ];

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: classNames(classes.drawerPaper, {
                    [classes.drawerPaperClose]: !props.open
                })
            }}
            open={props.open}
        >
            {props.open && (
                <Grid container wrap="nowrap" alignItems="center">
                    <EcoIcon className={classes.activeColor} />
                    <Box ml={0.5}>
                        <Typography
                            variant="h5"
                            className={classes.activeColor}
                        >
                            {"CLEANME"}
                        </Typography>
                    </Box>
                </Grid>
            )}

            <Box mt={6}>
                {props.open && (
                    <Typography variant="overline" display="block">
                        <Box fontWeight={600}>{"GENERAL"}</Box>
                    </Typography>
                )}

                {generalList.map(item => (
                    <DrawerMenuItem
                        key={item.title}
                        item={item}
                        activeColorClass={classes.activeColor}
                    />
                ))}
            </Box>
            <Box mt={3}>
                {props.open && (
                    <Typography variant="overline" display="block">
                        <Box fontWeight={600}>{"ADMINISTRATION"}</Box>
                    </Typography>
                )}
                {administrationList.map(item => (
                    <DrawerMenuItem
                        key={item.title}
                        item={item}
                        activeColorClass={classes.activeColor}
                    />
                ))}
            </Box>
        </Drawer>
    );
};
export default observer(DrawerMenu);
