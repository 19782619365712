import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";

import { NavLinkWrapperProps, NavLinkWrapperCallProps } from "./interfaces";
import useStyles from "./styles";
const NavLinkWrapper: React.FC<NavLinkWrapperProps & NavLinkWrapperCallProps> =
    props => {
        const { path, setIsActive, exact = false, children } = props;
        const classes = useStyles();
        const onClick = useCallback(
            match => {
                if (!match) {
                    setIsActive(false);
                    return false;
                } else {
                    setIsActive(true);
                    return true;
                }
            },
            [setIsActive]
        );

        return (
            <NavLink
                isActive={onClick}
                to={path}
                exact={exact}
                className={classes.link}
            >
                {children}
            </NavLink>
        );
    };
export default NavLinkWrapper;
