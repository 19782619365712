import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Paper,
    TextField
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { SearchField } from "components/molecules/SearchField";
import { TrainingsModal } from "components/organisms/Modals/TrainingsModal";
import { TrainingsTable } from "components/organisms/Tables/TrainingsTable";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Actions from "services/enums/Actions";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";

const TrainingsContent: React.FC = props => {
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <Paper elevation={3}>
                <Box p={2}>
                    <Grid container justify="space-between" alignItems="center">
                        <SearchField
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={openModal}
                        >
                            {"Add training"}
                        </Button>
                    </Grid>
                </Box>
                <TrainingsTable searchString={searchValue} />
            </Paper>
            <TrainingsModal
                isModalOpen={isModalOpen}
                handleClose={closeModal}
                action={Actions.CREATE}
            />
        </>
    );
};

export default observer(TrainingsContent);
