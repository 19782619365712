/* eslint-disable react/jsx-no-bind */
import { CircularProgress } from "@material-ui/core";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "stores";

import { AdminProfileContent } from "./Components/AdminProfileContent";
import { AdminProfileHeader } from "./Components/AdminProfileHeader";

const EmployeeProfile: React.FC = () => {
    const { adminsStore } = useStore();

    const { id } = useParams<any>();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const result = adminsStore.getSelectedAdmin(id);
        result.then(res => {
            setLoaded(true);
        });
    }, [id]);
    const selectedAdmin = adminsStore.selectedAdmin;

    return (
        <HeaderWrapper>
            {loaded ? (
                <BasePage
                    headerComponent={
                        <AdminProfileHeader header={selectedAdmin?.name} />
                    }
                    contentComponent={
                        <AdminProfileContent selectedAdmin={selectedAdmin} />
                    }
                />
            ) : (
                <CircularProgress />
            )}
        </HeaderWrapper>
    );
};

export default observer(EmployeeProfile);
