import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import { RatingController } from "components/molecules/FormControls/RatingController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-hook-form";
import { Feedback } from "services/models/FeedbackModel";
import { useStore } from "stores";
import * as yup from "yup";

import { FeedbackFormCallProps, FeedbackFormProps } from "./interfaces";

const FeedbackForm: React.FC<FeedbackFormProps & FeedbackFormCallProps> =
    props => {
        const { clientId, clientObjectId, deviceId, onSubmit } = props;
        const { feedbacksStore } = useStore();

        const validators = yup.object().shape({});

        const defaultFeedback = new Feedback({
            id: 0,
            name: "",
            clientId,
            clientObjectId,
            grade: 1,
            comment: "",
            deviceId
        });

        const { control, handleSubmit } = useForm({
            mode: "onBlur",
            resolver: yupResolver(validators),
            defaultValues: defaultFeedback
        });

        const onFormSubmit = handleSubmit(data => {
            feedbacksStore.addFeedbacks(data);
            onSubmit();
        });

        return (
            <form onSubmit={onFormSubmit}>
                <TextFieldController
                    control={control}
                    name="name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Name"
                />
                <RatingController control={control} name="grade" size="large" />
                <TextFieldController
                    control={control}
                    multiline
                    rows={4}
                    name="comment"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="comment"
                    label="Comment"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {"Send"}
                </Button>
            </form>
        );
    };
export default observer(FeedbackForm);
