import { Grid, Typography } from "@material-ui/core";
import React from "react";

const DevicesHeader: React.FC = props => {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{"Devices"}</Typography>
        </Grid>
    );
};

export default DevicesHeader;
