import { createTheme } from "@material-ui/core/styles";

const mainPaletteTheme = {};

export function createStyledTheme(prefersDarkMode: boolean) {
    const theme = createTheme({
        typography: {
            button: {
                textTransform: "none"
            }
        },
        palette: {
            type: prefersDarkMode ? "dark" : "light",
            ...mainPaletteTheme
        }
    });
    return theme;
}
