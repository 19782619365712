import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:not(:first-child)": {
                marginLeft: theme.spacing(2)
            }
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        modalImg: {
            width: "100%",
            height: "100%",
            maxHeight: 600,
            maxWidth: 800
        },
        paper: {
            margin: theme.spacing(4),
            borderRadius: 4,
            boxShadow: theme.shadows[5]
        }
    })
);
