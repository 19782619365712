enum AlertType {
    AUTOMATIC_ALERT,
    NFC_CODE_MESSAGE,
    TEXT_MESSAGE,
    APP_TROUBLE
}
export const getAlertTypeLabel = (action: AlertType) => {
    switch (action) {
        case AlertType.AUTOMATIC_ALERT:
            return "automatic alert";
        case AlertType.NFC_CODE_MESSAGE:
            return "nfc code message";
        case AlertType.TEXT_MESSAGE:
            return "request";
        case AlertType.APP_TROUBLE:
            return "app trouble";
    }
};

export const alertTypeMenuItems = [
    { value: AlertType.AUTOMATIC_ALERT, label: "Automatic alert" },
    // { value: AlertType.NFC_CODE_MESSAGE, label: "QR code message" },
    { value: AlertType.TEXT_MESSAGE, label: "Requests" }
    // { value: AlertType.APP_TROUBLE, label: "App trouble" }
];

export default AlertType;
