import { Grid, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { StatusItem } from "components/atoms/StatusItem";
import React, { useCallback, useState } from "react";

import { SearchFieldProps } from "./interfaces";

const SearchField: React.FC<SearchFieldProps> = props => {
    const { searchValue, setSearchValue } = props;
    const handleChange = useCallback(event => {
        setSearchValue(event.target.value);
    }, []);

    return (
        <TextField
            value={searchValue}
            onChange={handleChange}
            id="standard-multiline-flexible"
            variant="outlined"
            size="small"
            placeholder="search"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default SearchField;
