import { AuthorizedPrivateRoute } from "components/atoms/AuthorizedPrivateRoute";
import { Administration } from "components/Pages/Administration";
import { AdminProfile } from "components/Pages/AdminProfile";
import { Analytics } from "components/Pages/Analytics";
import { CleaningTypes } from "components/Pages/CleaningTypes";
import { ClientProfile } from "components/Pages/ClientProfile";
import { Clients } from "components/Pages/Clients";
import { CustomersQrFeedback } from "components/Pages/CustomersQrFeedback";
import { Dashboard } from "components/Pages/Dashboard";
import { Devices } from "components/Pages/Devices";
import { EmployeeProfile } from "components/Pages/EmployeeProfile";
import { Employees } from "components/Pages/Employees";
import { Login } from "components/Pages/Login";
import { ManagerProfile } from "components/Pages/ManagerProfile";
import { MyProfile } from "components/Pages/MyProfile";
import { NewAdminProfile } from "components/Pages/NewAdminProfile";
import { NewCleaningType } from "components/Pages/NewCleaningType";
import { NewClientProfile } from "components/Pages/NewClientProfile";
import { NewEmployeeProfile } from "components/Pages/NewEmployeeProfile";
import { NewManagerProfile } from "components/Pages/NewManagerProfile";
import { NfcTagProfile } from "components/Pages/NfcTagProfile";
import { NfcTags } from "components/Pages/NfcTags";
import { Tools } from "components/Pages/Tools";
import { Trainings } from "components/Pages/Trainings";
import React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";

import { appUrls } from "./appUrls";

const AppRouter: React.FC = () => {
    return (
        <Switch>
            <Route exact path={appUrls.home}>
                <Redirect to={appUrls.dashboard} />
            </Route>
            <AuthorizedPrivateRoute
                path={appUrls.myProfile}
                component={MyProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.dashboard}
                component={Dashboard}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.clients}
                component={Clients}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.clientProfile}
                component={ClientProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.newClient}
                component={NewClientProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.employees}
                component={Employees}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.newManager}
                component={NewManagerProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.managerProfile}
                component={ManagerProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.newAdmin}
                component={NewAdminProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.adminProfile}
                component={AdminProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.employeeProfile}
                component={EmployeeProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.newEmployee}
                component={NewEmployeeProfile}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.analytics}
                component={Analytics}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.administration}
                component={Administration}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.tools}
                component={Tools}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.сleaningType}
                component={CleaningTypes}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.newCleaningType}
                component={NewCleaningType}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.trainings}
                component={Trainings}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.devices}
                component={Devices}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.nfcTags}
                component={NfcTags}
                exact
            />
            <AuthorizedPrivateRoute
                path={appUrls.nfcTagById}
                component={NfcTagProfile}
                exact
            />
            <Route
                path={appUrls.qrFeedback}
                component={CustomersQrFeedback}
                exact
            />
            <Route path={appUrls.login} component={Login} exact />
        </Switch>
    );
};

export default AppRouter;
