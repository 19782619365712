import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MultipleSelectController } from "components/molecules/FormControls/MultipleSelectController";
import { SelectController } from "components/molecules/FormControls/SelectController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { observer } from "mobx-react-lite";
import React from "react";

import { getCleaningTypeFormControls } from "./CleaningTypeForm.utils";
import { CleaningTypeFormProps, CleaningTypeFormCallProps } from "./interfaces";

const CleaningTypeForm: React.FC<
    CleaningTypeFormProps & CleaningTypeFormCallProps
> = props => {
    const {
        onSubmit,
        actionButtonText,
        cleaningType,
        disabled = false
    } = props;

    const { control, onFormSubmit, toolsSelectMenuItems } =
        getCleaningTypeFormControls(cleaningType, onSubmit);

    return (
        <form onSubmit={onFormSubmit}>
            <TextFieldController
                control={control}
                disabled={disabled}
                name="name"
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Name"
            />
            <MultipleSelectController
                control={control}
                name="toolsIds"
                label="toolsIds"
                menuItems={toolsSelectMenuItems}
                formControlProps={{
                    disabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            {!disabled && (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {actionButtonText}
                </Button>
            )}
        </form>
    );
};
export default observer(CleaningTypeForm);
