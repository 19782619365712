import { Box, Button, Collapse, TableCell, TableRow } from "@material-ui/core";
import React from "react";

import { NfcWorkTrackerTable } from "../../NfcWorkTrackerTable";
import { TimeDifferenceTable } from "../../TimeDifferenceTable";

const AuditCollapseContent: React.FC<any> = props => {
    const { work, nfc, maps } = props;
    const { startLatitude, startLongitude, finishLatitude, finishLongitude } =
        maps;

    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in timeout="auto" unmountOnExit>
                    <Box pt={1} style={{ maxWidth: 600 }}>
                        <TimeDifferenceTable workArray={work} />
                    </Box>
                    <Box pt={1} style={{ maxWidth: 600 }}>
                        <NfcWorkTrackerTable nfcWorkTrackerArray={nfc} />
                    </Box>
                    <Box pt={1} pb={1}>
                        {startLatitude && startLongitude && (
                            <Box sx={{ display: "inline" }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    target="_blank"
                                    href={`https://www.google.com/maps/search/?api=1&query=${startLatitude},${startLongitude}`}
                                >
                                    {"Start location"}
                                </Button>
                            </Box>
                        )}
                        {finishLatitude && finishLatitude && (
                            <Box sx={{ display: "inline" }} ml={2}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    target="_blank"
                                    href={`https://www.google.com/maps/search/?api=1&query=${finishLatitude},${finishLongitude}`}
                                >
                                    {"Finish location"}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};
export default AuditCollapseContent;
