import TextField from "@material-ui/core/TextField";
import React, { useCallback } from "react";
import { Controller } from "react-hook-form";

import { TextFieldControllerProps } from "./interfaces";

const TextFieldController: React.FC<TextFieldControllerProps> = props => {
    const { name, control, ...rest } = props;

    const render = useCallback(
        ({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
                {...rest}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
            />
        ),
        [rest]
    );
    return <Controller control={control} name={name} render={render} />;
};
export default TextFieldController;
