import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getUsers = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    return $axios.get(`/api/Users${params}`);
};
export const getUserById = (id: number) => {
    return $axios.get(`/api/Users/${id}`);
};

export const setUser = (data: any) => {
    return $axios.post(`/api/Users/SignUp`, data);
};

export const updateUser = (id: number, data: any) => {
    return $axios.put(`/api/Users/${id}`, data);
};

export const deleteUser = (id: number) => {
    return $axios.delete(`/api/Users/${id}`);
};
