import { appUrls } from "App/routes/appUrls";
import { LoginForm } from "components/organisms/Forms/LoginForm";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { useStore } from "stores";

import useStyles from "./styles";

const Login: React.FC = () => {
    const { loginStore } = useStore();

    const classes = useStyles();

    return (
        <div className={classes.loginPage}>
            <div className={classes.background} />
            <div className={classes.form}>
                {!loginStore.user ? (
                    <LoginForm />
                ) : (
                    <Redirect to={appUrls.dashboard} />
                )}
            </div>
        </div>
    );
};

export default observer(Login);
