class Feedback {
    public id: number;
    public name?: string;
    public clientId: number;
    public clientName?: string;
    public clientObjectId: number;
    public clientObjectAddress?: string;
    public comment?: string;
    public grade?: number;
    public created?: string;
    public deviceId: number;

    constructor(model?) {
        if (model) {
            this.id = model.id;
            this.name = model.name;
            this.clientId = model.clientId;
            this.clientName = model.clientName;
            this.clientObjectId = model.clientObjectId;
            this.clientObjectAddress = model.clientObjectAddress;
            this.comment = model.comment;
            this.grade = model.grade;
            this.created = model.created;
            this.deviceId = model.deviceId;
        } else {
            this.id = 0;
            this.clientId = 0;
            this.clientObjectId = 0;
            this.deviceId = 0;
        }
    }
}

export default Feedback;
