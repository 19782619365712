import { Box, Grid } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { OrderReportsTable } from "components/organisms/Tables/OrderReportsTable";
import React, { useState } from "react";

const OrderReportTab: React.FC = props => {
    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </Grid>
            </Box>
            <OrderReportsTable searchString={searchValue} />
        </>
    );
};

export default OrderReportTab;
