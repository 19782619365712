import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";

import { AdminsStore } from "./AdminsStore";
import { AuditStore } from "./AuditStore";
import { CleaningTypesStore } from "./CleaningTypesStore";
import { ClientObjectAlertsStore } from "./ClientObjectAlertsStore";
import { ClientObjectsStore } from "./ClientObjectsStore";
import { ClientsStore } from "./ClientsStore";
import { DevicesStore } from "./DevicesStore";
import { EmployeesStore } from "./EmployeesStore";
import { FeedbacksStore } from "./FeedbacksStore";
import { FilesStore } from "./FilesStore";
import { LoginStore } from "./LoginStore";
import { ManagersStore } from "./ManagersStore";
import { NfcHistoryStore } from "./NfcHistoryStore";
import { NfcTagsStore } from "./NfcTagsStore";
import { OrderReportsStore } from "./OrderReportsStore";
import { OrdersStore } from "./OrdersStore";
import { ToolsStore } from "./ToolsStore";
import { TrainingsStore } from "./TrainingsStore";
import { UsersStore } from "./UsersStore";

class RootStore {
    adminsStore: AdminsStore;
    auditStore: AuditStore;
    clientsStore: ClientsStore;
    employeesStore: EmployeesStore;
    managersStore: ManagersStore;
    nfcTagsStore: NfcTagsStore;
    orderReportsStore: OrderReportsStore;
    nfcHistoryStore: NfcHistoryStore;
    usersStore: UsersStore;
    feedbacksStore: FeedbacksStore;
    filesStore: FilesStore;
    сlientObjectsStore: ClientObjectsStore;
    clientObjectAlertsStore: ClientObjectAlertsStore;
    cleaningTypesStore: CleaningTypesStore;
    ordersStore: OrdersStore;
    devicesStore: DevicesStore;
    toolsStore: ToolsStore;
    trainingsStore: TrainingsStore;
    loginStore: LoginStore;

    constructor() {
        this.adminsStore = new AdminsStore();
        this.auditStore = new AuditStore();
        this.clientsStore = new ClientsStore();
        this.employeesStore = new EmployeesStore();
        this.managersStore = new ManagersStore();
        this.nfcTagsStore = new NfcTagsStore();
        this.orderReportsStore = new OrderReportsStore();
        this.nfcHistoryStore = new NfcHistoryStore();
        this.usersStore = new UsersStore();
        this.feedbacksStore = new FeedbacksStore();
        this.filesStore = new FilesStore();
        this.сlientObjectsStore = new ClientObjectsStore();
        this.clientObjectAlertsStore = new ClientObjectAlertsStore();
        this.cleaningTypesStore = new CleaningTypesStore();
        this.ordersStore = new OrdersStore(this);
        this.devicesStore = new DevicesStore();
        this.toolsStore = new ToolsStore();
        this.trainingsStore = new TrainingsStore();
        this.loginStore = new LoginStore();
        makeAutoObservable(this);
    }
}

const StoreContext = createContext<RootStore>(new RootStore());

const StoreProvider: React.FC<{ store: RootStore }> = ({ store, children }) => {
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

const useStore = () => {
    return useContext(StoreContext);
};

export { RootStore, StoreProvider, useStore };
