import {
    Grid,
    Avatar,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PersonIcon from "@material-ui/icons/Person";
import classNames from "classnames";
import React, { useCallback, useState } from "react";

import useStyles from "./styles";
import { uploadFile } from "./UserAvatar.utils";

const UserAvatar: React.FC<any> = props => {
    const { loaded, handleChange, avatarSrc, disabled, handleDelete } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = useCallback((event: any) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onClick = useCallback(() => {
        if (!disabled && loaded) {
            uploadFile({ onUpload: handleChange });
            setAnchorEl(null);
        }
    }, [handleChange, disabled, loaded]);

    const onDelete = useCallback(() => {
        handleDelete();
        setAnchorEl(null);
    }, [handleDelete]);

    return (
        <div className={classes.overlayWrapper}>
            <Avatar
                alt="avatar"
                src={avatarSrc}
                style={{ width: 120, height: 120 }}
            >
                {loaded ? (
                    <PersonIcon className={classes.personIcon} />
                ) : (
                    <CircularProgress size={40} />
                )}
            </Avatar>
            <Grid
                classes={{
                    root: classNames({
                        [classes.overlay]: !disabled && loaded
                    })
                }}
            >
                <IconButton onClick={handleClick} color="primary">
                    <MoreHorizIcon className={classes.icon} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <>
                        <MenuItem onClick={onClick}>{"upload"}</MenuItem>
                        {avatarSrc && (
                            <MenuItem onClick={onDelete}>{"delete"}</MenuItem>
                        )}
                    </>
                </Menu>
            </Grid>
        </div>
    );
};

export default UserAvatar;
