import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useStore } from "stores";
import * as yup from "yup";

function getFormControls() {
    const validators = yup.object().shape({
        login: yup.string().trim().required("Field is required"),
        password: yup
            .string()
            .required("Field is required")
            .min(6, "min 6 simbols")
    });

    const { control, handleSubmit } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators)
    });

    return {
        control,
        handleSubmit
    };
}

export function getLoginFormControls() {
    const { loginStore } = useStore();

    const { control, handleSubmit } = getFormControls();

    const onFormSubmit = handleSubmit(data => {
        loginStore.login(data.login, data.password);
    });

    return {
        control,
        onFormSubmit
    };
}
