import { Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";

import { StatusItemProps } from "./interfaces";
import useStyles from "./styles";

const StatusItem: React.FC<StatusItemProps> = props => {
    const classes = useStyles();
    const { selected, onClick, text, disabled } = props;
    return (
        <Button
            classes={{
                root: classes.root,
                disabled: classes.disabled,
                iconSizeMedium: classes.iconSizeMedium
            }}
            disabled={disabled}
            disableElevation
            disableFocusRipple
            onClick={onClick}
            startIcon={selected ? <DoneIcon /> : null}
        >
            {text}
        </Button>
    );
};

export default StatusItem;
