import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ConfirmActionDialogWrapper } from "components/molecules/ConfirmActionDialogWrapper";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import OrderStatus from "services/enums/OrderStatus";

const OrdersTableControles: React.FC<any> = props => {
    const {
        order,
        canAdd,
        currentManagerId,
        onViewClick,
        onUpdateClick,
        onDeleteClick,
        revokeOrderClick,
        stopOrder
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = useCallback((event: any) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const itemClick = useCallback(
        actionCallback => () => {
            actionCallback();
            handleClose();
        },
        []
    );

    const userCanEdit = order.managerId === currentManagerId || canAdd;
    const canUpdateOrder = order.state === OrderStatus.ON_HOLD;
    const canRevoke =
        Number(order.clientObjectAlertId) !== 0 &&
        order.state === OrderStatus.ON_HOLD;

    const canStop = order.state !== OrderStatus.ON_HOLD;

    return (
        <>
            <IconButton onClick={handleClick} color="primary">
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={itemClick(onViewClick(order.id))}>
                    {"view"}
                </MenuItem>
                {userCanEdit && (
                    <>
                        {canUpdateOrder && (
                            <MenuItem
                                onClick={itemClick(onUpdateClick(order.id))}
                            >
                                {"edit"}
                            </MenuItem>
                        )}
                        {canRevoke && (
                            <MenuItem
                                onClick={itemClick(revokeOrderClick(order.id))}
                            >
                                {"revoke"}
                            </MenuItem>
                        )}
                        {canStop && (
                            <MenuItem onClick={itemClick(stopOrder(order.id))}>
                                {"stop order"}
                            </MenuItem>
                        )}
                        <ConfirmActionDialogWrapper
                            onConfirmClick={itemClick(onDeleteClick(order.id))}
                        >
                            {openConfirmActionDialog => (
                                <MenuItem onClick={openConfirmActionDialog}>
                                    {"delete"}
                                </MenuItem>
                            )}
                        </ConfirmActionDialogWrapper>
                    </>
                )}
            </Menu>
        </>
    );
};
export default observer(OrdersTableControles);
