import React, { useEffect } from "react";
import { useStore } from "stores";

const CheckAuthorization: React.FC = props => {
    const { loginStore } = useStore();
    useEffect(() => {
        loginStore.checkAuthorization();
    }, []);

    return null;
};
export default CheckAuthorization;
