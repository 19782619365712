import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export function getClientProfileFormControls(selectedClient, onSubmit) {
    const validators = yup.object().shape({
        name: yup.string().trim().required("Field is required").nullable(),
        email: yup.string().trim().required("Field is required").nullable(),
        contactPhone: yup
            .string()
            .trim()
            .required("Field is required")
            .nullable()
    });

    const { control, handleSubmit, register, setValue } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: {
            id: selectedClient.id,
            name: selectedClient.name,
            email: selectedClient.email,
            contactPhone: selectedClient.contactPhone
        }
    });

    const onFormSubmit = handleSubmit(data => {
        onSubmit(data);
    });

    return {
        control,
        onFormSubmit,
        register,
        setValue
    };
}
