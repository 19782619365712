import { Box, Button, Grid } from "@material-ui/core";
import { appUrls } from "App/routes/appUrls";
import { SearchField } from "components/molecules/SearchField";
import { ClientsTable } from "components/organisms/Tables/ClientsTable";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useStore } from "stores";

const ClientTab: React.FC = props => {
    const history = useHistory();
    const addClient = () => {
        history.push(appUrls.newClient);
    };

    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addClient}
                    >
                        {"Add client"}
                    </Button>
                </Grid>
            </Box>
            <ClientsTable searchString={searchValue} />
        </>
    );
};

export default ClientTab;
