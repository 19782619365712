import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-hook-form";
import Actions, { getActionLabel } from "services/enums/Actions";
import * as yup from "yup";

const NfcTagForm: React.FC<any> = props => {
    const { onSubmit, selectedNfcTag } = props;

    const validators = yup.object().shape({
        tagId: yup.string().trim().required("Field is required").nullable(),
        tagName: yup.string().trim().required("Field is required").nullable(),
        roomId: yup.number().required("Field is required").nullable(),
        clientName: yup
            .string()
            .trim()
            .required("Field is required")
            .nullable(),
        address: yup.string().trim().required("Field is required").nullable()
    });

    const { control, handleSubmit } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: selectedNfcTag
    });

    const onFormSubmit = handleSubmit(data => {
        onSubmit(data);
    });

    return (
        <form onSubmit={onFormSubmit}>
            <TextFieldController
                control={control}
                disabled={true}
                name="tagId"
                variant="outlined"
                margin="normal"
                fullWidth
                id="tagId"
                label="Tag Id"
            />
            <TextFieldController
                control={control}
                name="tagName"
                variant="outlined"
                margin="normal"
                fullWidth
                id="tagName"
                label="Tag name"
            />
            <TextFieldController
                control={control}
                disabled={true}
                type="number"
                name="roomId"
                variant="outlined"
                margin="normal"
                fullWidth
                id="roomId"
                label="Room Id"
            />
            <TextFieldController
                control={control}
                disabled={true}
                name="clientName"
                variant="outlined"
                margin="normal"
                fullWidth
                id="clientName"
                label="Client name"
            />
            <TextFieldController
                control={control}
                disabled={true}
                name="address"
                variant="outlined"
                margin="normal"
                fullWidth
                id="address"
                label="Address"
            />

            <Button type="submit" fullWidth variant="contained" color="primary">
                {getActionLabel(Actions.UPDATE)}
            </Button>
        </form>
    );
};
export default observer(NfcTagForm);
