import { TableUserAvatar } from "components/atoms/TableUserAvatar";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "stores";

const EmployeesAvatar: React.FC<any> = props => {
    const { id } = props;
    const { employeesStore } = useStore();

    const [loaded, setLoaded] = useState<any>(false);
    const [url, setUrl] = useState<any>(false);
    useEffect(() => {
        const result = employeesStore.getPhoto(id);
        result
            .then(blob => {
                if (blob) {
                    const blobUrl = URL.createObjectURL(blob);
                    setUrl(blobUrl);
                }
            })
            .finally(() => {
                setLoaded(true);
            });
    }, [id]);

    return <TableUserAvatar logoSrc={url} loaded={loaded} />;
};

export default observer(EmployeesAvatar);
