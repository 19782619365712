import { Paper } from "@material-ui/core";
import { CheckAuthorization } from "components/atoms/CheckAuthorization";
import { StyledThemeWrapper } from "components/atoms/StyledThemeWrapper";
import React from "react";
import { Router } from "react-router-dom";
import { RootStore, StoreProvider } from "stores";

import history from "./history";
import { AppRouter } from "./routes";
import useStyles from "./styles";

const App: React.FC = () => {
    const store = new RootStore();
    const classes = useStyles();
    return (
        <StoreProvider store={store}>
            <StyledThemeWrapper>
                <CheckAuthorization />
                <Router history={history}>
                    <Paper classes={{ root: classes.app }}>
                        <AppRouter />
                    </Paper>
                </Router>
            </StyledThemeWrapper>
        </StoreProvider>
    );
};

export default App;
