import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { NewClientProfileContent } from "./Components/NewClientProfileContent";
import { NewClientProfileHeader } from "./Components/NewClientProfileHeader";

const NewClientProfile: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<NewClientProfileHeader />}
                contentComponent={<NewClientProfileContent />}
            />
        </HeaderWrapper>
    );
};

export default NewClientProfile;
