import React from "react";

import { TabPanelProps } from "./interfaces";

const TabPanel: React.FC<TabPanelProps> = props => {
    const { value, index, children } = props;
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <>{children}</>}
        </div>
    );
};

export default TabPanel;
