/* eslint-disable react/jsx-no-bind */
import { CircularProgress } from "@material-ui/core";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "stores";

import { ClientProfileContent } from "./Components/ClientProfileContent";
import { ClientProfileHeader } from "./Components/ClientProfileHeader";

const ClientProfile: React.FC = () => {
    const { clientsStore } = useStore();

    const { id } = useParams<any>();

    // TODO подумать над валидацией если строка и что делать
    // редирек на 404 с фронта или бека мб
    // + вопрос по валидации бека если с заданным ИД нету клиента
    const idIsValidId = isNaN(id);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const result = clientsStore.getSelectedClient(id);
        result.then(res => {
            setLoaded(true);
        });
    }, [id]);
    const selectedClient = clientsStore.selectedClient;

    return (
        <HeaderWrapper>
            {loaded ? (
                <BasePage
                    headerComponent={
                        <ClientProfileHeader header={selectedClient?.name} />
                    }
                    contentComponent={
                        <ClientProfileContent selectedClient={selectedClient} />
                    }
                />
            ) : (
                <CircularProgress />
            )}
        </HeaderWrapper>
    );
};

export default observer(ClientProfile);
