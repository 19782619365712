import { Box, Paper } from "@material-ui/core";
import { NfcTagForm } from "components/organisms/Forms/NfcTagForm";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useStore } from "stores";

const NfcTagContent: React.FC<any> = props => {
    const { loaded, selectedNfcTag } = props;
    const { nfcTagsStore } = useStore();

    const history = useHistory();
    const onSubmit = useCallback(data => {
        const result = nfcTagsStore.updateNfcTag(data);
        result.then(res => {
            if (res) {
                history.goBack();
            }
        });
    }, []);

    return (
        <Paper elevation={3}>
            <Box px={2} py={2}>
                {loaded ? (
                    selectedNfcTag ? (
                        <NfcTagForm
                            selectedNfcTag={selectedNfcTag}
                            onSubmit={onSubmit}
                        />
                    ) : (
                        "Nfc not found"
                    )
                ) : (
                    <>{"loading"}</>
                )}
            </Box>
        </Paper>
    );
};

export default observer(NfcTagContent);
