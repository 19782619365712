import { Avatar, CircularProgress } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";

const TableUserAvatar: React.FC<any> = props => {
    const { logoSrc, loaded } = props;
    return (
        <>
            <Avatar
                alt="avatar"
                src={logoSrc}
                style={{ width: 40, height: 40 }}
            >
                {loaded ? <PersonIcon /> : <CircularProgress size={20} />}
            </Avatar>
        </>
    );
};

export default TableUserAvatar;
