import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { useState } from "react";
import { DashboardTabs } from "services/enums/DashboardTabs";

import { DashboardContent } from "./Components/DashboardContent";
import { DashboardHeader } from "./Components/DashboardHeader";

const Dashboard: React.FC = () => {
    const [selectedOrderDate, setSelectedOrderDate] = useState(null);
    const [selectedAlertDate, setSelectedAlertDate] = useState(null);

    const [selectedTabIndex, setSelectedTabIndex] = useState(
        DashboardTabs.ORDERS
    );

    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={
                    <DashboardHeader
                        selectedOrderDate={selectedOrderDate}
                        setSelectedOrderDate={setSelectedOrderDate}
                        selectedAlertDate={selectedAlertDate}
                        setSelectedAlertDate={setSelectedAlertDate}
                        selectedTabIndex={selectedTabIndex}
                    />
                }
                contentComponent={
                    <DashboardContent
                        selectedOrderDate={selectedOrderDate}
                        selectedAlertDate={selectedAlertDate}
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                    />
                }
            />
        </HeaderWrapper>
    );
};

export default Dashboard;
