import { Button } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Tooltip } from "components/atoms/Tooltip";
import {
    BaseTable,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import AlertState, { getAlertStateLabel } from "services/enums/AlertState";
import { getAlertTypeLabel } from "services/enums/AlertType";
import { ClientObjectAlert } from "services/models/ClientObjectAlertModels";
import { useStore } from "stores";

const ClientObjectAlertsTable: React.FC<any> = props => {
    const { selectedAlertTypeIds, searchString, selectedDate } = props;
    const { clientObjectAlertsStore, loginStore, ordersStore } = useStore();

    const currentManagerId = loginStore.user?.id;

    const takeAlert = useCallback(
        (id: number) => () => {
            ordersStore.createOrderFromAlert(id, Number(currentManagerId));
        },
        [currentManagerId]
    );

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const clientObjectsHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "clientName", label: "Client", sorted: true },
        { id: "clientObjectName", label: "Object's name", sorted: true },
        { id: "clientObjectAddress", label: "Address", sorted: true },
        { id: "created", label: "Created", sorted: true },
        { id: "throuble", label: "Iformation", sorted: true },
        { id: "controls", label: "", sorted: false }
    ];

    const getClientObjectsAlertRowContent = useCallback(
        (row: ClientObjectAlert) => ({
            rowContent: [
                row.id,
                row.clientName,
                row.clientObjectName,
                row.clientObjectAddress,
                row.created ? row.created.replace("T", " ").slice(0, 16) : "",
                <>
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={
                            row.throuble ? (
                                <Tooltip title={row.throuble} leaveDelay={500}>
                                    <ErrorOutlineIcon />
                                </Tooltip>
                            ) : null
                        }
                    >
                        {getAlertTypeLabel(row.type)}
                    </Button>
                </>,
                <>
                    {row.state === AlertState.DONE ? (
                        <Button
                            size="small"
                            style={{ color: "green", borderColor: "green" }}
                            variant="outlined"
                        >
                            {"Finished"}
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={takeAlert(row.id)}
                        >
                            {"Handle"}
                        </Button>
                    )}
                </>
            ]
        }),
        []
    );

    const getClientObjectAlerts = useCallback(() => {
        clientObjectAlertsStore.getClientObjectAlerts(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            selectedAlertTypeIds,
            searchString,
            selectedDate
        );
    }, [
        page,
        rowsPerPage,
        sortBy,
        order,
        selectedAlertTypeIds,
        searchString,
        selectedDate
    ]);

    useEffect(() => {
        getClientObjectAlerts();
    }, [getClientObjectAlerts]);

    useEffect(() => {
        if (clientObjectAlertsStore.needUpdate) {
            getClientObjectAlerts();
        }
    }, [getClientObjectAlerts, clientObjectAlertsStore.needUpdate]);

    const clientObjectsRowCells =
        clientObjectAlertsStore.clientObjectAlerts.map(row =>
            getClientObjectsAlertRowContent(row)
        );

    return (
        <>
            <BaseTable
                headCells={clientObjectsHeadCells}
                rowCells={clientObjectsRowCells}
                // TODO add totalCount and pagination
                rowCellsСount={clientObjectAlertsStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
        </>
    );
};

export default observer(ClientObjectAlertsTable);
