import { Avatar, CircularProgress, Modal } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useStore } from "stores";

import useStyles from "./styles";

const ImageReportListItem: React.FC<any> = props => {
    const { photoReportId } = props;
    const classes = useStyles();
    const { filesStore } = useStore();

    const [loaded, setLoaded] = useState<any>(false);
    const [url, setUrl] = useState<any>(false);

    useEffect(() => {
        const result = filesStore.getFile(photoReportId);
        result
            .then(blob => {
                setUrl(blob);
            })
            .finally(() => {
                setLoaded(true);
            });
    }, [photoReportId]);

    const [open, setOpen] = useState(false);

    const openImg = useCallback(() => {
        if (url) {
            setOpen(true);
        }
    }, [url]);

    const closeImg = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <Avatar
                onClick={openImg}
                variant="rounded"
                alt="image"
                src={url}
                classes={{ root: classes.root }}
                style={{
                    width: 80,
                    height: 60
                }}
            >
                {loaded ? <ImageIcon /> : <CircularProgress size={20} />}
            </Avatar>
            <Modal className={classes.modal} open={open} onClose={closeImg}>
                <div className={classes.paper}>
                    <Avatar
                        variant="rounded"
                        alt="image"
                        src={url}
                        classes={{ root: classes.modalImg }}
                    >
                        {loaded ? (
                            <ImageIcon />
                        ) : (
                            <CircularProgress size={20} />
                        )}
                    </Avatar>
                </div>
            </Modal>
        </>
    );
};
export default observer(ImageReportListItem);
