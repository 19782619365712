import { Grid, Typography } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { FromToDatePicker } from "components/molecules/FromToDatePicker";
import React from "react";
import { DashboardTabs } from "services/enums/DashboardTabs";

const DashboardHeader: React.FC<any> = props => {
    const {
        selectedTabIndex,
        selectedOrderDate,
        setSelectedOrderDate,
        selectedAlertDate,
        setSelectedAlertDate
    } = props;

    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{"Dashboard"}</Typography>
            <TabPanel value={selectedTabIndex} index={DashboardTabs.ORDERS}>
                <Grid item>
                    <FromToDatePicker
                        selectedDate={selectedOrderDate}
                        setSelectedDate={setSelectedOrderDate}
                    />
                </Grid>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={DashboardTabs.ALERTS}>
                <Grid item>
                    <FromToDatePicker
                        selectedDate={selectedAlertDate}
                        setSelectedDate={setSelectedAlertDate}
                    />
                </Grid>
            </TabPanel>
        </Grid>
    );
};

export default DashboardHeader;
