import AlertState from "../../enums/AlertState";
import AlertType from "../../enums/AlertType";

class ClientObjectAlert {
    public id: number;
    public clientId?: number;
    public clientName?: string;

    public clientObjectId?: number;
    public clientObjectName?: string;
    public clientObjectAddress?: string;

    public throuble?: string;
    public deviceId?: number;
    public created: string;

    public state: AlertState;
    public type: AlertType;

    constructor(model?: ClientObjectAlert) {
        if (model) {
            this.id = model.id;
            this.clientId = model.clientId;
            this.clientName = model.clientName;

            this.clientObjectId = model.clientObjectId;
            this.clientObjectName = model.clientObjectName;
            this.clientObjectAddress = model.clientObjectAddress;

            this.throuble = model.throuble;
            this.deviceId = model.deviceId;
            this.created = model.created;

            this.state = model.state;
            this.type = model.type;
        } else {
            this.id = 0;
            this.created = "";
            this.state = AlertState.NEW;
            this.type = AlertType.TEXT_MESSAGE;
        }
    }
}

export default ClientObjectAlert;
