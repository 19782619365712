import { Box, Typography } from "@material-ui/core";
import { FeedbackForm } from "components/organisms/Forms/FeedbackForm";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";

const QrFeedback: React.FC = () => {
    const { search } = useLocation();

    const [isSended, setIsSended] = useState(false);
    const query = new URLSearchParams(search);

    const clientId = query.get("clientId");
    const deviceId = query.get("deviceId");
    const clientObjectId = query.get("clientObjectId");

    // http://localhost:3000/qrFeedback?clientId=1&deviceId=1&clientObjectId=1
    return (
        <div>
            <Box component="fieldset" m={3} borderColor="transparent">
                {clientObjectId && deviceId && clientObjectId ? (
                    isSended ? (
                        <Typography variant="h6">
                            {"Thanks for your feedback."}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="h6">
                                {
                                    "Here you can leave a comment about our products."
                                }
                            </Typography>
                            <FeedbackForm
                                clientId={Number(clientId)}
                                clientObjectId={Number(clientObjectId)}
                                deviceId={Number(deviceId)}
                                onSubmit={() => {
                                    setIsSended(true);
                                }}
                            />
                        </>
                    )
                ) : (
                    <Typography variant="h6">
                        {"Sorry, but this link is out of date."}
                    </Typography>
                )}
            </Box>
        </div>
    );
};

export default QrFeedback;
