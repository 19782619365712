class OrderReport {
    public orderId = 0;
    public expectedDate = "";
    public employeeId = 0;
    public employeeName = "";
    public clientId = 0;
    public clientName = "";
    public clientObjectId = 0;
    public clientObjectName = "";
    public clientObjectAddress = "";
    public startDate = "";
    public endDate = "";
    public photoReports: number[] = [];
    public comment = "";
    public commentReport = "";
    public commentFeedback = "";
    public gradeFeedback = 0;

    constructor(model?: Partial<OrderReport>) {
        Object.assign(this, model);
    }
}

export default OrderReport;
