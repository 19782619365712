import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { NewAdminProfileContent } from "./Components/NewAdminProfileContent";
import { NewAdminProfileHeader } from "./Components/NewAdminProfileHeader";

const NewAdminProfile: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<NewAdminProfileHeader />}
                contentComponent={<NewAdminProfileContent />}
            />
        </HeaderWrapper>
    );
};

export default NewAdminProfile;
