import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import { SelectController } from "components/molecules/FormControls/SelectController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-hook-form";
import Actions, { getActionLabel } from "services/enums/Actions";
import SistemRole from "services/enums/SistemRole";
import { Tool } from "services/models/ToolModel";
import { useStore } from "stores";
import * as yup from "yup";

import { getDeviceFormControls } from "./DeviceForm.utils";
import { DeviceFormCallProps, DeviceFormProps } from "./interfaces";

const DeviceForm: React.FC<DeviceFormProps & DeviceFormCallProps> = props => {
    const { onSubmit, selectedDeviceId, action } = props;

    const {
        control,
        onFormSubmit,
        clientSelectMenuItems,
        objectSelectMenuItems,
        clientObjectIdDisabled
    } = getDeviceFormControls(selectedDeviceId, onSubmit);

    return (
        <form onSubmit={onFormSubmit}>
            <TextFieldController
                disabled={true}
                control={control}
                name="uid"
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                id="uid"
                label="Uid"
            />
            <TextFieldController
                control={control}
                name="name"
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                id="name"
                label="Name Devices"
            />
            <SelectController
                control={control}
                name="clientId"
                label="Client"
                menuItems={clientSelectMenuItems}
                formControlProps={{
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <SelectController
                control={control}
                name="clientObjectId"
                label="Object"
                menuItems={objectSelectMenuItems}
                formControlProps={{
                    disabled: clientObjectIdDisabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <TextFieldController
                control={control}
                name="timeoutBeforeAlertMin"
                variant="outlined"
                margin="normal"
                fullWidth
                id="timeoutBeforeAlertMin"
                label="Timeout before alert (min)"
                type="number"
            />
            <TextFieldController
                control={control}
                name="timeoutAfterAlertMin"
                variant="outlined"
                margin="normal"
                fullWidth
                id="timeoutAfterAlertMin"
                label="Timeout after alert (min)"
                type="number"
            />
            <TextFieldController
                control={control}
                name="freeTimeoutsAmountMin"
                variant="outlined"
                margin="normal"
                fullWidth
                id="freeTimeoutsAmountMin"
                label="Free timeouts amount (min)"
                type="number"
            />

            <Button type="submit" fullWidth variant="contained" color="primary">
                {getActionLabel(Actions.UPDATE)}
            </Button>
        </form>
    );
};
export default observer(DeviceForm);
