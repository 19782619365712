import { Button } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Tooltip } from "components/atoms/Tooltip";
import { OrderModal } from "components/organisms/Modals/OrderModal";
import {
    BaseTable,
    getBaseActionsControls,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import OrderStatus, { getOrderStatusLabel } from "services/enums/OrderStatus";
import SistemRole from "services/enums/SistemRole";
import { Order } from "services/models/OrderModel";
import { useStore } from "stores";

import OrdersTableControles from "./components/OrdersTableControles";

const OrdersTable: React.FC<any> = props => {
    const { selectedStatusIds, showAllList, searchString, selectedDate } =
        props;

    const { ordersStore, loginStore } = useStore();

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const {
        isModalOpen,
        closeModal,
        action,
        selectedItemId,
        onUpdateClick,
        onViewClick
    } = getBaseActionsControls();

    const onDeleteClick = useCallback(
        selectedId => () => {
            ordersStore.deleteOrder(selectedId);
        },
        []
    );

    const revokeOrderClick = useCallback(
        selectedId => () => {
            ordersStore.revokeOrder(selectedId);
        },
        []
    );

    const stopOrder = useCallback(
        selectedId => () => {
            ordersStore.changeStateOrder(selectedId, OrderStatus.ON_HOLD);
        },
        []
    );

    const ordersHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "clientName", label: "Client", sorted: true },
        { id: "clientObjectName", label: "Object", sorted: true },
        {
            id: "clientObjectAddress",
            label: "Address",
            sorted: true
        },
        { id: "employeeName", label: "Employee", sorted: true },
        { id: "managerName", label: "Manager", sorted: true },
        { id: "state", label: "Status", sorted: true },
        { id: "createdDate", label: "Order created", sorted: true },
        { id: "extectedDate", label: "Cleaning expected", sorted: true },

        { id: "controls", label: "", sorted: false }
    ];

    const canAdd = loginStore.user?.role === SistemRole.ADMIN;
    const currentManagerId = loginStore.user?.id;

    const getTooltipContent = useCallback((rowItem: any) => {
        const isEmpty =
            !rowItem.comment &&
            !rowItem.declineReason &&
            !rowItem.attentionReason &&
            !rowItem.startTime &&
            !rowItem.endTime;

        if (isEmpty) {
            return null;
        }

        return (
            <>
                {rowItem.comment && <div>{`comment: ${rowItem.comment}`}</div>}
                {rowItem.declineReason && (
                    <div>{`comment: ${rowItem.declineReason}`}</div>
                )}
                {rowItem.attentionReason && (
                    <div>{`comment: ${rowItem.attentionReason}`}</div>
                )}
                {rowItem.startTime && (
                    <div>{`start time: ${rowItem.startTime
                        .replace("T", " ")
                        .slice(0, 16)}`}</div>
                )}
                {rowItem.endTime && (
                    <div>{`end time: ${rowItem.endTime
                        .replace("T", " ")
                        .slice(0, 16)}`}</div>
                )}
            </>
        );
    }, []);

    const getOrdersRowContent = useCallback(
        (row: Order) => {
            const tooltipContent = getTooltipContent(row);
            return {
                rowContent: [
                    row.id,
                    row.clientName,
                    row.clientObjectName,
                    row.clientObjectAddress,
                    row.employeeName,
                    row.managerName,
                    <>
                        <Button
                            variant="outlined"
                            size="small"
                            endIcon={
                                tooltipContent && (
                                    <Tooltip
                                        title={tooltipContent}
                                        leaveDelay={500}
                                    >
                                        <ErrorOutlineIcon />
                                    </Tooltip>
                                )
                            }
                        >
                            {getOrderStatusLabel(row.state)}
                        </Button>
                    </>,
                    row.createdDate
                        ? row.createdDate.replace("T", " ").slice(0, 16)
                        : "",
                    row.extectedDate
                        ? row.extectedDate.replace("T", " ").slice(0, 16)
                        : "",
                    <>
                        <OrdersTableControles
                            order={row}
                            canAdd={canAdd}
                            currentManagerId={currentManagerId}
                            onUpdateClick={onUpdateClick}
                            onViewClick={onViewClick}
                            onDeleteClick={onDeleteClick}
                            revokeOrderClick={revokeOrderClick}
                            stopOrder={stopOrder}
                        />
                    </>
                ]
            };
        },
        [currentManagerId]
    );

    const getOrders = useCallback(() => {
        ordersStore.getOrders(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            selectedStatusIds,
            showAllList ? undefined : currentManagerId,
            searchString,
            selectedDate
        );
    }, [
        page,
        rowsPerPage,
        sortBy,
        order,
        selectedStatusIds,
        showAllList,
        currentManagerId,
        searchString,
        selectedDate
    ]);

    useEffect(() => {
        getOrders();
    }, [getOrders]);

    useEffect(() => {
        if (ordersStore.needUpdate) {
            getOrders();
        }
    }, [getOrders, ordersStore.needUpdate]);

    const ordersRowCells = ordersStore.orders.map(row =>
        getOrdersRowContent(row)
    );

    return (
        <>
            <BaseTable
                headCells={ordersHeadCells}
                rowCells={ordersRowCells}
                rowCellsСount={ordersStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
            <OrderModal
                isModalOpen={isModalOpen}
                handleClose={closeModal}
                selectedOrderId={selectedItemId}
                action={action}
            />
        </>
    );
};

export default observer(OrdersTable);
