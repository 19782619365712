import MuiPhoneNumber from "material-ui-phone-number";
import React, { useCallback } from "react";
import { Controller } from "react-hook-form";

import { PhoneTextFieldControllerProps } from "./interfaces";

const PhoneTextFieldController: React.FC<PhoneTextFieldControllerProps> =
    props => {
        const { name, control, ...rest } = props;

        const render = useCallback(
            ({ field: { onChange, value }, fieldState: { error } }) => (
                <MuiPhoneNumber
                    defaultCountry="us"
                    {...rest}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            ),
            [rest]
        );
        return <Controller control={control} name={name} render={render} />;
    };
export default PhoneTextFieldController;
