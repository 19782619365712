import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { TrainingsContent } from "./Components/TrainingsContent";
import { TrainingsHeader } from "./Components/TrainingsHeader";

const Trainings: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<TrainingsHeader />}
                contentComponent={<TrainingsContent />}
            />
        </HeaderWrapper>
    );
};

export default Trainings;
