class ClientObjectRoom {
    public id = 0;
    public name = "";
    public surfaceArea = 0;

    constructor(model?: Partial<ClientObjectRoom>) {
        Object.assign(this, model);
    }
}

export default ClientObjectRoom;
