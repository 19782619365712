import { BaseTable } from "components/organisms/Tables/BaseTable";
import { useCallback } from "react";

const WorkTable: React.FC<any> = props => {
    const { workArray } = props;

    const workHeadCells = [
        { id: "work", label: "Works", sorted: false },
        { id: "startTime", label: "Start time", sorted: false },
        { id: "endTime", label: "End time", sorted: false },
        { id: "difference", label: "Period", sorted: false }
    ];

    const getWorkContent = useCallback(
        (row: any) => ({
            rowContent: ["work", row.startTime, row.endTime, row.difference]
        }),
        []
    );

    const workRowCells = workArray.map(row => getWorkContent(row));

    const rowCellsСount = workArray.length;

    return (
        <>
            {rowCellsСount ? (
                <BaseTable
                    headCells={workHeadCells}
                    rowCells={workRowCells}
                    rowCellsСount={rowCellsСount}
                    hidePagination
                />
            ) : (
                <>{"Not found"}</>
            )}
        </>
    );
};

export default WorkTable;
