import {
    Table,
    TableBody,
    TableContainer,
    TablePagination
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

import useStyles from "./styles";
import { TableHead } from "./TableHead";
import { TableRowContent } from "./TableRowContent";

export interface TableProps {
    rowCells: any;
    headCells: any[];
    rowCellsСount: number;
    tableControls?: any;
    hidePagination?: boolean;
}

const BaseTable: React.FC<TableProps> = props => {
    const classes = useStyles();
    const {
        headCells,
        rowCells,
        rowCellsСount,
        tableControls = {},
        hidePagination
    } = props;

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = tableControls;

    return (
        <>
            <TableContainer className={classes.tableContent}>
                <Table size="small">
                    <TableHead
                        order={order}
                        sortBy={sortBy}
                        headCells={headCells}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {rowCells?.map((row, index) => (
                            <>
                                <TableRowContent
                                    row={row?.rowContent}
                                    key={`row-${index}`}
                                />
                                {row?.collapse}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!hidePagination && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowCellsСount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </>
    );
};

export default observer(BaseTable);
