enum WorkStatus {
    WORKS,
    SICK_LEAVE,
    VACATION,
    FIRED
}

export const getWorkStatusLabel = (action: WorkStatus) => {
    switch (action) {
        case WorkStatus.WORKS:
            return "Works";
        case WorkStatus.SICK_LEAVE:
            return "SickLeave";
        case WorkStatus.VACATION:
            return "Vacation";
        case WorkStatus.FIRED:
            return "Fired";
    }
};

export const workStatusMenuItems = [
    { value: WorkStatus.WORKS, label: "Works" },
    { value: WorkStatus.SICK_LEAVE, label: "SickLeave" },
    { value: WorkStatus.VACATION, label: "Vacation" },
    { value: WorkStatus.FIRED, label: "Fired" }
];

export default WorkStatus;
