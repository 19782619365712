import { NfcHistoryTable } from "components/organisms/Tables/NfcHistoryTable";
import React from "react";

const NfcHistoryTab: React.FC<any> = props => {
    return (
        <>
            <NfcHistoryTable selectedEmployeeId={props.selectedEmployeeId} />
        </>
    );
};

export default NfcHistoryTab;
