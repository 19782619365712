import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { CleaningType } from "services/models/CleaningTypeModel";

import * as Api from "./api";

class CleaningTypesStore {
    totalCount?: number;
    cleaningTypes: CleaningType[] = [];
    selectedCleaningType: CleaningType;
    needUpdate = false;
    loaded = false;

    async getCleaningTypes(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            this.setLoaded(false);
            const { data } = await Api.getCleaningTypes(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );

            this.setCleaningTypes(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } finally {
            this.setLoaded(true);
        }
    }

    async addCleaningType(cleaningType: any) {
        try {
            const { data } = await Api.setCleaningType(cleaningType);
            if (data) {
                this.setUpdateRequest(true);
            }
            return true;
        } catch (error: any) {
            return false;
        }
    }

    async deleteCleaningType(id: number) {
        try {
            const { data } = await Api.deleteCleaningType(id);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    async getSelectedCleaningType(id: number) {
        try {
            this.setLoaded(false);
            const { data } = await Api.getCleaningTypeById(id);
            this.setSelectedCleaningType(data);
        } finally {
            this.setLoaded(true);
        }
    }

    async updateCleaningType(id: number, cleaningType: CleaningType) {
        try {
            await Api.updateCleaningType(id, cleaningType);
            this.setUpdateRequest(true);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    private setLoaded(loaded: boolean) {
        this.loaded = loaded;
    }

    private setCleaningTypes(cleaningTypes: CleaningType[]) {
        this.cleaningTypes = cleaningTypes.map(i => new CleaningType(i));
    }

    private setSelectedCleaningType(selectedCleaningType: CleaningType) {
        this.selectedCleaningType = selectedCleaningType;
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default CleaningTypesStore;
