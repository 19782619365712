import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: "flex",
            alignItems: "center",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline"
            }
        },
        content: {
            marginLeft: theme.spacing(1.5),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }
    })
);
