import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ChangePasswordForm } from "components/organisms/Forms/ChangePasswordForm";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import Actions, { getActionLabel } from "services/enums/Actions";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";
import { useStore } from "stores";

const ChangeProfilePasswordTab: React.FC<any> = props => {
    const { selectedUser } = props;
    const { managersStore } = useStore();

    const [result, setSuccessResult, setFaildResult] = useBooleanState(false);
    const [message, setMessage] = useState("");
    const onCloseAlert = useCallback(() => {
        setMessage("");
    }, []);

    const onSubmit = useCallback(data => {
        const managersStoreResult = managersStore.updatePassword(data);
        managersStoreResult.then(res => {
            if (res) {
                setSuccessResult();
                setMessage("Password changed");
            } else {
                setFaildResult();
                setMessage("Something went wrong");
            }
        });
    }, []);

    return (
        <Box p={2}>
            {result ? (
                <div>{message}</div>
            ) : (
                <>
                    {message && (
                        <Alert
                            variant="outlined"
                            severity="error"
                            icon={false}
                            onClose={onCloseAlert}
                        >
                            {message}
                        </Alert>
                    )}
                    {selectedUser ? (
                        <ChangePasswordForm
                            selectedId={selectedUser.id}
                            actionButtonText={getActionLabel(Actions.UPDATE)}
                            onSubmit={onSubmit}
                        />
                    ) : (
                        "Profile not found"
                    )}
                </>
            )}
        </Box>
    );
};

export default observer(ChangeProfilePasswordTab);
