import { Grid, Typography } from "@material-ui/core";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";
import { useStore } from "stores";

import { NfcTagContent } from "./Components/NfcTagContent";

const NfcTagProfile: React.FC<any> = props => {
    const { nfcTagsStore } = useStore();
    const [loaded, setLoaded] = useBooleanState(false);

    const { id } = useParams<any>();

    //TODO add 404
    const isValidId = !isNaN(Number(id));

    useEffect(() => {
        if (isValidId) {
            const result = nfcTagsStore.getSelectedNfcTag(id);
            result.then(res => {
                setLoaded();
            });
        }
    }, [id, isValidId]);

    const selectedNfcTag = toJS(nfcTagsStore.selectedNfcTag);

    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="h5">{"Edit Nfs tag"}</Typography>
                    </Grid>
                }
                contentComponent={
                    isValidId ? (
                        <NfcTagContent
                            loaded={loaded}
                            selectedNfcTag={selectedNfcTag}
                        />
                    ) : (
                        <Typography variant="h5">{"404"}</Typography>
                    )
                }
            />
        </HeaderWrapper>
    );
};

export default observer(NfcTagProfile);
