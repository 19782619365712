import {
    BaseTable,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { NfcHistory } from "services/models/NfcHistoryModel";
import { useStore } from "stores";

const NfcHistoryTable: React.FC<any> = props => {
    const { nfcHistoryStore } = useStore();

    const { selectedEmployeeId } = props;

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const getNfcTrackHistoryHeadCells = [
        { id: "date", label: "Date", sorted: true },
        { id: "tagName", label: "Tag name", sorted: true },
        { id: "tagId", label: "Tag Id", sorted: true },
        { id: "userName", label: "User name", sorted: true },
        { id: "orderId", label: "Order Id", sorted: true },
        { id: "orderComment", label: "Order comment", sorted: true },
        { id: "clientName", label: "Client name", sorted: true }
    ];

    const getNfcTagRowContent = useCallback(
        (row: NfcHistory) => ({
            rowContent: [
                row.date ? row.date.replace("T", " ").slice(0, 16) : "",
                row.tagName,
                row.tagId,
                row.userName,
                row.orderId,
                row.orderComment,
                row.clientName
            ]
        }),
        []
    );

    const getNfcTrackHistory = useCallback(() => {
        nfcHistoryStore.getNfcTrackHistory(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            selectedEmployeeId
        );
    }, [page, rowsPerPage, sortBy, order, selectedEmployeeId]);

    useEffect(() => {
        getNfcTrackHistory();
    }, [getNfcTrackHistory]);

    useEffect(() => {
        if (nfcHistoryStore.needUpdate) {
            getNfcTrackHistory();
        }
    }, [getNfcTrackHistory, nfcHistoryStore.needUpdate]);

    const nfcTrackHistoryRowCells = nfcHistoryStore.nfcTrackHistory.map(row =>
        getNfcTagRowContent(row)
    );

    return (
        <>
            {nfcHistoryStore.totalCount ? (
                <BaseTable
                    headCells={getNfcTrackHistoryHeadCells}
                    rowCells={nfcTrackHistoryRowCells}
                    rowCellsСount={nfcHistoryStore.totalCount ?? 0}
                    tableControls={{
                        order,
                        sortBy,
                        page,
                        rowsPerPage,
                        handleChangePage,
                        handleChangeRowsPerPage,
                        handleRequestSort
                    }}
                />
            ) : (
                <>{"No history found"}</>
            )}
        </>
    );
};

export default observer(NfcHistoryTable);
