import { DateTime } from "luxon";
import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getClientObjectAlerts = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    selectedAlertTypeIds?: number[],
    searchString?: string,
    selectedDate?: any
) => {
    // TODO remove States = 0 and add normal filter
    let params = `?Skip=${skip}&States=0,3`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (selectedAlertTypeIds && selectedAlertTypeIds?.length > 0)
        params += `&Types=${selectedAlertTypeIds.join(",")}`;
    if (selectedDate) {
        const newFrom = DateTime.fromObject({
            year: selectedDate.year,
            month: selectedDate.month,
            day: selectedDate.day
        })
            .toJSDate()
            .toISOString();
        const newTo = DateTime.fromObject({
            year: selectedDate.year,
            month: selectedDate.month,
            day: selectedDate.day,
            hour: 23,
            minute: 59,
            second: 59
        })
            .toJSDate()
            .toISOString();
        params += `&CreatedDateFrom=${newFrom}&CreatedDateTo=${newTo}`;
    }
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/ClientObjectAlerts${params}`);
};

export const getClientObjectAlertsById = (id: number) => {
    return $axios.get(`/api/ClientObjectAlerts/${id}`);
};

export const setClientObjectAlerts = (data: any) => {
    return $axios.post(`/api/ClientObjectAlerts`, data);
};

export const updateClientObjectAlerts = (id: number, data: any) => {
    return $axios.put(`/api/ClientObjectAlerts/${id}`, data);
};

export const deleteClientObjectAlerts = (id: number) => {
    return $axios.delete(`/api/ClientObjectAlerts/${id}`);
};
