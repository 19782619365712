import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { PhoneTextFieldController } from "components/molecules/FormControls/PhoneTextFieldController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import React from "react";

import { getClientProfileFormControls } from "./ClientProfileForm.utils";
import ClientProfileAvatar from "./component/ClientProfileAvatar";
import {
    ClientProfileFormProps,
    ClientProfileFormCallProps
} from "./interfaces";

const ClientProfileForm: React.FC<
    ClientProfileFormProps & ClientProfileFormCallProps
> = props => {
    const { selectedClient, onSubmit, disabled, actionButtonText } = props;

    const { control, onFormSubmit, register, setValue } =
        getClientProfileFormControls(selectedClient, onSubmit);

    const linkPhone = selectedClient?.contactPhone.replace(/[^0-9]/g, "");

    return (
        <form onSubmit={onFormSubmit}>
            <Grid container spacing={4} direction="row" alignItems="center">
                <Grid item>
                    <Box mx={6}>
                        <ClientProfileAvatar
                            id={selectedClient.id}
                            disabled={disabled}
                            register={register}
                            setValue={setValue}
                        />
                    </Box>
                    <Box textAlign="center" my={2}>
                        <Button
                            href={`https://api.whatsapp.com/send?phone=${linkPhone}`}
                            variant="contained"
                            color="primary"
                            target="_blank"
                        >
                            {"WhatsApp"}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs>
                    <TextFieldController
                        control={control}
                        disabled={disabled}
                        name="name"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Name"
                    />
                    <TextFieldController
                        control={control}
                        disabled={disabled}
                        name="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Email"
                    />
                    <PhoneTextFieldController
                        control={control}
                        disabled={disabled}
                        name="contactPhone"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Contact phone"
                    />
                </Grid>
            </Grid>

            {!disabled && (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {actionButtonText}
                </Button>
            )}
        </form>
    );
};
export default ClientProfileForm;
