enum OrderStatus {
    ATTENTION,
    APPOINTED,
    CONFIRMED,
    CLEANING,
    FINISHED,
    ON_HOLD,
    DECLINED,
    REVOKED
}

export const getOrderStatusLabel = (action: OrderStatus) => {
    switch (action) {
        case OrderStatus.ATTENTION:
            return "attention";
        case OrderStatus.APPOINTED:
            return "appointed";
        case OrderStatus.CONFIRMED:
            return "confirmed";
        case OrderStatus.CLEANING:
            return "cleaning";
        case OrderStatus.FINISHED:
            return "finished";
        case OrderStatus.ON_HOLD:
            return "on hold";
        case OrderStatus.DECLINED:
            return "declined";
        case OrderStatus.REVOKED:
            return "revoked";
    }
};

export const orderStatusMenuItems = [
    { value: OrderStatus.ATTENTION, label: "Attention" },
    { value: OrderStatus.APPOINTED, label: "Appointed" },
    { value: OrderStatus.CONFIRMED, label: "Confirmed" },
    { value: OrderStatus.CLEANING, label: "Cleaning" },
    { value: OrderStatus.FINISHED, label: "Finished" },
    { value: OrderStatus.ON_HOLD, label: "On hold" },
    { value: OrderStatus.DECLINED, label: "Declined" }
    // { value: OrderStatus.REVOKED, label: "Revoked" }
];

export default OrderStatus;
