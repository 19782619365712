import { Box, Button, Grid } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { StatusFilterPanel } from "components/molecules/StatusFilterPanel";
import { OrderModal } from "components/organisms/Modals/OrderModal";
import { OrdersTable } from "components/organisms/Tables/OrdersTable";
import React, { useCallback, useState } from "react";
import Actions from "services/enums/Actions";
import { orderStatusMenuItems } from "services/enums/OrderStatus";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";

const OrderTab: React.FC<any> = props => {
    const { showAllList, selectedDate } = props;

    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);
    const onItemClick = useCallback(
        id => () => {
            if (selectedStatusIds.includes(id)) {
                setSelectedStatusIds(selectedStatusIds.filter(i => i !== id));
            } else {
                setSelectedStatusIds([...selectedStatusIds, id]);
            }
        },
        [selectedStatusIds]
    );

    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={openModal}
                    >
                        {"Add order"}
                    </Button>
                </Grid>
            </Box>
            <Box p={2}>
                <StatusFilterPanel
                    statsLists={orderStatusMenuItems}
                    selectedIds={selectedStatusIds}
                    onItemClick={onItemClick}
                />
            </Box>
            <OrdersTable
                selectedStatusIds={selectedStatusIds}
                showAllList={showAllList}
                searchString={searchValue}
                selectedDate={selectedDate}
            />
            <OrderModal
                isModalOpen={isModalOpen}
                handleClose={closeModal}
                action={Actions.CREATE}
            />
        </>
    );
};

export default OrderTab;
