import { makeAutoObservable } from "mobx";
import Audit from "services/models/AuditModel/Audit";

import * as Api from "./api";

class AuditStore {
    orderAuditViewModels: Audit[] = [];
    dayTimeWorkingHours: string;
    monthTimeWorkingHours: string;

    async getAudit(id: number, date: string) {
        try {
            const { data } = await Api.getAudit(id, date);
            this.setAudit(data.orderAuditViewModels);
            this.setAuditTimes(
                data.dayTimeWorkingHours,
                data.monthTimeWorkingHours
            );
        } catch (error: any) {}
    }

    private setAudit(orderAuditViewModels: Audit[]) {
        this.orderAuditViewModels = orderAuditViewModels.map(i => new Audit(i));
    }
    private setAuditTimes(
        dayTimeWorkingHours: string,
        monthTimeWorkingHours: string
    ) {
        this.dayTimeWorkingHours = dayTimeWorkingHours;
        this.monthTimeWorkingHours = monthTimeWorkingHours;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default AuditStore;
