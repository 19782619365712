import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";

const DocumentCard: React.FC<any> = props => {
    const { documentName, onDownload, onDelete, disabled } = props;

    return (
        <Card
            style={{
                maxWidth: "600px",
                backgroundColor: "rgba(0, 0, 0, 0.08)"
            }}
        >
            <CardContent style={{ padding: "8px 16px" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid item wrap="nowrap">
                        <Typography variant="body1">{documentName}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color="primary" onClick={onDownload}>
                            <GetAppIcon />
                        </IconButton>
                        <IconButton
                            color="secondary"
                            onClick={onDelete}
                            disabled={disabled}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DocumentCard;
