import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { DevicesContent } from "./Components/DevicesContent";
import { DevicesHeader } from "./Components/DevicesHeader";

const Devices: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<DevicesHeader />}
                contentComponent={<DevicesContent />}
            />
        </HeaderWrapper>
    );
};

export default Devices;
