import { TableCell, TableRow } from "@material-ui/core";

export interface TableRowContentProps {
    row: any;
}

const TableRowContent: React.FC<TableRowContentProps> = props => {
    const { row } = props;
    return (
        <TableRow hover>
            {row?.map((item, index) => (
                <TableCell component="th" scope="row" key={`cell-${index}`}>
                    {item}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default TableRowContent;
