import { makeAutoObservable } from "mobx";
import OrderStatus from "services/enums/OrderStatus";
import SortDirection from "services/enums/SortDirection";
import { Order } from "services/models/OrderModel";
import { RootStore } from "stores";

import * as Api from "./api";

class OrdersStore {
    totalCount?: number;
    orders: Order[] = [];
    selectedOrder?: Order;
    selectedCleaningTypesToOrder: any[];
    needUpdate = false;
    routerStore: RootStore;
    loaded = false;

    async getOrders(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        selectedStatusIds?: number[],
        managerId?: number,
        searchString?: string,
        selectedDate?: any
    ) {
        try {
            this.setLoaded(false);
            const { data } = await Api.getOrders(
                skip,
                take,
                sortBy,
                direction,
                selectedStatusIds,
                managerId,
                searchString,
                selectedDate
            );

            this.setOrders(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {
        } finally {
            this.setLoaded(true);
        }
    }

    async getOrderById(id: number) {
        try {
            this.setLoaded(false);
            const { data } = await Api.getOrderById(id);
            this.setSelectedOrder(data);
        } catch (error: any) {
        } finally {
            this.setLoaded(true);
        }
    }

    async getCleaningTypesToOrder(id: number) {
        try {
            this.setLoaded(false);
            const { data } = await Api.getCleaningTypesToOrder(id);
            this.setSelectedCleaningTypesToOrder(data);
        } catch (error: any) {
        } finally {
            this.setLoaded(true);
        }
    }

    async addOrder(order: any) {
        try {
            const { cleaningTypesIds, ...orderData } = order;
            const { data } = await Api.setOrder(orderData);

            await Api.setCleaningTypesToOrder({
                id: data.id,
                cleaningTypesIds
            });
            this.setUpdateRequest(true);
        } catch (error: any) {}
    }

    async createOrderFromAlert(alertId: number, managerId: number) {
        try {
            const { data } = await Api.createOrderFromAlert({
                alertId,
                managerId
            });
            if (data) {
                this.setUpdateRequest(true);
                this.routerStore.clientObjectAlertsStore.updateRequest();
            }
        } catch (error: any) {}
    }

    async revokeOrder(id: number) {
        try {
            const { data } = await Api.revokeOrder({
                id
            });
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    async updateOrder(id: number, order: any) {
        try {
            const { cleaningTypesIds, ...orderData } = order;
            await Api.updateOrder(id, orderData);
            await Api.setCleaningTypesToOrder({
                id,
                cleaningTypesIds
            });
            this.setUpdateRequest(true);
        } catch (error: any) {}
    }

    async deleteOrder(id: number) {
        try {
            const { data } = await Api.deleteOrder(id);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    async changeStateOrder(id: number, state: OrderStatus) {
        try {
            const { data } = await Api.changeStateOrder({
                id,
                state
            });
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    private setLoaded(loaded: boolean) {
        this.loaded = loaded;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    private setOrders(orders: Order[]) {
        this.orders = orders.map(i => new Order(i));
    }

    private setSelectedOrder(selectedOrder) {
        this.selectedOrder = selectedOrder;
    }

    private setSelectedCleaningTypesToOrder(selectedCleaningTypesToOrder) {
        this.selectedCleaningTypesToOrder = selectedCleaningTypesToOrder;
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    constructor(rootStore: RootStore) {
        this.routerStore = rootStore;
        makeAutoObservable(this);
    }
}

export default OrdersStore;
