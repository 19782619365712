import RateType from "services/enums/RateType";

import SistemRole from "../../enums/SistemRole";
import WorkStatus from "../../enums/WorkStatus";

class User {
    public id = 0;
    public name = "";
    public email = "";
    public contactPhone = "";
    public password = "";
    public position = "";
    public dateOfBirtdayUTC = "";
    public state = WorkStatus.WORKS;
    public role = SistemRole.CLIENT;
    public paymentRate = 0;
    public rateType = RateType.HOURLY;

    constructor(model?: Partial<User>) {
        Object.assign(this, model);
    }
}

export default User;
