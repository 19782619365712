enum Actions {
    VIEW,
    CREATE,
    UPDATE
}

export const getActionLabel = (action: Actions) => {
    switch (action) {
        case Actions.VIEW:
            return "View";
        case Actions.CREATE:
            return "Create";
        case Actions.UPDATE:
            return "Save";
    }
};

export default Actions;
