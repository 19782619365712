import { useCallback, useState } from "react";
import Actions from "services/enums/Actions";
import SortDirection from "services/enums/SortDirection";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";
import {
    getRowsPerPageLocal,
    setRowsPerPageLocal
} from "services/utils/LocalStorageHelper";

export function getBaseTableControls() {
    const [order, setOrder] = useState<SortDirection>(SortDirection.ASC);
    const [sortBy, setSortBy] = useState<string | undefined>(undefined);

    const rowsPerPageLocal = getRowsPerPageLocal();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        rowsPerPageLocal > 0 ? rowsPerPageLocal : 5
    );
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(Number(event.target.value));
        setRowsPerPageLocal(Number(event.target.value));
        setPage(0);
    };
    const handleRequestSort = useCallback(
        property => {
            const isAsc = sortBy === property && order === SortDirection.ASC;
            setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
            setSortBy(property);
        },
        [sortBy, order]
    );

    return {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    };
}

export function getBaseActionsControls() {
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const [action, setAction] = useState<Actions>(Actions.VIEW);
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>(
        undefined
    );

    const onUpdateClick = useCallback(
        selectedId => () => {
            setSelectedItemId(selectedId);
            setAction(Actions.UPDATE);
            openModal();
        },
        []
    );
    const onViewClick = useCallback(
        selectedId => () => {
            setSelectedItemId(selectedId);
            setAction(Actions.VIEW);
            openModal();
        },
        []
    );
    return {
        isModalOpen,
        closeModal,
        action,
        selectedItemId,
        onUpdateClick,
        onViewClick
    };
}
