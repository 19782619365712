import {
    BaseTable,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import Actions from "services/enums/Actions";
import { CleaningType } from "services/models/CleaningTypeModel";
import { useStore } from "stores";

import TableControles from "./components/TableControles";

const CleaningTypesTable: React.FC<any> = props => {
    const { searchString } = props;
    const { cleaningTypesStore } = useStore();

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const history = useHistory();

    const onViewClick = useCallback(
        selectedId => () => {
            history.push(`/сleaningType/${selectedId}`);
        },
        []
    );
    const onUpdateClick = useCallback(
        selectedId => () => {
            history.push({
                pathname: `/сleaningType/${selectedId}`,
                state: {
                    action: Actions.UPDATE
                }
            });
        },
        []
    );

    const onDeleteClick = useCallback(
        selectedId => () => {
            cleaningTypesStore.deleteCleaningType(selectedId);
        },
        []
    );

    const clientObjectTypesHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "name", label: "Name", sorted: true },
        { id: "tools", label: "Tools", sorted: false },
        { id: "controls", label: "", sorted: false }
    ];

    const getClientObjectTypesRowContent = useCallback(
        (row: CleaningType) => ({
            rowContent: [
                row.id,
                row.name,
                row.tools.map(i => i.name).join(", "),
                <>
                    <TableControles
                        onViewClick={onViewClick(row.id)}
                        onDeleteClick={onDeleteClick(row.id)}
                        onUpdateClick={onUpdateClick(row.id)}
                    />
                </>
            ]
        }),
        []
    );

    const getClientObjectTypes = useCallback(() => {
        cleaningTypesStore.getCleaningTypes(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getClientObjectTypes();
    }, [getClientObjectTypes]);

    useEffect(() => {
        if (cleaningTypesStore.needUpdate) {
            getClientObjectTypes();
        }
    }, [getClientObjectTypes, cleaningTypesStore.needUpdate]);

    const clientObjectTypesRowCells = cleaningTypesStore.cleaningTypes.map(
        row => getClientObjectTypesRowContent(row)
    );

    return (
        <>
            <BaseTable
                headCells={clientObjectTypesHeadCells}
                rowCells={clientObjectTypesRowCells}
                rowCellsСount={cleaningTypesStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
        </>
    );
};

export default observer(CleaningTypesTable);
