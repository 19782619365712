/* eslint-disable react/jsx-no-bind */
import { CircularProgress } from "@material-ui/core";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { EmployeeProfileTabs } from "services/enums/EmployeeProfileTabs";
import { useStore } from "stores";

import { EmployeeProfileContent } from "./Components/EmployeeProfileContent";
import { EmployeeProfileHeader } from "./Components/EmployeeProfileHeader";

const EmployeeProfile: React.FC = () => {
    const { employeesStore } = useStore();

    const { id } = useParams<any>();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const result = employeesStore.getSelectedEmployee(id);
        result.then(res => {
            setLoaded(true);
        });
    }, [id]);
    const selectedEmployee = employeesStore.selectedEmployee;

    const [selectedTabIndex, setSelectedTabIndex] = useState(
        EmployeeProfileTabs.PROFILE
    );

    const [selectedAuditDate, setSelectedAuditDate] = useState(DateTime.now());

    return (
        <HeaderWrapper>
            {loaded ? (
                <BasePage
                    headerComponent={
                        <EmployeeProfileHeader
                            header={selectedEmployee?.name}
                            selectedAuditDate={selectedAuditDate}
                            setSelectedAuditDate={setSelectedAuditDate}
                            selectedTabIndex={selectedTabIndex}
                        />
                    }
                    contentComponent={
                        <EmployeeProfileContent
                            selectedEmployee={selectedEmployee}
                            selectedTabIndex={selectedTabIndex}
                            selectedAuditDate={selectedAuditDate.toISODate()}
                            setSelectedTabIndex={setSelectedTabIndex}
                        />
                    }
                />
            ) : (
                <CircularProgress />
            )}
        </HeaderWrapper>
    );
};

export default observer(EmployeeProfile);
