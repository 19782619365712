import { Box, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

import { CleaningTypesTab } from "../CleaningTypesTab";
import { ToolsTab } from "../ToolsTab";

const ToolsContent: React.FC = props => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);

    return (
        <>
            <Paper elevation={3}>
                <Box pt={2} px={2}>
                    <Grid
                        container
                        justify="space-between"
                        alignItems="baseline"
                    >
                        <Tabs
                            value={selectedTabIndex}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="Tools" />
                            <Tab label="Work types" />
                        </Tabs>
                    </Grid>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <ToolsTab />
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <CleaningTypesTab />
                </TabPanel>
            </Paper>
        </>
    );
};

export default observer(ToolsContent);
