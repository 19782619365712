import { Grid } from "@material-ui/core";
import { StatusItem } from "components/atoms/StatusItem";
import React from "react";

const StatusFilterPanel: React.FC<any> = props => {
    const { statsLists, selectedIds, onItemClick } = props;

    return (
        <Grid container spacing={1}>
            {statsLists.map(i => {
                return (
                    <Grid item key={i.value}>
                        <StatusItem
                            text={i.label}
                            onClick={onItemClick(i.value)}
                            selected={selectedIds.includes(i.value)}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default StatusFilterPanel;
