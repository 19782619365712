class Tool {
    public id = 0;
    public name = "";
    public description = "";
    public use = "";

    constructor(model?: Tool) {
        Object.assign(this, model);
    }
}

export default Tool;
