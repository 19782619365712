import { Box, Grid } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { StatusFilterPanel } from "components/molecules/StatusFilterPanel";
import { ClientObjectAlertsTable } from "components/organisms/Tables/ClientObjectAlertsTable";
import React, { useCallback, useState } from "react";
import { alertTypeMenuItems } from "services/enums/AlertType";

const AlertTab: React.FC<any> = props => {
    const { selectedDate } = props;
    const [selectedAlertTypeIds, setSelectedAlertTypeIds] = useState<number[]>(
        []
    );

    const [searchValue, setSearchValue] = useState("");

    const onItemClick = useCallback(
        id => () => {
            if (selectedAlertTypeIds.includes(id)) {
                setSelectedAlertTypeIds(
                    selectedAlertTypeIds.filter(i => i !== id)
                );
            } else {
                setSelectedAlertTypeIds([...selectedAlertTypeIds, id]);
            }
        },
        [selectedAlertTypeIds]
    );

    return (
        <>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </Grid>
            </Box>
            <Box p={2}>
                <StatusFilterPanel
                    statsLists={alertTypeMenuItems}
                    selectedIds={selectedAlertTypeIds}
                    onItemClick={onItemClick}
                />
            </Box>
            <ClientObjectAlertsTable
                selectedAlertTypeIds={selectedAlertTypeIds}
                searchString={searchValue}
                selectedDate={selectedDate}
            />
        </>
    );
};

export default AlertTab;
