import MuiTooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import React from "react";

import useStyles from "./styles";

const Tooltip: React.FC<TooltipProps> = props => {
    const classes = useStyles();

    return (
        <MuiTooltip {...props} classes={{ tooltip: classes.tooltip }}>
            {props.children}
        </MuiTooltip>
    );
};

export default Tooltip;
