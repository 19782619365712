import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

const ManagerProfileHeader: React.FC<any> = props => {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{props.header}</Typography>
        </Grid>
    );
};

export default observer(ManagerProfileHeader);
