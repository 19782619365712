import { observer } from "mobx-react-lite";
import React from "react";

import { Agreement } from "./Components/Agreement";
import { PersonalDocument } from "./Components/PersonalDocument";

const DocumentTab: React.FC<any> = props => {
    const { selectedEmployeeId, disabled } = props;

    return (
        <>
            <Agreement
                selectedEmployeeId={selectedEmployeeId}
                disabled={disabled}
            />
            <PersonalDocument
                selectedEmployeeId={selectedEmployeeId}
                disabled={disabled}
            />
        </>
    );
};

export default observer(DocumentTab);
