export enum EmployeeProfileTabs {
    PROFILE,
    DOCUMENT,
    NFC_HISTORY,
    AUDIT,
    CHANGE_PASSWORD
}

export const getEmployeeProfileTabsLabel = (tab: EmployeeProfileTabs) => {
    switch (tab) {
        case EmployeeProfileTabs.PROFILE:
            return "Profile";
        case EmployeeProfileTabs.DOCUMENT:
            return "Personal document";
        case EmployeeProfileTabs.NFC_HISTORY:
            return "Nfc history";
        case EmployeeProfileTabs.AUDIT:
            return "Audit";
        case EmployeeProfileTabs.CHANGE_PASSWORD:
            return "Change password";
    }
};
