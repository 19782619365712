class Training {
    public id: number;
    public url: string;
    public description: string;
    public name: string;
    public hashtags: string;
    public author: string;

    constructor(model?: Training) {
        if (model) {
            this.id = model.id;
            this.url = model.url;
            this.description = model.description;
            this.name = model.name;
            this.hashtags = model.hashtags;
            this.author = model.author;
        } else {
            this.id = 0;
            this.url = "";
            this.description = "";
            this.name = "";
            this.hashtags = "";
            this.author = "";
        }
    }
}

export default Training;
