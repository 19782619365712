import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { Device } from "services/models/DeviceModel";

import * as Api from "./api";

class DevicesStore {
    totalCount?: number;
    devices: Device[] = [];
    needUpdate = false;

    async getDevices(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            const { data } = await Api.getDevices(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );

            this.setDevices(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {}
    }

    async updateDevice(id: number, device: any) {
        try {
            const { data } = await Api.updateDevice(id, device);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    async deleteDevice(id: number) {
        try {
            const { data } = await Api.deleteDevice(id);
            if (data) {
                this.setUpdateRequest(true);
                return true;
            } else {
                return false;
            }
        } catch (error: any) {
            return false;
        }
    }

    private setDevices(devices: Device[]) {
        this.devices = devices.map(i => new Device(i));
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default DevicesStore;
