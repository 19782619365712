import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { NewEmployeeProfileContent } from "./Components/NewEmployeeProfileContent";
import { NewEmployeeProfileHeader } from "./Components/NewEmployeeProfileHeader";

const NewEmployeeProfile: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<NewEmployeeProfileHeader />}
                contentComponent={<NewEmployeeProfileContent />}
            />
        </HeaderWrapper>
    );
};

export default NewEmployeeProfile;
