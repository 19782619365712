import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: "unset",
            textDecoration: "none"
        }
    })
);
