import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        app: {
            height: "100vh",
            overflow: "hidden",
            background: "#E5E5E5"
        }
    })
);
