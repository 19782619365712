import {
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import classNames from "classnames";
import { NavLinkWrapper } from "components/atoms/NavLinkWrapper";
import React, { PropsWithChildren, useState } from "react";

import { DrawerMenuItemProps } from "./interfaces";

const useStyles = makeStyles(() => ({
    root: {
        whiteSpace: "nowrap",
        "&$selected": {
            backgroundColor: "transparent"
        }
    },
    selected: {}
}));

const DrawerMenuItem: React.FC<DrawerMenuItemProps> = props => {
    const classes = useStyles();
    const { item, activeColorClass } = props;
    const [isActive, setIsActive] = useState(false);
    return (
        <NavLinkWrapper
            path={item.path}
            exact={item.exact}
            setIsActive={setIsActive}
        >
            <ListItem
                button
                selected={isActive}
                classes={{
                    root: classNames(classes.root, {
                        [activeColorClass]: isActive
                    }),
                    selected: classes.selected
                }}
            >
                <ListItemIcon
                    classes={{
                        root: classNames({
                            [activeColorClass]: isActive
                        })
                    }}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
            </ListItem>
        </NavLinkWrapper>
    );
};
export default React.memo<PropsWithChildren<DrawerMenuItemProps>>(
    DrawerMenuItem
);
