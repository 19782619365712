import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { DateFieldController } from "components/molecules/FormControls/DateFieldController";
import { PhoneTextFieldController } from "components/molecules/FormControls/PhoneTextFieldController";
import { SelectController } from "components/molecules/FormControls/SelectController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import React from "react";
import { DisplayDateType } from "services/enums/DisplayDateType";
import { rateTypeMenuItems } from "services/enums/RateType";
import { workStatusMenuItems } from "services/enums/WorkStatus";

import EmployeeProfileAvatar from "./component/EmployeeProfileAvatar";
import { getEmployeeProfileControls } from "./EmployeeProfileForm.utils";
import {
    EmployeeProfileFormProps,
    EmployeeProfileFormCallProps
} from "./interfaces";

const EmployeeProfileForm: React.FC<
    EmployeeProfileFormProps & EmployeeProfileFormCallProps
> = props => {
    const { selectedEmployee, disabled, onSubmit, actionButtonText } = props;

    const { control, onFormSubmit, register, setValue } =
        getEmployeeProfileControls(selectedEmployee, onSubmit);

    const linkPhone = selectedEmployee?.contactPhone.replace(/[^0-9]/g, "");
    const selectedEmployeeId = selectedEmployee?.id;

    return (
        <form onSubmit={onFormSubmit}>
            <Grid container spacing={4} direction="row" alignItems="center">
                <Grid item>
                    <Box mx={6}>
                        <EmployeeProfileAvatar
                            selectedEmployeeId={selectedEmployeeId}
                            disabled={disabled}
                            register={register}
                            setValue={setValue}
                        />
                    </Box>
                    <Box textAlign="center" my={2}>
                        <Button
                            href={`https://api.whatsapp.com/send?phone=${linkPhone}`}
                            variant="contained"
                            color="primary"
                            target="_blank"
                        >
                            {"WhatsApp"}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs>
                    <TextFieldController
                        control={control}
                        disabled={disabled}
                        name="name"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Name"
                    />
                    <TextFieldController
                        control={control}
                        disabled={disabled}
                        name="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                    />
                    <PhoneTextFieldController
                        control={control}
                        disabled={disabled}
                        name="contactPhone"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="contactPhone"
                        label="Contact phone"
                    />
                </Grid>
            </Grid>
            <DateFieldController
                control={control}
                disabled={disabled}
                id="dateOfBirtdayUTC"
                name="dateOfBirtdayUTC"
                label="Date of birtday"
                type={DisplayDateType.DATE}
                fullWidth
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                PopoverProps={{
                    disableAutoFocus: true,
                    disableEnforceFocus: true
                }}
                autoOk
            />
            <TextFieldController
                control={control}
                disabled={disabled}
                name="position"
                variant="outlined"
                margin="normal"
                fullWidth
                id="position"
                label="Position"
            />

            <SelectController
                control={control}
                name="state"
                label="Status"
                menuItems={workStatusMenuItems}
                formControlProps={{
                    disabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <SelectController
                control={control}
                name="rateType"
                label="Rate type"
                menuItems={rateTypeMenuItems}
                formControlProps={{
                    disabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <TextFieldController
                control={control}
                name="paymentRate"
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={disabled}
                id="paymentRate"
                label="Payment rate"
                type="number"
            />

            {!disabled && (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {actionButtonText}
                </Button>
            )}
        </form>
    );
};
export default EmployeeProfileForm;
