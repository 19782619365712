import { yupResolver } from "@hookform/resolvers/yup";
import { toJS } from "mobx";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Actions from "services/enums/Actions";
import { ClientObject } from "services/models/ClientObjectModel";
import { ClientObjectRoom } from "services/models/ClientObjectRoomModel";
import { useStore } from "stores";
import * as yup from "yup";

function getFormControls(сlientObject) {
    const validators = yup.object().shape({
        clientId: yup.number().required("Field is required").nullable(),
        name: yup.string().trim().required("Field is required").nullable(),
        address: yup.string().trim().required("Field is required").nullable(),
        roomsCount: yup.number().required("Field is required").nullable(),
        totalSquare: yup.number().required("Field is required").nullable(),
        rooms: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup
                        .string()
                        .trim()
                        .required("Field is required")
                        .nullable(),
                    surfaceArea: yup
                        .number()
                        .required("Field is required")
                        .nullable()
                        .min(1, "Must be greater than 0")
                })
            )
            .required("Field is required")
            .min(1, "The number of rooms cannot be less than 1")
    });

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: {
            id: сlientObject.id,
            clientId: сlientObject.clientId,
            name: сlientObject.name,
            address: сlientObject.address,
            roomsCount: сlientObject.roomsCount,
            totalSquare: сlientObject.totalSquare,
            rooms: сlientObject.rooms
        }
    });
    const {
        fields: roomsField,
        append,
        remove
    } = useFieldArray({
        control,
        name: "rooms"
    });

    const handleAddRoom = useCallback(() => {
        append(new ClientObjectRoom());
    }, [append]);

    const handleRemoveRoom = useCallback(
        index => () => {
            remove(index);
        },
        [remove]
    );
    return {
        control,
        handleSubmit,
        roomsField,
        handleAddRoom,
        handleRemoveRoom,
        errors
    };
}

function getClientSelectMenuItems(clients) {
    const clientSelectMenuItems = clients.map(i => {
        return { value: i.id, label: i.name };
    });
    return clientSelectMenuItems;
}

function getSelectedClientObject(clientObjects, selectedClientObjectId) {
    const selectedClientObject = new ClientObject(
        toJS(clientObjects.find(i => i.id === selectedClientObjectId))
    );
    return selectedClientObject;
}

export function getClientObjectFormControls(
    selectedClientObjectId,
    action,
    onSubmit
) {
    const { сlientObjectsStore, clientsStore } = useStore();

    useEffect(() => {
        clientsStore.getClients();
        // TODO
        // сlientObjectsStore.getClientObjects();
    }, []);

    const selectedClientObject = useMemo(
        () =>
            getSelectedClientObject(
                сlientObjectsStore.clientObjects,
                selectedClientObjectId
            ),
        [сlientObjectsStore.clientObjects, selectedClientObjectId]
    );

    const clientSelectMenuItems = useMemo(
        () => getClientSelectMenuItems(clientsStore.clients),
        [clientsStore.clients]
    );

    const {
        control,
        handleSubmit,
        roomsField,
        handleAddRoom,
        handleRemoveRoom,
        errors
    } = getFormControls(selectedClientObject);

    const onFormSubmit = handleSubmit(data => {
        if (action === Actions.UPDATE) {
            сlientObjectsStore.updateClientObject(
                Number(selectedClientObjectId),
                data
            );
        }
        if (action === Actions.CREATE) {
            сlientObjectsStore.addClientObject(data);
        }
        onSubmit();
    });

    const disabled = action === Actions.VIEW;

    return {
        control,
        onFormSubmit,
        disabled,
        clientSelectMenuItems,
        roomsField,
        handleAddRoom,
        handleRemoveRoom,
        errors
    };
}
