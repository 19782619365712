import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { CleaningTypeForm } from "components/organisms/Forms/CleaningTypeForm";
import { useCallback } from "react";
import { useHistory } from "react-router";
import Actions, { getActionLabel } from "services/enums/Actions";
import { CleaningType } from "services/models/CleaningTypeModel";
import { useStore } from "stores";

const NewCleaningType: React.FC = () => {
    const { cleaningTypesStore } = useStore();
    const newCleaningType = new CleaningType();
    const history = useHistory();

    const onSubmit = useCallback((data: any) => {
        const result = cleaningTypesStore.addCleaningType(data);
        result.then(res => {
            if (res) {
                history.goBack();
            }
        });
    }, []);

    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="h5">
                            {"Add client object type"}
                        </Typography>
                    </Grid>
                }
                contentComponent={
                    <Paper elevation={3}>
                        <Box p={2}>
                            <CleaningTypeForm
                                cleaningType={newCleaningType}
                                actionButtonText={getActionLabel(
                                    Actions.CREATE
                                )}
                                onSubmit={onSubmit}
                            />
                        </Box>
                    </Paper>
                }
            />
        </HeaderWrapper>
    );
};

export default NewCleaningType;
