import { makeAutoObservable } from "mobx";
import { User } from "services/models/UserModels";
import {
    removeAccessToken,
    setAccessToken
} from "services/utils/LocalStorageHelper";

import * as Api from "./api";

class LoginStore {
    user?: User;
    authorizationChecked = false;
    errorMessage?: string;
    avatarSrc?: any;

    async login(login: string, password: string) {
        try {
            const { data } = await Api.login({ login, password });
            setAccessToken(data.access_token);
            if (data) {
                this.checkAuthorization();
            }
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async logout() {
        try {
            this.removeUser();
            removeAccessToken();
            await Api.logout();
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async checkAuthorization() {
        try {
            const { data } = await Api.check();
            this.setUser(data);
            const response = await Api.getPhoto(data.id);
            const url = URL.createObjectURL(response.data);
            this.setAvatarSrc(url);
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        } finally {
            this.setAuthorizationChecked(true);
        }
    }

    removeErrorMessage() {
        this.errorMessage = undefined;
    }

    private setAuthorizationChecked(authorizationChecked: boolean) {
        this.authorizationChecked = authorizationChecked;
    }

    private setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    private setUser(user: User) {
        this.user = new User(user);
    }
    private removeUser() {
        this.user = undefined;
    }

    private setAvatarSrc(avatarSrc: any) {
        this.avatarSrc = avatarSrc;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default LoginStore;
