import OrderStatus from "../../enums/OrderStatus";
import OrderType from "../../enums/OrderType";

class Order {
    public id = 0;
    public clientId = 0;
    public clientName = "";
    public clientObjectId = 0;
    public clientObjectName = "";
    public clientObjectAddress = "";
    public employeeId = 0;
    public employeeName = "";
    public managerId = 0;
    public managerName = "";
    public state: OrderStatus = OrderStatus.ON_HOLD;
    public type: OrderType = OrderType.SINGLE_INSTANCE;
    public createdDate = "";
    public extectedDate = "";
    public actualDate = "";
    public recurrentParentId: number | null = null;
    public clientObjectAlertId: number | null = null;
    public managerPhone = "";
    public comment = "";
    public declineReason = "";
    public attentionReason = "";
    public startTime = "";
    public endTime = "";
    public startLongitude = "";
    public startLatitude = "";
    public finishLongitude = "";
    public finishLatitude = "";

    constructor(model?: Partial<Order>) {
        Object.assign(this, model);
    }
}

export default Order;
