import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { User } from "services/models/UserModels";

import * as Api from "./api";

class AdminsStore {
    totalCount?: number;
    admins: User[] = [];
    selectedAdmin: User;
    needUpdate = false;
    errorMessage?: string;

    async getAdmins(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            const { data } = await Api.getAdmins(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );
            this.setAdmins(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async addAdmin(admin: any) {
        try {
            const { data } = await Api.setAdmin(admin);
            if (data) {
                this.setUpdateRequest(true);
            }
            return true;
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
            return false;
        }
    }

    async getSelectedAdmin(id: number) {
        try {
            const { data } = await Api.getAdmin(id);
            this.setSelectedAdmin(data);
            return true;
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
            return false;
        }
    }

    async updateAdmin(id: number, admin: any) {
        try {
            const { data } = await Api.updateAdmin(id, admin);
            this.setUpdateRequest(true);
            return true;
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
            return false;
        }
    }

    async updatePassword(newPassword: any) {
        try {
            const { data } = await Api.updatePassword(newPassword);
            if (data) {
                return true;
            } else {
                return false;
            }
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
            return false;
        }
    }

    async deleteAdmin(id: number) {
        try {
            const { data } = await Api.deleteAdmin(id);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async getPhoto(id: number) {
        try {
            const response = await Api.getPhoto(id);
            return response.data;
        } catch (error: any) {
            return false;
        }
    }

    async setPhoto(id: number, file: any) {
        try {
            const { data } = await Api.setPhoto(id, file);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    removeErrorMessage() {
        this.errorMessage = undefined;
    }

    private setAdmins(admins: User[]) {
        this.admins = admins.map(i => new User(i));
    }

    private setSelectedAdmin(admin: User) {
        this.selectedAdmin = admin;
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    private setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default AdminsStore;
