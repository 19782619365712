class NfcHistory {
    public userId = 0;
    public userName = "";
    public tagId = "";
    public tagName = "";
    public date = "";
    public orderId = 0;
    public orderComment = "";
    public clientId = 0;
    public clientName = "";

    constructor(model?: Partial<NfcHistory>) {
        Object.assign(this, model);
    }
}

export default NfcHistory;
