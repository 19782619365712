import { Box, Grid, Paper } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { DevicesTable } from "components/organisms/Tables/DevicesTable";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const DevicesContent: React.FC = props => {
    const [searchValue, setSearchValue] = useState("");
    return (
        <Paper elevation={3}>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </Grid>
            </Box>
            <DevicesTable searchString={searchValue} />
        </Paper>
    );
};

export default observer(DevicesContent);
