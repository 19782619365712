import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { generatePassword } from "services/utils/GeneratePassword";
import * as yup from "yup";

export function getChangePasswordFormControls(selectedId, onSubmit) {
    const validators = yup.object().shape({
        password: yup.string().required("Field is required").nullable(),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
    });

    const { control, handleSubmit, setValue } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: {
            id: selectedId,
            password: "",
            passwordConfirmation: ""
        }
    });

    const generateNewPassword = useCallback(() => {
        const random = generatePassword(8);
        setValue("password", random);
        setValue("passwordConfirmation", random);
    }, []);

    const onFormSubmit = handleSubmit((data: any) => {
        const { passwordConfirmation, ...restData } = data;
        onSubmit(restData);
    });

    return {
        control,
        onFormSubmit,
        generateNewPassword
    };
}
