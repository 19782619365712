import { IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { appUrls } from "App/routes/appUrls";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import {
    getDrawerMenuIsOpenLocal,
    setDrawerMenuIsOpenLocal
} from "services/utils/LocalStorageHelper";
import { useStore } from "stores";

import { CurrentUser } from "../CurrentUser";
import { DrawerMenu } from "../DrawerMenu";
import useStyles from "./styles";

const HeaderWrapper: React.FC = props => {
    const classes = useStyles();
    const { loginStore } = useStore();

    const isOpenLocal = getDrawerMenuIsOpenLocal();

    const [menuIsOpen, setMenuIsOpen] = useState(isOpenLocal);

    const openDrawerMenu = () => {
        setDrawerMenuIsOpenLocal(true);
        setMenuIsOpen(true);
    };
    const closeDrawerMenu = () => {
        setDrawerMenuIsOpenLocal(false);
        setMenuIsOpen(false);
    };

    return (
        <div className={classes.wrapper}>
            <DrawerMenu open={menuIsOpen} />
            <div className={classes.content}>
                <header className={classes.header}>
                    {menuIsOpen ? (
                        <IconButton onClick={closeDrawerMenu}>
                            <MenuOpenIcon />
                        </IconButton>
                    ) : (
                        <IconButton onClick={openDrawerMenu}>
                            <ChevronRightIcon />
                        </IconButton>
                    )}
                    {loginStore.user ? (
                        <CurrentUser />
                    ) : (
                        // TODO style
                        <RouterLink to={appUrls.login} exact={true}>
                            {"Login"}
                        </RouterLink>
                    )}
                </header>
                <div className={classes.children}>{props.children}</div>
            </div>
        </div>
    );
};
export default observer(HeaderWrapper);
