import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useCallback } from "react";
import { Controller } from "react-hook-form";

import { MultipleSelectControllerProps } from "./interfaces";
import useStyles from "./styles";

const MultipleSelectController: React.FC<MultipleSelectControllerProps> =
    props => {
        const {
            name,
            control,
            label,
            menuItems,
            formControlProps,
            selectProps
        } = props;

        const classes = useStyles();

        const getRenderValue = useCallback(
            selected =>
                selected
                    ?.map(
                        option =>
                            menuItems.find(item => item.value === option)?.label
                    )
                    .join(", "),
            [menuItems]
        );
        const render = useCallback(
            ({ field: { onChange, value } }) => {
                return (
                    <FormControl {...formControlProps}>
                        <InputLabel id="select-label">{label}</InputLabel>
                        <Select
                            labelId="select-label"
                            multiple
                            value={value}
                            onChange={onChange}
                            label={label}
                            renderValue={getRenderValue}
                            MenuProps={{
                                classes: { paper: classes.menuPaper },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                            {...selectProps}
                        >
                            {menuItems.map(item => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            },
            [props, getRenderValue]
        );

        return <Controller control={control} name={name} render={render} />;
    };
export default MultipleSelectController;
