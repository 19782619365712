import { Box, IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { DateFieldController } from "components/molecules/FormControls/DateFieldController";
import { PhoneTextFieldController } from "components/molecules/FormControls/PhoneTextFieldController";
import { SelectController } from "components/molecules/FormControls/SelectController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import React, { useCallback, useState } from "react";
import Actions, { getActionLabel } from "services/enums/Actions";
import { DisplayDateType } from "services/enums/DisplayDateType";
import { rateTypeMenuItems } from "services/enums/RateType";
import { workStatusMenuItems } from "services/enums/WorkStatus";

import { NewEmployeeFormCallProps } from "./interfaces";
import { getNewEmployeeControls } from "./NewEmployeeForm.utils";

const NewEmployeeForm: React.FC<NewEmployeeFormCallProps> = props => {
    const { onSubmit } = props;

    const { control, onFormSubmit, generateNewPassword } =
        getNewEmployeeControls(onSubmit);

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = useCallback(
        () => setShowPassword(prevState => !prevState),
        []
    );

    return (
        <form onSubmit={onFormSubmit}>
            <TextFieldController
                control={control}
                name="name"
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Name"
            />
            <TextFieldController
                control={control}
                name="email"
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
            />
            <PhoneTextFieldController
                control={control}
                name="contactPhone"
                variant="outlined"
                margin="normal"
                fullWidth
                id="contactPhone"
                label="Contact phone"
            />
            <DateFieldController
                control={control}
                id="dateOfBirtdayUTC"
                name="dateOfBirtdayUTC"
                label="Date of birtday"
                type={DisplayDateType.DATE}
                fullWidth
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                PopoverProps={{
                    disableAutoFocus: true,
                    disableEnforceFocus: true
                }}
                autoOk
            />
            <TextFieldController
                control={control}
                name="position"
                variant="outlined"
                margin="normal"
                fullWidth
                id="position"
                label="Position"
            />

            <SelectController
                control={control}
                name="state"
                label="Status"
                menuItems={workStatusMenuItems}
                formControlProps={{
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <SelectController
                control={control}
                name="rateType"
                label="Rate type"
                menuItems={rateTypeMenuItems}
                formControlProps={{
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <TextFieldController
                control={control}
                name="paymentRate"
                variant="outlined"
                margin="normal"
                fullWidth
                id="paymentRate"
                label="Payment rate"
                type="number"
            />
            <TextFieldController
                control={control}
                name="password"
                variant="outlined"
                margin="normal"
                fullWidth
                label="password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword}>
                                {showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <TextFieldController
                control={control}
                name="passwordConfirmation"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Confirm password"
                type="password"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={generateNewPassword}
            >
                {"Generate password"}
            </Button>

            <Box mt={1}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {getActionLabel(Actions.CREATE)}
                </Button>
            </Box>
        </form>
    );
};
export default NewEmployeeForm;
