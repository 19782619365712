import { Grid, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { DateFieldController } from "components/molecules/FormControls/DateFieldController";
import { MultipleSelectController } from "components/molecules/FormControls/MultipleSelectController";
import { SelectController } from "components/molecules/FormControls/SelectController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { observer } from "mobx-react-lite";
import React from "react";
import { getActionLabel } from "services/enums/Actions";
import { DisplayDateType } from "services/enums/DisplayDateType";

import { OrderFormProps, OrderFormCallProps } from "./interfaces";
import { getOrderFormControls } from "./OrderForm.utils";

const OrderForm: React.FC<OrderFormProps & OrderFormCallProps> = props => {
    const { selectedOrder, selectedCleaningTypesToOrder, action, onSubmit } =
        props;

    const {
        onFormSubmit,
        onFormSend,
        sendDisabled,
        control,
        clientSelectMenuItems,
        disabled,
        objectSelectMenuItems,
        clientObjectIdDisabled,
        objectAddress,
        employeeSelectMenuItems,
        cleaningTypeSelectMenuItems
    } = getOrderFormControls(
        selectedOrder,
        selectedCleaningTypesToOrder,
        action,
        onSubmit
    );

    return (
        <form onSubmit={onFormSubmit}>
            <SelectController
                control={control}
                name="clientId"
                label="Client"
                menuItems={clientSelectMenuItems}
                formControlProps={{
                    disabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <SelectController
                control={control}
                name="clientObjectId"
                label="Object"
                menuItems={objectSelectMenuItems}
                formControlProps={{
                    disabled: clientObjectIdDisabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <TextField
                disabled
                variant="outlined"
                margin="normal"
                fullWidth
                label="Object's address"
                value={objectAddress}
            />
            <SelectController
                control={control}
                name="employeeId"
                label="Employee"
                menuItems={employeeSelectMenuItems}
                formControlProps={{
                    disabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <DateFieldController
                control={control}
                disabled={disabled}
                id="ExtectedDate"
                name="extectedDate"
                label="Cleaning expected"
                type={DisplayDateType.DATE_TIME}
                fullWidth
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                PopoverProps={{
                    disableAutoFocus: true,
                    disableEnforceFocus: true
                }}
                autoOk
            />
            <MultipleSelectController
                control={control}
                name="cleaningTypesIds"
                label="Cleaning types ids"
                menuItems={cleaningTypeSelectMenuItems}
                formControlProps={{
                    disabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <TextFieldController
                control={control}
                multiline
                rows={2}
                maxRows={5}
                name="comment"
                variant="outlined"
                margin="normal"
                fullWidth
                id="comment"
                label="Comment"
            />
            {!disabled && (
                <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="primary"
                        >
                            {getActionLabel(action)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={sendDisabled}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onFormSend}
                        >
                            {"Send order"}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </form>
    );
};
export default observer(OrderForm);
