enum OrderType {
    SINGLE_INSTANCE,
    RECURRENT_PARENT,
    RECURRENT_INCTANCE
}

export const getOrderTypeLabel = (action: OrderType) => {
    switch (action) {
        case OrderType.SINGLE_INSTANCE:
            return "SingleInstance";
        case OrderType.RECURRENT_PARENT:
            return "RecurrentParent";
        case OrderType.RECURRENT_INCTANCE:
            return "RecurrentInctance";
    }
};

export const orderTypeMenuItems = [
    { value: OrderType.SINGLE_INSTANCE, label: "SingleInstance" },
    { value: OrderType.RECURRENT_PARENT, label: "RecurrentParent" },
    { value: OrderType.RECURRENT_INCTANCE, label: "RecurrentInctance" }
];

export default OrderType;
