import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        userProfile: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px"
        },
        content: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            width: "100%",
            maxWidth: "780px",
            minHeight: "300px",
            maxHeight: "690px",
            borderRadius: "10px",
            background: "rgb(255, 255, 255)"
        },
        closeButton: {
            display: "flex",
            justifyContent: "flex-end"
        },
        form: {
            padding: "0 20px 10px 20px",
            flexGrow: 1,
            overflow: "auto"
        }
    })
);
