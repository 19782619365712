import { Box, Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { NewEmployeeForm } from "components/organisms/Forms/NewEmployeeForm";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useStore } from "stores";

const NewAdminProfileContent: React.FC = () => {
    const { adminsStore } = useStore();

    const [message, setMessage] = useState("");
    const onCloseAlert = useCallback(() => {
        setMessage("");
    }, []);

    const history = useHistory();

    const onSubmit = useCallback(data => {
        const result = adminsStore.addAdmin(data);
        result.then(res => {
            if (res) {
                history.goBack();
            } else {
                setMessage("Something went wrong");
            }
        });
    }, []);

    return (
        <Paper elevation={3}>
            <Box p={2}>
                {message && (
                    <Alert
                        variant="outlined"
                        severity="error"
                        icon={false}
                        onClose={onCloseAlert}
                    >
                        {message}
                    </Alert>
                )}
                <NewEmployeeForm onSubmit={onSubmit} />
            </Box>
        </Paper>
    );
};

export default observer(NewAdminProfileContent);
