const urls = {
    home: "/",
    myProfile: "/myProfile",
    dashboard: "/dashboard",
    clients: "/clients",
    clientProfile: "/client/:id",
    newClient: "/newClient",
    employees: "/employees",
    newManager: "/employees/newManager",
    managerProfile: "/employees/managers/:id",
    newAdmin: "/employees/newAdmin",
    adminProfile: "/employees/admins/:id",
    employeeProfile: "/employee/:id",
    newEmployee: "/newEmployee",
    analytics: "/analytics",
    administration: "/administration",
    trainings: "/trainings",
    devices: "/devices",
    qrFeedback: "/qrFeedback",
    tools: "/tools",
    nfcTags: "/nfcTags",
    nfcTagById: "/nfcTags/:id",
    сleaningType: "/сleaningType/:id",
    newCleaningType: "/newCleaningType",
    login: "/login"
};

export { urls as appUrls };
