import { Grid, Typography } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { FromToDatePicker } from "components/molecules/FromToDatePicker";
import { observer } from "mobx-react-lite";
import React from "react";
import { EmployeeProfileTabs } from "services/enums/EmployeeProfileTabs";

const EmployeeProfileHeader: React.FC<any> = props => {
    const {
        header,
        selectedAuditDate,
        setSelectedAuditDate,
        selectedTabIndex
    } = props;

    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{header}</Typography>
            <TabPanel
                value={selectedTabIndex}
                index={EmployeeProfileTabs.AUDIT}
            >
                <Grid item>
                    <FromToDatePicker
                        selectedDate={selectedAuditDate}
                        setSelectedDate={setSelectedAuditDate}
                    />
                </Grid>
            </TabPanel>
        </Grid>
    );
};

export default observer(EmployeeProfileHeader);
