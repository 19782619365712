import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4, 5, 8, 5)
        },
        header: {
            flexShrink: 0
        },
        content: {
            marginTop: theme.spacing(3),
            flexGrow: 1
        }
    })
);
