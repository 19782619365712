import { Box, Button, Grid } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { ToolsModal } from "components/organisms/Modals/ToolsModal";
import { ToolsTable } from "components/organisms/Tables/ToolsTable";
import React, { useState } from "react";
import Actions from "services/enums/Actions";
import useBooleanState from "services/utils/CustomHooks/useBooleanState";

const ToolsTab: React.FC = props => {
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={openModal}
                    >
                        {"Add tool"}
                    </Button>
                </Grid>
            </Box>
            <ToolsTable searchString={searchValue} />
            <ToolsModal
                isModalOpen={isModalOpen}
                handleClose={closeModal}
                action={Actions.CREATE}
            />
        </>
    );
};

export default ToolsTab;
