import {
    BaseTable,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import Actions from "services/enums/Actions";
import { NfcTag } from "services/models/NfcTagModel";
import { useStore } from "stores";

import NfcTagsTableControles from "./components/NfcTagsTableControles";

const NfcTagsTable: React.FC<any> = props => {
    const { searchString } = props;
    const { nfcTagsStore } = useStore();

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const history = useHistory();

    const onUpdateClick = useCallback(
        selectedId => () => {
            history.push({
                pathname: `/nfcTags/${selectedId}`,
                state: {
                    action: Actions.UPDATE
                }
            });
        },
        []
    );

    const onDeleteClick = useCallback(
        selectedId => () => {
            nfcTagsStore.deleteNfcTag(selectedId);
        },
        []
    );

    const getNfcTagsHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "tagId", label: "Tag id", sorted: true },
        { id: "tagName", label: "Tag name", sorted: true },
        { id: "roomId", label: "Room  id", sorted: true },
        { id: "controls", label: "", sorted: false }
    ];

    const getNfcTagRowContent = useCallback(
        (row: NfcTag) => ({
            rowContent: [
                row.id,
                row.tagId,
                row.tagName,
                row.roomId,
                <>
                    <NfcTagsTableControles
                        canEdit={true}
                        onUpdateClick={onUpdateClick(row.id)}
                        onDeleteClick={onDeleteClick(row.id)}
                    />
                </>
            ]
        }),
        []
    );

    const getNfcTags = useCallback(() => {
        nfcTagsStore.getNfcTags(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getNfcTags();
    }, [getNfcTags]);

    useEffect(() => {
        if (nfcTagsStore.needUpdate) {
            getNfcTags();
        }
    }, [getNfcTags, nfcTagsStore.needUpdate]);

    const nfcTagsRowCells = nfcTagsStore.nfcTags.map(row =>
        getNfcTagRowContent(row)
    );

    return (
        <>
            <BaseTable
                headCells={getNfcTagsHeadCells}
                rowCells={nfcTagsRowCells}
                // TODO add totalCount and pagination
                rowCellsСount={nfcTagsStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
        </>
    );
};

export default observer(NfcTagsTable);
