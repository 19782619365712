import Alert from "@material-ui/lab/Alert";
import { DeviceModal } from "components/organisms/Modals/DeviceModal";
import {
    BaseTable,
    getBaseActionsControls,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Device } from "services/models/DeviceModel";
import { useStore } from "stores";

import DeviceTableControles from "./components/DeviceTableControles";

const DevicesTable: React.FC<any> = props => {
    const { searchString } = props;
    const { devicesStore } = useStore();

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const { isModalOpen, closeModal, action, selectedItemId, onUpdateClick } =
        getBaseActionsControls();

    const [message, setMessage] = useState("");
    const onCloseAlert = useCallback(() => {
        setMessage("");
    }, []);

    const onDeleteClick = useCallback(
        selectedId => () => {
            const result = devicesStore.deleteDevice(selectedId);
            result.then(res => {
                if (!res) {
                    setMessage(
                        "Failed to delete the devise. Most likely it's already in use."
                    );
                }
            });
        },
        []
    );

    const devicesHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "uid", label: "Uid", sorted: true },
        { id: "name", label: "Device name", sorted: true },
        { id: "clientName", label: "Client", sorted: true },
        { id: "clientObjectName", label: "Object", sorted: true },
        { id: "clientObjectAddress", label: "Address", sorted: true },

        { id: "controls", label: "", sorted: false }
    ];

    const getDevicesRowContent = useCallback(
        (row: Device) => ({
            rowContent: [
                row.id,
                row.uid,
                row.name,
                row.clientName,
                row.clientObjectName,
                row.clientObjectAddress,
                <>
                    <DeviceTableControles
                        canEdit={true}
                        onUpdateClick={onUpdateClick(row.id)}
                        onDeleteClick={onDeleteClick(row.id)}
                    />
                </>
            ]
        }),
        []
    );

    const getDevices = useCallback(() => {
        devicesStore.getDevices(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getDevices();
    }, [getDevices]);

    useEffect(() => {
        if (devicesStore.needUpdate) {
            getDevices();
        }
    }, [getDevices, devicesStore.needUpdate]);

    const devicesRowCells = devicesStore.devices.map(row =>
        getDevicesRowContent(row)
    );

    return (
        <>
            {message && (
                <Alert
                    variant="outlined"
                    severity="error"
                    icon={false}
                    onClose={onCloseAlert}
                >
                    {message}
                </Alert>
            )}
            <BaseTable
                headCells={devicesHeadCells}
                rowCells={devicesRowCells}
                // TODO add totalCount and pagination
                rowCellsСount={devicesStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
            <DeviceModal
                isModalOpen={isModalOpen}
                handleClose={closeModal}
                selectedDeviceId={selectedItemId}
                action={action}
            />
        </>
    );
};

export default observer(DevicesTable);
