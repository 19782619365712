import { useState } from "react";

type UseBooleanStateReturnType = [boolean, () => void, () => void];

function useBooleanState(initialState = false): UseBooleanStateReturnType {
    const [innerState, setInnerState] = useState(initialState);
    const setTrue = () => setInnerState(true);
    const setFalse = () => setInnerState(false);
    return [innerState, setTrue, setFalse];
}

export default useBooleanState;
