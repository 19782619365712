enum RateType {
    HOURLY,
    MONTLY
}

export const getRateTypeLabel = (action: RateType) => {
    switch (action) {
        case RateType.HOURLY:
            return "Hourly";
        case RateType.MONTLY:
            return "Montly";
    }
};

export const rateTypeMenuItems = [
    { value: RateType.HOURLY, label: "Hourly" },
    { value: RateType.MONTLY, label: "Montly" }
];

export default RateType;
