export const ACCESS_TOKEN = "access_token";
export const ROWS_PER_PAGE = "rowsPerPage";
export const DROWER_MENU__IS_OPEN = "drawerMenuIsOpen";

export function getAccessToken() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    return token;
}

export function setAccessToken(token: string) {
    localStorage.setItem(ACCESS_TOKEN, token);
}

export function removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
}

export function getRowsPerPageLocal() {
    const rows = localStorage.getItem(ROWS_PER_PAGE);
    return Number(rows);
}

export function setRowsPerPageLocal(rows: number) {
    localStorage.setItem(ROWS_PER_PAGE, rows.toString());
}

export function getDrawerMenuIsOpenLocal() {
    const isOpen = localStorage.getItem(DROWER_MENU__IS_OPEN);
    if (isOpen === "false") {
        return false;
    }
    return true;
}

export function setDrawerMenuIsOpenLocal(state: boolean) {
    localStorage.setItem(DROWER_MENU__IS_OPEN, state.toString());
}
