import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import RateType from "services/enums/RateType";
import WorkStatus from "services/enums/WorkStatus";
import * as yup from "yup";

export function getEmployeeProfileControls(selectedEmployee, onSubmit) {
    const validators = yup.object().shape({
        name: yup.string().trim().required("Field is required").nullable(),
        email: yup.string().trim().required("Field is required").nullable(),
        contactPhone: yup
            .string()
            .trim()
            .required("Field is required")
            .nullable(),
        position: yup.string().trim().required("Field is required").nullable(),
        dateOfBirtdayUTC: yup
            .string()
            .trim()
            .required("Field is required")
            .nullable(),
        state: yup
            .mixed()
            .oneOf(Object.values(WorkStatus))
            .required("Field is required"),
        paymentRate: yup.number().required("Field is required").nullable(),
        rateType: yup
            .mixed()
            .oneOf(Object.values(RateType))
            .required("Field is required")
    });

    const { register, control, handleSubmit, setValue } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: {
            id: selectedEmployee.id,
            name: selectedEmployee.name,
            email: selectedEmployee.email,
            contactPhone: selectedEmployee.contactPhone,
            position: selectedEmployee.position,
            dateOfBirtdayUTC: DateTime.fromISO(
                selectedEmployee.dateOfBirtdayUTC
            ).toISODate(),
            state: selectedEmployee.state,
            paymentRate: selectedEmployee.paymentRate,
            rateType: selectedEmployee.rateType,
            file: null
        }
    });

    const onFormSubmit = handleSubmit(data => {
        onSubmit(data);
    });

    return {
        register,
        control,
        onFormSubmit,
        setValue
    };
}
