import { $axios } from "services/http";

export const login = (data: any) => {
    return $axios.post(`/api/Auth/login`, data);
};

export const logout = () => {
    return $axios.post(`/api/Auth/logout`);
};

export const check = () => {
    return $axios.get(`/api/Auth/manager/my`);
};

export const getPhoto = (id: number) => {
    return $axios.get(`/api/Managers/${id}/photo`, {
        responseType: "blob"
    });
};
