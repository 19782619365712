import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { ClientObject } from "services/models/ClientObjectModel";

import * as Api from "./api";
class ClientObjectsStore {
    totalCount?: number;
    clientObjects: ClientObject[] = [];
    needUpdate = false;

    async getClientObjects(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            const { data } = await Api.getClientObjects(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );

            this.setClientObjects(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {}
    }

    async addClientObject(clientObjects: any) {
        try {
            const { data } = await Api.setClientObject(clientObjects);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    async updateClientObject(id: number, clientObjects: any) {
        try {
            const { data } = await Api.updateClientObject(id, clientObjects);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    async deleteClientObject(id: number) {
        try {
            const { data } = await Api.deleteClientObject(id);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    private setClientObjects(clientObjects: ClientObject[]) {
        this.clientObjects = clientObjects.map(i => new ClientObject(i));
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default ClientObjectsStore;
