import { Box, IconButton, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { BaseTable } from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Audit } from "services/models/AuditModel";
import { useStore } from "stores";

import AuditCollapseContent from "./components/AuditCollapseContent";

const AuditTable: React.FC<any> = props => {
    const { auditStore } = useStore();

    const { selectedEmployeeId, selectedDate } = props;

    const getAudit = useCallback(() => {
        if (selectedDate) {
            auditStore.getAudit(selectedEmployeeId, selectedDate);
        }
    }, [selectedEmployeeId, selectedDate]);

    useEffect(() => {
        getAudit();
    }, [getAudit]);

    const auditHeadCells = [
        { id: "id", label: "Id", sorted: false },
        { id: "clientName", label: "Client", sorted: false },
        { id: "clientObjectName", label: "Object", sorted: false },
        {
            id: "clientObjectAddress",
            label: "Address",
            sorted: false
        },
        { id: "managerName", label: "Manager", sorted: false },
        { id: "startTime", label: "Start time", sorted: false },
        { id: "endTime", label: "End time", sorted: false },
        { id: "difference", label: "Difference", sorted: false },
        { id: "controls", label: "", sorted: false }
    ];

    const getAuditRowContent = useCallback(
        (row: Audit, collapseOpen: boolean, onCollapseClick: any) => ({
            rowContent: [
                row.orderViewModel?.id,
                row.orderViewModel?.clientName,
                row.orderViewModel?.clientObjectName,
                row.orderViewModel?.clientObjectAddress,
                row.orderViewModel?.managerName,
                row.orderDuration?.startTime,
                row.orderDuration?.endTime,
                row.orderDuration?.difference,
                <>
                    <IconButton
                        size="small"
                        onClick={onCollapseClick(row.orderViewModel?.id)}
                    >
                        {collapseOpen ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </>
            ],
            collapse: (
                <>
                    {collapseOpen && (
                        <AuditCollapseContent
                            work={row.work}
                            nfc={row.nfc}
                            maps={{
                                startLatitude:
                                    row.orderViewModel?.startLatitude,
                                startLongitude:
                                    row.orderViewModel?.startLongitude,
                                finishLatitude:
                                    row.orderViewModel?.finishLatitude,
                                finishLongitude:
                                    row.orderViewModel?.finishLongitude
                            }}
                        />
                    )}
                </>
            )
        }),
        []
    );

    const [openCollapseId, setOpendCollapseId] = useState<number[]>([]);

    const onCollapseClickCallback = useCallback(
        orderId => () => {
            if (openCollapseId.includes(orderId)) {
                setOpendCollapseId(prev => prev.filter(f => f !== orderId));
            } else {
                setOpendCollapseId(prev => [...prev, orderId]);
            }
        },
        [openCollapseId, setOpendCollapseId]
    );

    const auditRowCells = auditStore.orderAuditViewModels.map(row =>
        getAuditRowContent(
            row,
            openCollapseId.includes(row.orderViewModel?.id),
            onCollapseClickCallback
        )
    );

    const rowCellsСount = auditStore.orderAuditViewModels.length;

    return (
        <>
            <Box p={1}>
                <Typography variant="body1">{`Day working hours: ${auditStore.dayTimeWorkingHours}`}</Typography>
                <Typography variant="body1">{`Month working hours: ${auditStore.monthTimeWorkingHours}`}</Typography>
            </Box>
            {selectedDate ? (
                <>
                    {!!rowCellsСount && (
                        <BaseTable
                            headCells={auditHeadCells}
                            rowCells={auditRowCells}
                            rowCellsСount={rowCellsСount}
                            hidePagination
                        />
                    )}
                </>
            ) : (
                <Box px={1}>{"Select date please"}</Box>
            )}
        </>
    );
};

export default observer(AuditTable);
