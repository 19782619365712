import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { NfcTagsContent } from "./Components/NfcTagsContent";
import { NfcTagsHeader } from "./Components/NfcTagsHeader";

const NfcTags: React.FC<any> = props => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<NfcTagsHeader />}
                contentComponent={<NfcTagsContent />}
            />
        </HeaderWrapper>
    );
};

export default NfcTags;
