import {
    TrainingsForm,
    TrainingsFormProps
} from "components/organisms/Forms/TrainingsForm";
import React from "react";

import { BaseModal, BaseModalCallProps, BaseModalProps } from "../BaseModal";

const TrainingsModal: React.FC<
    TrainingsFormProps & BaseModalProps & BaseModalCallProps
> = props => {
    const { isModalOpen, handleClose, action, selectedTrainingId } = props;
    return (
        <BaseModal isModalOpen={isModalOpen} handleClose={handleClose}>
            <TrainingsForm
                onSubmit={handleClose}
                action={action}
                selectedTrainingId={selectedTrainingId}
            />
        </BaseModal>
    );
};
export default TrainingsModal;
