import { Box, Grid } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { FeedbacksTable } from "components/organisms/Tables/FeedbacksTable";
import React, { useState } from "react";

const FeedBackTab: React.FC = props => {
    const [searchValue, setSearchValue] = useState("");
    return (
        <>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </Grid>
            </Box>
            <FeedbacksTable searchString={searchValue} />
        </>
    );
};

export default FeedBackTab;
