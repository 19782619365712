import Link from "@material-ui/core/Link";
import { TrainingsModal } from "components/organisms/Modals/TrainingsModal";
import {
    BaseTable,
    getBaseActionsControls,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import SistemRole from "services/enums/SistemRole";
import { Training } from "services/models/TrainingsModels";
import { useStore } from "stores";

import TrainingsTableControles from "./components/TrainingsTableControles";

const TrainingsTable: React.FC<any> = props => {
    const { searchString } = props;
    const { trainingsStore, loginStore } = useStore();
    const canAdd = loginStore.user?.role === SistemRole.ADMIN;

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const {
        isModalOpen,
        closeModal,
        action,
        selectedItemId,
        onViewClick,
        onUpdateClick
    } = getBaseActionsControls();

    const onDeleteClick = useCallback(
        selectedId => () => {
            trainingsStore.deleteTraining(selectedId);
        },
        []
    );

    const trainingsHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "name", label: "Name", sorted: true },
        { id: "description", label: "Description", sorted: true },
        { id: "hashtags", label: "Hashtags", sorted: true },
        { id: "author", label: "Author", sorted: true },
        { id: "url", label: "Link", sorted: true },
        { id: "controls", label: "", sorted: false }
    ];

    const getTrainingsRowContent = useCallback(
        (row: Training) => ({
            rowContent: [
                row.id,
                row.name,
                row.description,
                row.hashtags,
                row.author,
                <>
                    <Link href={row.url} color="inherit" target="_blank">
                        {row.url}
                    </Link>
                </>,
                <>
                    <TrainingsTableControles
                        canEdit={canAdd}
                        onViewClick={onViewClick(row.id)}
                        onDeleteClick={onDeleteClick(row.id)}
                        onUpdateClick={onUpdateClick(row.id)}
                    />
                </>
            ]
        }),
        []
    );

    const getTrainings = useCallback(() => {
        trainingsStore.getTrainings(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getTrainings();
    }, [getTrainings]);

    useEffect(() => {
        if (trainingsStore.needUpdate) {
            getTrainings();
        }
    }, [getTrainings, trainingsStore.needUpdate]);

    const trainingsRowCells = trainingsStore.trainings.map(row =>
        getTrainingsRowContent(row)
    );

    return (
        <>
            <BaseTable
                headCells={trainingsHeadCells}
                rowCells={trainingsRowCells}
                // TODO add totalCount and pagination
                rowCellsСount={trainingsStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
            <TrainingsModal
                isModalOpen={isModalOpen}
                handleClose={closeModal}
                selectedTrainingId={selectedItemId}
                action={action}
            />
        </>
    );
};

export default observer(TrainingsTable);
