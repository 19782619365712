import {
    TableCell,
    TableHead as MuiTableHead,
    TableRow,
    TableSortLabel
} from "@material-ui/core";
import { useCallback } from "react";
import SortDirection from "services/enums/SortDirection";

import { TableHeadProps } from "./interfaces";
import useStyles from "./styles";

const TableHead: React.FC<TableHeadProps> = props => {
    const { order, sortBy, onRequestSort, headCells } = props;

    const handleRequestSort = useCallback(
        property => () => onRequestSort(property),
        [onRequestSort]
    );

    const classes = useStyles();

    return (
        <MuiTableHead>
            <TableRow classes={{ root: classes.tableRow }}>
                {headCells.map(headCell => {
                    return headCell.sorted ? (
                        <TableCell key={headCell.id}>
                            <TableSortLabel
                                active={sortBy === headCell.id}
                                direction={
                                    sortBy === headCell.id
                                        ? order
                                        : SortDirection.ASC
                                }
                                onClick={handleRequestSort(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell key={headCell.id}>
                            {headCell.label}
                        </TableCell>
                    );
                })}
            </TableRow>
        </MuiTableHead>
    );
};

export default TableHead;
