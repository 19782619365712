import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: "12px",
            lineHeight: "16px",
            textTransform: "unset",
            letterSpacing: "0.0125em",
            color: "#3F7DFE",
            background: "#E6EEFF",
            borderRadius: "4px",
            "&$disabled": {
                color: "#3F7DFE",
                background: "#E6EEFF"
            }
        },
        disabled: {},
        iconSizeMedium: {
            "& > *:first-child": {
                fontSize: "16px"
            }
        }
    })
);
