import { BaseTable } from "components/organisms/Tables/BaseTable";
import { useCallback } from "react";
import { NfcWorkTracker } from "services/models/NfcWorkTracker";

const NfcWorkTrackerTable: React.FC<any> = props => {
    const { nfcWorkTrackerArray } = props;

    const workHeadCells = [
        { id: "tagId", label: "Tag id", sorted: false },
        { id: "tagName", label: "Tag name", sorted: false },
        { id: "startTime", label: "Start time", sorted: false },
        { id: "endTime", label: "End time", sorted: false },
        { id: "difference", label: "Period", sorted: false }
    ];

    const getWorkContent = useCallback(
        (row: NfcWorkTracker) => ({
            rowContent: [
                row.nfcTagViewModel?.tagId,
                row.nfcTagViewModel?.tagName,
                row.startTime,
                row.endTime,
                row.difference
            ]
        }),
        []
    );

    const workRowCells = nfcWorkTrackerArray.map(row => getWorkContent(row));

    const rowCellsСount = nfcWorkTrackerArray.length;

    return (
        <>
            {!!rowCellsСount && (
                <>
                    <BaseTable
                        headCells={workHeadCells}
                        rowCells={workRowCells}
                        rowCellsСount={rowCellsСount}
                        hidePagination
                    />
                </>
            )}
        </>
    );
};

export default NfcWorkTrackerTable;
