import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "stores";

import { getLoginFormControls } from "./LoginForm.utils";

const LoginForm: React.FC = () => {
    const { loginStore } = useStore();
    const { control, onFormSubmit } = getLoginFormControls();

    return (
        <form onSubmit={onFormSubmit}>
            {loginStore.errorMessage && (
                <Alert
                    variant="outlined"
                    severity="error"
                    icon={false}
                    onClose={() => {
                        loginStore.removeErrorMessage();
                    }}
                >
                    {loginStore.errorMessage}
                </Alert>
            )}
            <TextFieldController
                control={control}
                name="login"
                variant="outlined"
                margin="normal"
                fullWidth
                id="login"
                label="Login"
            />
            <TextFieldController
                control={control}
                name="password"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Password"
                type="password"
                id="password"
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
                {"Sign In"}
            </Button>
        </form>
    );
};
export default observer(LoginForm);
