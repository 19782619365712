import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        overlayWrapper: {
            position: "relative"
        },
        overlay: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            background: "rgba(0, 0, 0, .6)",
            cursor: "pointer",
            "&:hover": {
                opacity: 1
            }
        },
        icon: {
            color: "#fff",
            fontSize: 40
        },
        personIcon: {
            color: "#fff",
            fontSize: 60
        }
    })
);
