class Device {
    public id: number;
    public uid: string;
    public password: string;
    public name: string;
    public timeoutBeforeAlertMin: number;
    public timeoutAfterAlertMin: number;
    public freeTimeoutsAmountMin: number;
    public clientId: number;
    public clientName: string;
    public clientObjectId: number;
    public clientObjectName: string;
    public clientObjectAddress: string;
    public type: any;

    constructor(model?) {
        if (model) {
            this.id = model.id;
            this.uid = model.uid;
            this.password = model.password;
            this.name = model.name;
            this.timeoutBeforeAlertMin = model.timeoutBeforeAlertMin;
            this.timeoutAfterAlertMin = model.timeoutAfterAlertMin;
            this.freeTimeoutsAmountMin = model.freeTimeoutsAmountMin;
            this.clientId = model.clientId;
            this.clientName = model.clientName;
            this.clientObjectId = model.clientObjectId;
            this.clientObjectName = model.clientObjectName;
            this.clientObjectAddress = model.clientObjectAddress;
            this.type = model.type;
        } else {
            this.id = 0;
            this.uid = "";
            this.password = "";
            this.name = "";
            this.timeoutBeforeAlertMin = 0;
            this.timeoutAfterAlertMin = 0;
            this.freeTimeoutsAmountMin = 0;
            this.clientId = 0;
            this.clientName = "";
            this.clientObjectId = 0;
            this.clientObjectName = "";
            this.clientObjectAddress = "";
            this.type = 0;
        }
    }
}

export default Device;
