import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getFeedbacks = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    searchString?: string
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/Feedbacks${params}`);
};

export const getFeedbacksById = (id: number) => {
    return $axios.get(`/api/Feedbacks/${id}`);
};

export const setFeedbacks = (data: any) => {
    return $axios.post(`/api/Feedbacks`, data);
};

export const updateFeedbacks = (id: number, data: any) => {
    return $axios.put(`/api/Feedbacks/${id}`, data);
};

export const deleteFeedbacks = (id: number) => {
    return $axios.delete(`/api/Feedbacks/${id}`);
};
