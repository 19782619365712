import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-hook-form";
import Actions, { getActionLabel } from "services/enums/Actions";
import { Tool } from "services/models/ToolModel";
import { useStore } from "stores";
import * as yup from "yup";

import { ToolsFormCallProps, ToolsFormProps } from "./interfaces";

const ToolsForm: React.FC<ToolsFormProps & ToolsFormCallProps> = props => {
    const { onSubmit, selectedToolId, action } = props;
    const disabled = action === Actions.VIEW;
    const { toolsStore } = useStore();

    const validators = yup.object().shape({
        name: yup.string().trim().required("Field is required").nullable(),
        description: yup
            .string()
            .trim()
            .required("Field is required")
            .nullable(),
        use: yup.string().trim().required("Field is required").nullable()
    });

    const defaultTool =
        action === Actions.VIEW || action === Actions.UPDATE
            ? new Tool(
                  toJS(toolsStore.tools.find(i => i.id === selectedToolId))
              )
            : new Tool();

    const { control, handleSubmit } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: defaultTool
    });

    const onFormSubmit = handleSubmit(data => {
        if (action === Actions.UPDATE) {
            toolsStore.updateTool(Number(selectedToolId), data);
        }
        if (action === Actions.CREATE) {
            toolsStore.addTools(data);
        }
        onSubmit();
    });

    return (
        <form onSubmit={onFormSubmit}>
            <TextFieldController
                control={control}
                disabled={disabled}
                name="name"
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Name"
            />
            <TextFieldController
                control={control}
                disabled={disabled}
                name="description"
                variant="outlined"
                margin="normal"
                fullWidth
                id="description"
                label="Description"
            />
            <TextFieldController
                control={control}
                disabled={disabled}
                name="use"
                variant="outlined"
                margin="normal"
                fullWidth
                id="use"
                label="Purpose"
            />
            {!disabled && (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {getActionLabel(Actions.CREATE)}
                </Button>
            )}
        </form>
    );
};
export default observer(ToolsForm);
