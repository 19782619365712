import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getTools = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    searchString?: string
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/Tools${params}`);
};

export const getToolsById = (id: number) => {
    return $axios.get(`/api/Tools/${id}`);
};

export const setTools = (data: any) => {
    return $axios.post(`/api/Tools`, data);
};

export const updateTool = (id: number, data: any) => {
    return $axios.put(`/api/Tools/${id}`, data);
};

export const deleteTool = (id: number) => {
    return $axios.delete(`/api/Tools/${id}`);
};
