import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { NfcHistory } from "services/models/NfcHistoryModel";

import * as Api from "./api";

class NfcHistoryStore {
    totalCount = 0;
    nfcTrackHistory: NfcHistory[] = [];
    needUpdate = false;

    async getNfcTrackHistory(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        userId?: number
    ) {
        try {
            const { data } = await Api.getNfcTrackHistory(
                skip,
                take,
                sortBy,
                direction,
                userId
            );

            this.setNfcTrackHistory(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {}
    }

    private setNfcTrackHistory(nfcTrackHistory: NfcHistory[]) {
        this.nfcTrackHistory = nfcTrackHistory.map(i => new NfcHistory(i));
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default NfcHistoryStore;
