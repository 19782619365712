import { Box, Button, Grid } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { AdminsTable } from "components/organisms/Tables/AdminsTable";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router";
import SistemRole from "services/enums/SistemRole";
import { useStore } from "stores";

const AdminsTab: React.FC = props => {
    const { loginStore } = useStore();

    const canAdd = loginStore.user?.role === SistemRole.ADMIN;

    const history = useHistory();
    const addAdmin = () => {
        history.push(`/employees/newAdmin`);
    };

    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <Box p={2}>
                <Grid container justify="space-between" alignItems="center">
                    <SearchField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    {canAdd && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addAdmin}
                        >
                            {"Add admins"}
                        </Button>
                    )}
                </Grid>
            </Box>
            <AdminsTable searchString={searchValue} />
        </>
    );
};

export default observer(AdminsTab);
