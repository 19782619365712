import { IconButton, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import { BaseModalProps, BaseModalCallProps } from "./interfaces";
import useStyles from "./styles";

const BaseModal: React.FC<BaseModalProps & BaseModalCallProps> = props => {
    const { isModalOpen, handleClose, children } = props;

    const classes = useStyles();

    return (
        <Modal
            open={isModalOpen}
            onClose={handleClose}
            className={classes.userProfile}
        >
            <div className={classes.content}>
                <div className={classes.closeButton}>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.form}>{children}</div>
            </div>
        </Modal>
    );
};
export default BaseModal;
