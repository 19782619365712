import {
    ToolsForm,
    ToolsFormProps
} from "components/organisms/Forms/ToolsForm";
import React from "react";

import { BaseModal, BaseModalCallProps, BaseModalProps } from "../BaseModal";

const ToolsModal: React.FC<
    ToolsFormProps & BaseModalProps & BaseModalCallProps
> = props => {
    const { isModalOpen, handleClose, action, selectedToolId } = props;
    return (
        <BaseModal isModalOpen={isModalOpen} handleClose={handleClose}>
            <ToolsForm
                onSubmit={handleClose}
                action={action}
                selectedToolId={selectedToolId}
            />
        </BaseModal>
    );
};
export default ToolsModal;
