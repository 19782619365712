import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import RateType from "services/enums/RateType";
import WorkStatus from "services/enums/WorkStatus";
import { User } from "services/models/UserModels";
import { generatePassword } from "services/utils/GeneratePassword";
import { useStore } from "stores";
import * as yup from "yup";

export function getNewEmployeeControls(onSubmit) {
    const newEmployee = new User();

    const validators = yup.object().shape({
        name: yup.string().trim().required("Field is required").nullable(),
        email: yup
            .string()
            .trim()
            .email()
            .required("Field is required")
            .nullable(),
        contactPhone: yup
            .string()
            .trim()
            .required("Field is required")
            .nullable(),
        position: yup.string().trim().required("Field is required").nullable(),
        dateOfBirtdayUTC: yup
            .string()
            .trim()
            .required("Field is required")
            .nullable(),
        state: yup
            .mixed()
            .oneOf(Object.values(WorkStatus))
            .required("Field is required"),
        password: yup.string().required("Field is required").nullable(),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match"),
        paymentRate: yup.number().required("Field is required").nullable(),
        rateType: yup
            .mixed()
            .oneOf(Object.values(RateType))
            .required("Field is required")
    });

    const { control, handleSubmit, setValue } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: {
            id: newEmployee.id,
            name: newEmployee.name,
            email: newEmployee.email,
            contactPhone: newEmployee.contactPhone,
            position: newEmployee.position,
            dateOfBirtdayUTC: newEmployee.dateOfBirtdayUTC,
            state: newEmployee.state,
            paymentRate: newEmployee.paymentRate,
            rateType: newEmployee.rateType,
            password: "",
            passwordConfirmation: ""
        }
    });

    const generateNewPassword = useCallback(() => {
        const random = generatePassword(8);
        setValue("password", random);
        setValue("passwordConfirmation", random);
    }, []);

    const onFormSubmit = handleSubmit((data: any) => {
        const { passwordConfirmation, ...restData } = data;
        onSubmit(restData);
    });

    return {
        control,
        onFormSubmit,
        generateNewPassword
    };
}
