import { Tool } from "../ToolModel";

class CleaningType {
    public id = 0;
    public name = "";
    public tools: Tool[] = [];

    constructor(model?: CleaningType) {
        Object.assign(this, model);
    }
}

export default CleaningType;
