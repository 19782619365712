import axios from "axios";
import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";
import { getAccessToken } from "services/utils/LocalStorageHelper";

export const getEmployees = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    searchString?: string
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/Employees${params}`);
};

export const getEmployee = (id: number) => {
    return $axios.get(`/api/Employees/${id}`);
};

export const setEmployee = (data: any) => {
    return $axios.post(`/api/Employees/SignUp`, data);
};

export const updateEmployee = (id: number, data: any) => {
    return $axios.put(`/api/Employees/${id}`, data);
};

export const deleteEmployee = (id: number) => {
    return $axios.delete(`/api/Employees/${id}`);
};

export const updatePassword = (data: any) => {
    return $axios.post(`/api/Employees/ChangePassword`, data);
};

export const getPhoto = (id: number) => {
    return $axios.get(`/api/Employees/${id}/photo`, {
        responseType: "blob"
    });
};

export const setPhoto = (id: number, data: any) => {
    return $axios.post(`/api/Employees/${id}/photo`, data);
};

export const deletePhoto = (id: number) => {
    return $axios.delete(`/api/Clients/${id}/photo`);
};

export const getPersonalDocument = (id: number) => {
    return $axios.get(`/api/Employees/${id}/personalDocument`);
};

export const setPersonalDocument = (id: number, data: any) => {
    return $axios.post(`/api/Employees/${id}/personalDocument`, data);
};

export const deletePersonalDocument = (id: number) => {
    return $axios.delete(`/api/Employees/${id}/personalDocument`);
};

export const getAgreement = (id: number) => {
    return $axios.get(`/api/Employees/${id}/agreement`);
};

export const setAgreement = (id: number, data: any) => {
    return $axios.post(`/api/Employees/${id}/agreement`, data);
};

export const deleteAgreement = (id: number) => {
    return $axios.delete(`/api/Employees/${id}/agreement`);
};
