import { useCallback } from "react";
import { useStore } from "stores";

import { uploadFile } from "../FileLoading.utils";

export function onUploadCallback(selectedEmployeeId, setMessage, setLoaded) {
    const { employeesStore } = useStore();
    const handleUpload = useCallback(
        async files => {
            const fileData = new FormData();
            fileData.append("file", files[0]);
            const result = await employeesStore.setAgreement(
                selectedEmployeeId,
                fileData
            );
            if (result) {
                setMessage("Agreement uploaded successfully.");
                setLoaded(false);
            } else {
                setMessage("There was a problem with uploading the file.");
            }
        },
        [selectedEmployeeId]
    );
    return useCallback(() => {
        uploadFile({ onUpload: handleUpload });
    }, [employeesStore, handleUpload]);
}
