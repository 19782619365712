import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { ClientObjectAlert } from "services/models/ClientObjectAlertModels";

import * as Api from "./api";

class ClientObjectAlertsStore {
    totalCount?: number;
    clientObjectAlerts: ClientObjectAlert[] = [];
    needUpdate = false;

    async getClientObjectAlerts(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        selectedAlertTypeIds?: number[],
        searchString?: string,
        selectedDate?: any
    ) {
        try {
            const { data } = await Api.getClientObjectAlerts(
                skip,
                take,
                sortBy,
                direction,
                selectedAlertTypeIds,
                searchString,
                selectedDate
            );

            this.setClientObjectAlerts(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {}
    }

    async updateRequest() {
        try {
            this.setUpdateRequest(true);
        } catch (error: any) {}
    }

    private setClientObjectAlerts(clientObjectAlerts: ClientObjectAlert[]) {
        this.clientObjectAlerts = clientObjectAlerts.map(
            i => new ClientObjectAlert(i)
        );
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default ClientObjectAlertsStore;
