import { IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
    BaseTable,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { OrderReport } from "services/models/OrderReportModel";
import { useStore } from "stores";

import { ReportCollapseContent } from "./components/ReportCollapseContent";
const OrderReportsTable: React.FC<any> = props => {
    const { searchString } = props;
    const { orderReportsStore } = useStore();

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const orderReportsHeadCells = [
        { id: "orderId", label: "Order Id", sorted: true },
        { id: "employeeName", label: "Employee", sorted: true },
        { id: "clientName", label: "Client", sorted: true },
        { id: "clientObjectName", label: "Object", sorted: true },
        {
            id: "clientObjectAddress",
            label: "Address",
            sorted: true
        },
        { id: "startDate", label: "Start date", sorted: true },
        { id: "endDate", label: "End date", sorted: true },
        { id: "controls", label: "", sorted: false }
    ];

    const getOrderReportsRowContent = useCallback(
        (row: OrderReport, collapseOpen: boolean, onCollapseClick: any) => {
            return {
                rowContent: [
                    row.orderId,
                    row.employeeName,
                    row.clientName,
                    row.clientObjectName,
                    row.clientObjectAddress,
                    row.startDate
                        ? row.startDate.replace("T", " ").slice(0, 16)
                        : "",
                    row.endDate
                        ? row.endDate.replace("T", " ").slice(0, 16)
                        : "",
                    <>
                        <IconButton
                            size="small"
                            onClick={onCollapseClick(row.orderId)}
                        >
                            {collapseOpen ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </>
                ],
                collapse: (
                    <>{collapseOpen && <ReportCollapseContent {...row} />}</>
                )
            };
        },
        []
    );

    const getOrderReports = useCallback(() => {
        orderReportsStore.getOrderReports(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getOrderReports();
    }, [getOrderReports]);

    useEffect(() => {
        if (orderReportsStore.needUpdate) {
            getOrderReports();
        }
    }, [getOrderReports, orderReportsStore.needUpdate]);

    const [openCollapseId, setOpendCollapseId] = useState<number[]>([]);

    const onCollapseClickCallback = useCallback(
        orderId => () => {
            if (openCollapseId.includes(orderId)) {
                setOpendCollapseId(prev => prev.filter(f => f !== orderId));
            } else {
                setOpendCollapseId(prev => [...prev, orderId]);
            }
        },
        [openCollapseId, setOpendCollapseId]
    );

    const orderReportsRowCells = orderReportsStore.orderReports.map(row =>
        getOrderReportsRowContent(
            row,
            openCollapseId.includes(row.orderId),
            onCollapseClickCallback
        )
    );

    return (
        <>
            <BaseTable
                headCells={orderReportsHeadCells}
                rowCells={orderReportsRowCells}
                rowCellsСount={orderReportsStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
        </>
    );
};

export default observer(OrderReportsTable);
