import { Box, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

import { AdminsTab } from "../AdminsTab";
import { ManagersTab } from "../ManagersTab";
import { СleanersTab } from "../СleanersTab";

const EmployeesContent: React.FC = props => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);

    return (
        <Paper elevation={3}>
            <Box pt={2} px={2}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Tabs
                        value={selectedTabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Сleaners" />
                        <Tab label="Managers" />
                        <Tab label="Admins" />
                    </Tabs>
                </Grid>
            </Box>
            <TabPanel value={selectedTabIndex} index={0}>
                <СleanersTab />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1}>
                <ManagersTab />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={2}>
                <AdminsTab />
            </TabPanel>
        </Paper>
    );
};

export default observer(EmployeesContent);
