import { Chip, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ConfirmActionDialogWrapper } from "components/molecules/ConfirmActionDialogWrapper";
import {
    BaseTable,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import Actions from "services/enums/Actions";
import SistemRole from "services/enums/SistemRole";
import { User } from "services/models/UserModels";
import { useStore } from "stores";

import EmployeesAvatar from "./component/EmployeesAvatar";
import { EmployeesTableProps } from "./interfaces";

const EmployeesTable: React.FC<EmployeesTableProps> = props => {
    const { searchString } = props;
    const { employeesStore, loginStore } = useStore();

    const canAdd = loginStore.user?.role === SistemRole.ADMIN;

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const history = useHistory();

    const onViewClick = id => () => {
        history.push(`/employee/${id}`);
    };

    const onUpdateClick = id => () => {
        history.push({
            pathname: `/employee/${id}`,
            state: {
                action: Actions.UPDATE
            }
        });
    };

    const onDeleteClick = useCallback(
        selectedId => () => {
            employeesStore.deleteEmployee(selectedId);
        },
        []
    );

    const onCloseAlert = useCallback(() => {
        employeesStore.removeErrorMessage();
    }, []);

    const getEmployees = useCallback(() => {
        employeesStore.getEmployees(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    const employeesHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "name", label: "Name", sorted: true },
        { id: "email", label: "Email", sorted: true },
        { id: "contactPhone", label: "Phone №", sorted: true },
        { id: "position", label: "Position", sorted: true },
        { id: "dateOfBirtdayUTC", label: "Birthday", sorted: true },
        { id: "controls", label: "", sorted: false }
    ];

    const getEmployeesRowContent = useCallback(
        (row: User) => ({
            rowContent: [
                row.id,
                <Grid
                    key={row.id}
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid item>
                        <EmployeesAvatar id={row.id} />
                    </Grid>
                    <Grid item>{row.name}</Grid>
                </Grid>,
                row.email,
                row.contactPhone,
                row.position,
                DateTime.fromISO(row.dateOfBirtdayUTC).toISODate(),
                <>
                    <Chip
                        style={{ margin: "2px" }}
                        size="small"
                        label="view"
                        onClick={onViewClick(row.id)}
                    />
                    {canAdd && (
                        <>
                            <Chip
                                style={{ margin: "2px" }}
                                size="small"
                                label="edit"
                                onClick={onUpdateClick(row.id)}
                            />
                            <ConfirmActionDialogWrapper
                                onConfirmClick={onDeleteClick(row.id)}
                            >
                                {openConfirmActionDialog => (
                                    <Chip
                                        style={{ margin: "2px" }}
                                        size="small"
                                        label="delete"
                                        onClick={openConfirmActionDialog}
                                    />
                                )}
                            </ConfirmActionDialogWrapper>
                        </>
                    )}
                </>
            ]
        }),
        []
    );

    const employeesRowCells = employeesStore.employees.map(row =>
        getEmployeesRowContent(row)
    );

    useEffect(() => {
        getEmployees();
    }, [getEmployees]);

    useEffect(() => {
        if (employeesStore.needUpdate) {
            getEmployees();
        }
    }, [getEmployees, employeesStore.needUpdate]);

    return (
        <>
            {employeesStore.errorMessage && (
                <Alert
                    variant="outlined"
                    severity="error"
                    icon={false}
                    onClose={onCloseAlert}
                >
                    {employeesStore.errorMessage}
                </Alert>
            )}
            <BaseTable
                headCells={employeesHeadCells}
                rowCells={employeesRowCells}
                rowCellsСount={employeesStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
        </>
    );
};

export default observer(EmployeesTable);
