import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { EmployeeProfileForm } from "components/organisms/Forms/EmployeeProfileForm";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import Actions, { getActionLabel } from "services/enums/Actions";
import { useStore } from "stores";

const ProfileTab: React.FC<any> = props => {
    const { selectedUser } = props;
    const { managersStore, loginStore } = useStore();

    const [alert, setAlert] = useState<any>({
        severity: "error",
        message: ""
    });
    const onCloseAlert = useCallback(() => {
        setAlert({
            severity: "error",
            message: ""
        });
    }, []);

    const onSubmit = useCallback(data => {
        const { file, ...restData } = data;
        const result = managersStore.updateManager(data.id, restData);
        result
            .then(res => {
                if (res) {
                    if (file) {
                        const setAvatarResult = managersStore.setPhoto(
                            data.id,
                            file
                        );
                        setAvatarResult.then(res => {
                            if (res) {
                                setAlert({
                                    severity: "success",
                                    message: "Data changed."
                                });
                            } else {
                                setAlert({
                                    severity: "error",
                                    message:
                                        "Something went wrong. Avatar doesn't load."
                                });
                            }
                        });
                    } else {
                        setAlert({
                            severity: "success",
                            message: "Data changed."
                        });
                    }
                } else {
                    setAlert({
                        severity: "error",
                        message: "Something went wrong."
                    });
                }
            })
            .finally(() => {
                loginStore.checkAuthorization();
            });
    }, []);

    return (
        <Box p={2}>
            {alert.message && (
                <Alert
                    variant="outlined"
                    severity={alert.severity}
                    icon={false}
                    onClose={onCloseAlert}
                >
                    {alert.message}
                </Alert>
            )}
            {selectedUser ? (
                <EmployeeProfileForm
                    selectedEmployee={selectedUser}
                    actionButtonText={getActionLabel(Actions.UPDATE)}
                    onSubmit={onSubmit}
                />
            ) : (
                "Something went wrong"
            )}
        </Box>
    );
};

export default observer(ProfileTab);
