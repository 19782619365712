import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    Typography
} from "@material-ui/core";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { CleaningTypeForm } from "components/organisms/Forms/CleaningTypeForm";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import Actions, { getActionLabel } from "services/enums/Actions";
import { useStore } from "stores";

const CleaningTypes: React.FC = () => {
    const { cleaningTypesStore } = useStore();

    const { id } = useParams<any>();
    const { state } = useLocation<{ action?: Actions }>();

    const disabled = state?.action !== Actions.UPDATE;

    useEffect(() => {
        cleaningTypesStore.getSelectedCleaningType(id);
    }, [id]);

    const selectedCleaningType = cleaningTypesStore.selectedCleaningType;

    const onSubmit = useCallback((data: any) => {
        cleaningTypesStore.updateCleaningType(data.id, data);
    }, []);

    return (
        <HeaderWrapper>
            {cleaningTypesStore.loaded ? (
                <BasePage
                    headerComponent={
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h5">
                                {"Cleaning type"}
                            </Typography>
                        </Grid>
                    }
                    contentComponent={
                        <Paper elevation={3}>
                            <Box p={2}>
                                {selectedCleaningType ? (
                                    <CleaningTypeForm
                                        cleaningType={selectedCleaningType}
                                        actionButtonText={getActionLabel(
                                            Actions.UPDATE
                                        )}
                                        onSubmit={onSubmit}
                                        disabled={disabled}
                                    />
                                ) : (
                                    "Cleaning type not found"
                                )}
                            </Box>
                        </Paper>
                    }
                />
            ) : (
                <CircularProgress />
            )}
        </HeaderWrapper>
    );
};

export default observer(CleaningTypes);
