import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { NfcTag } from "services/models/NfcTagModel";

import * as Api from "./api";

class NfcTagsStore {
    totalCount?: number;
    nfcTags: NfcTag[] = [];
    selectedNfcTag: NfcTag;
    needUpdate = false;

    async getNfcTags(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            const { data } = await Api.getNfcTags(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );

            this.setNfcTags(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {}
    }

    async getSelectedNfcTag(id: number) {
        try {
            const { data } = await Api.getNfcTagsById(id);
            this.setSelectedNfcTag(data);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    async addNfcTags(tool: any) {
        try {
            const { data } = await Api.setNfcTags(tool);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    async updateNfcTag(nfcTag: NfcTag) {
        try {
            await Api.updateNfcTag(nfcTag);
            this.setUpdateRequest(true);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    async deleteNfcTag(id: number) {
        try {
            await Api.deleteNfcTag(id);
            this.setUpdateRequest(true);
        } catch (error: any) {}
    }

    private setNfcTags(nfcTags: NfcTag[]) {
        this.nfcTags = nfcTags.map(i => new NfcTag(i));
    }

    private setSelectedNfcTag(selectedNfcTag: NfcTag) {
        this.selectedNfcTag = selectedNfcTag;
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default NfcTagsStore;
