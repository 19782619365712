import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "stores";

const NewManagerProfileHeader: React.FC = () => {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{"Add manager"}</Typography>
        </Grid>
    );
};

export default observer(NewManagerProfileHeader);
