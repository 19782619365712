import { Box, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useLocation } from "react-router";
import Actions from "services/enums/Actions";
import {
    EmployeeProfileTabs,
    getEmployeeProfileTabsLabel
} from "services/enums/EmployeeProfileTabs";
import SistemRole from "services/enums/SistemRole";
import { useStore } from "stores";

import { AuditTab } from "../AuditTab";
import { ChangeEmployeePasswordTab } from "../ChangeEmployeePasswordTab";
import { DocumentTab } from "../DocumentTab";
import { NfcHistoryTab } from "../NfcHistoryTab";
import { ProfileTab } from "../ProfileTab";

const EmployeeProfileContent: React.FC<any> = props => {
    const {
        selectedEmployee,
        selectedTabIndex,
        setSelectedTabIndex,
        selectedAuditDate
    } = props;

    const { loginStore } = useStore();
    const canChangePassword = loginStore.user?.role === SistemRole.ADMIN;

    const { state } = useLocation<{ action?: Actions }>();
    const disabled = state?.action !== Actions.UPDATE;

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);

    return (
        <Paper elevation={3}>
            <Box py={2}>
                <Box px={2} pb={2}>
                    <Grid
                        container
                        justify="space-between"
                        alignItems="baseline"
                    >
                        <Tabs
                            value={selectedTabIndex}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab
                                label={getEmployeeProfileTabsLabel(
                                    EmployeeProfileTabs.PROFILE
                                )}
                                value={EmployeeProfileTabs.PROFILE}
                            />
                            <Tab
                                label={getEmployeeProfileTabsLabel(
                                    EmployeeProfileTabs.DOCUMENT
                                )}
                                value={EmployeeProfileTabs.DOCUMENT}
                            />
                            <Tab
                                label={getEmployeeProfileTabsLabel(
                                    EmployeeProfileTabs.NFC_HISTORY
                                )}
                                value={EmployeeProfileTabs.NFC_HISTORY}
                            />
                            <Tab
                                label={getEmployeeProfileTabsLabel(
                                    EmployeeProfileTabs.AUDIT
                                )}
                                value={EmployeeProfileTabs.AUDIT}
                            />
                            {canChangePassword && !disabled && (
                                <Tab
                                    label={getEmployeeProfileTabsLabel(
                                        EmployeeProfileTabs.CHANGE_PASSWORD
                                    )}
                                    value={EmployeeProfileTabs.CHANGE_PASSWORD}
                                />
                            )}
                        </Tabs>
                    </Grid>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <Box px={2}>
                        <ProfileTab
                            selectedEmployee={selectedEmployee}
                            disabled={disabled}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <Box px={2}>
                        <DocumentTab
                            selectedEmployeeId={selectedEmployee?.id}
                            disabled={disabled}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={2}>
                    <Box px={2}>
                        <NfcHistoryTab
                            selectedEmployeeId={selectedEmployee?.id}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={3}>
                    <AuditTab
                        selectedEmployeeId={selectedEmployee?.id}
                        selectedAuditDate={selectedAuditDate}
                    />
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={4}>
                    <Box px={2}>
                        <ChangeEmployeePasswordTab
                            selectedEmployee={selectedEmployee}
                        />
                    </Box>
                </TabPanel>
            </Box>
        </Paper>
    );
};

export default observer(EmployeeProfileContent);
