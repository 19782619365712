import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { User } from "services/models/UserModels";

import * as Api from "./api";

class ClientsStore {
    totalCount?: number;
    clients: User[] = [];
    selectedClient: User;
    needUpdate = false;
    errorMessage?: string;

    async getClients(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            const { data } = await Api.getClients(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );
            this.setClients(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async getSelectedClient(id: number) {
        try {
            const { data } = await Api.getClient(id);
            this.setSelectedClient(data);
            return true;
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        } finally {
            return false;
        }
    }

    async addClient(clients: any) {
        try {
            const { data } = await Api.setClient(clients);
            if (data) {
                this.setUpdateRequest(true);
            }
            return true;
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
            return false;
        }
    }

    async updateClient(id: number, clients: any) {
        try {
            const { data } = await Api.updateClient(id, clients);
            if (data) {
                this.setUpdateRequest(true);
            }
            return true;
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
            return false;
        }
    }

    async updatePassword(newPassword: any) {
        try {
            const { data } = await Api.updatePassword(newPassword);
            if (data) {
                return true;
            } else {
                return false;
            }
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
            return false;
        }
    }

    async deleteClient(id: number) {
        try {
            const { data } = await Api.deleteClient(id);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async getPhoto(id: number) {
        try {
            const response = await Api.getPhoto(id);
            return response.data;
        } catch (error: any) {
            return false;
        }
    }

    async setPhoto(id: number, file: any) {
        try {
            const { data } = await Api.setPhoto(id, file);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    async deletePhoto(id: number) {
        try {
            const { data } = await Api.deletePhoto(id);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    async getAgreement(id: number) {
        try {
            const { data } = await Api.getAgreement(id);
            return data;
        } catch (error: any) {
            return false;
        }
    }

    async setAgreement(id: number, file: any) {
        try {
            const { data } = await Api.setAgreement(id, file);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    async deleteAgreement(id: number) {
        try {
            const { data } = await Api.deleteAgreement(id);
            return true;
        } catch (error: any) {
            return false;
        }
    }

    private setClients(clients: User[]) {
        this.clients = clients.map(i => new User(i));
    }

    private setSelectedClient(client: User) {
        this.selectedClient = client;
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    removeErrorMessage() {
        this.errorMessage = undefined;
    }

    private setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default ClientsStore;
