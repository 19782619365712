import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { createStyledTheme } from "services/themes/theme";

const StyledThemeWrapper: React.FC = props => {
    const { children } = props;
    const theme = createStyledTheme(false);
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default StyledThemeWrapper;
