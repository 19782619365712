import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import React, { useCallback } from "react";
import { Controller } from "react-hook-form";

import { SelectControllerProps } from "./interfaces";
import useStyles from "./styles";

const SelectController: React.FC<SelectControllerProps> = props => {
    const { name, control, label, menuItems, formControlProps, selectProps } =
        props;

    const classes = useStyles();

    const render = useCallback(
        ({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl {...formControlProps} error={error}>
                <InputLabel id="select-label">{label}</InputLabel>
                <Select
                    labelId="select-label"
                    value={value}
                    onChange={onChange}
                    label={label}
                    MenuProps={{
                        classes: { paper: classes.menuPaper },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    {...selectProps}
                >
                    {menuItems.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
        ),
        [props]
    );
    return <Controller control={control} name={name} render={render} />;
};
export default SelectController;
