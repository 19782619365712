import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { User } from "services/models/UserModels";

import * as Api from "./api";

class UsersStore {
    user?: User;
    needUpdate = false;
    errorMessage?: string;

    async getUserById(id: number) {
        try {
            const { data } = await Api.getUserById(id);
            this.setUser(data);
            this.setUpdateRequest(false);
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async updateUser(id: number, users: any) {
        try {
            const { data } = await Api.updateUser(id, users);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    async deleteUser(id: number) {
        try {
            const { data } = await Api.deleteUser(id);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {
            this.setErrorMessage(error?.response?.data?.Message);
        }
    }

    private setUser(user: User) {
        this.user = new User(user);
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    removeErrorMessage() {
        this.errorMessage = undefined;
    }

    private setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default UsersStore;
