import { ToolsModal } from "components/organisms/Modals/ToolsModal";
import {
    BaseTable,
    getBaseActionsControls,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import SistemRole from "services/enums/SistemRole";
import { Tool } from "services/models/ToolModel";
import { useStore } from "stores";

import ToolsTableControles from "./components/ToolsTableControles";

const ToolsTable: React.FC<any> = props => {
    const { searchString } = props;
    const { toolsStore, loginStore } = useStore();
    const canAdd = loginStore.user?.role === SistemRole.ADMIN;
    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const {
        isModalOpen,
        closeModal,
        action,
        selectedItemId,
        onViewClick,
        onUpdateClick
    } = getBaseActionsControls();

    const onDeleteClick = useCallback(
        selectedId => () => {
            toolsStore.deleteTool(selectedId);
        },
        []
    );

    const toolsHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "name", label: "Name", sorted: true },
        { id: "description", label: "Description", sorted: true },
        { id: "use", label: "Purpose", sorted: true },

        { id: "controls", label: "", sorted: false }
    ];

    const getToolsRowContent = useCallback(
        (row: Tool) => ({
            rowContent: [
                row.id,
                row.name,
                row.description,
                row.use,
                <>
                    <ToolsTableControles
                        canEdit={canAdd}
                        onViewClick={onViewClick(row.id)}
                        onDeleteClick={onDeleteClick(row.id)}
                        onUpdateClick={onUpdateClick(row.id)}
                    />
                </>
            ]
        }),
        []
    );

    const getTools = useCallback(() => {
        toolsStore.getTools(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getTools();
    }, [getTools]);

    useEffect(() => {
        if (toolsStore.needUpdate) {
            getTools();
        }
    }, [getTools, toolsStore.needUpdate]);

    const toolsRowCells = toolsStore.tools.map(row => getToolsRowContent(row));

    return (
        <>
            <BaseTable
                headCells={toolsHeadCells}
                rowCells={toolsRowCells}
                // TODO add totalCount and pagination
                rowCellsСount={toolsStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
            <ToolsModal
                isModalOpen={isModalOpen}
                handleClose={closeModal}
                selectedToolId={selectedItemId}
                action={action}
            />
        </>
    );
};

export default observer(ToolsTable);
