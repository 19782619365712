import { yupResolver } from "@hookform/resolvers/yup";
import { toJS } from "mobx";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useStore } from "stores";
import * as yup from "yup";

function getToolsSelectMenuItems(tools) {
    const toolsSelectMenuItems = tools.map(i => {
        return { value: i.id, label: i.name };
    });
    return toolsSelectMenuItems;
}

export function getCleaningTypeFormControls(cleaningType, onSubmit) {
    const { toolsStore } = useStore();

    useEffect(() => {
        // TODO
        toolsStore.getTools();
    }, []);

    const toolsSelectMenuItems = useMemo(
        () => getToolsSelectMenuItems(toolsStore.tools),
        [toolsStore.tools]
    );

    const validators = yup.object().shape({
        name: yup.string().trim().required("Field is required").nullable(),
        toolsIds: yup.array().of(yup.number().nullable())
    });

    const { control, handleSubmit } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: {
            id: cleaningType.id,
            name: cleaningType.name,
            toolsIds: cleaningType.tools.map(i => i.id)
        }
    });

    const onFormSubmit = handleSubmit(data => {
        onSubmit(data);
    });

    return {
        control,
        onFormSubmit,
        toolsSelectMenuItems
    };
}
