import Rating from "@material-ui/lab/Rating";
import React, { useCallback } from "react";
import { Controller } from "react-hook-form";

import { RatingControllerProps } from "./interfaces";

const RatingController: React.FC<RatingControllerProps> = props => {
    const { name, control, ...rest } = props;

    const render = useCallback(
        ({ field: { onChange, value } }) => (
            <Rating {...rest} value={value} onChange={onChange} />
        ),
        [rest]
    );
    return <Controller control={control} name={name} render={render} />;
};
export default RatingController;
