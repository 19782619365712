import { NfcWorkTracker } from "../NfcWorkTracker";
import { Order } from "../OrderModel";
import { TimeDifference } from "../TimeDifference";

class Audit {
    public orderViewModel: Order;
    public orderDuration: TimeDifference;
    public work: TimeDifference[];
    public nfc: NfcWorkTracker[];

    constructor(model?: Partial<Audit>) {
        Object.assign(this, model);
    }
}

export default Audit;
