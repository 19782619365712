import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { OrderReport } from "services/models/OrderReportModel";

import * as Api from "./api";

class OrderReportsStore {
    totalCount?: number;
    orderReports: OrderReport[] = [];
    needUpdate = false;

    async getOrderReports(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            const { data } = await Api.getOrderReports(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );

            this.setOrderReports(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {}
    }

    private setOrderReports(orderReports: any[]) {
        this.orderReports = orderReports.map(i => new OrderReport(i));
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default OrderReportsStore;
