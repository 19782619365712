enum SistemRole {
    ADMIN,
    MANAGER,
    EMPLOYEE,
    CLIENT
}

export const getSistemRoleLabel = (action?: SistemRole) => {
    switch (action) {
        case SistemRole.ADMIN:
            return "Admin";
        case SistemRole.MANAGER:
            return "Manager";
        case SistemRole.EMPLOYEE:
            return "Employee";
        case SistemRole.CLIENT:
            return "Client";
        default:
            return null;
    }
};

export const sistemRoleMenuItems = [
    { value: SistemRole.ADMIN, label: "Admin" },
    { value: SistemRole.MANAGER, label: "Manager" },
    { value: SistemRole.EMPLOYEE, label: "Employee" },
    { value: SistemRole.CLIENT, label: "Client" }
];

export default SistemRole;
