class NfcTag {
    public id = 0;
    public tagId = "";
    public tagName = "";
    public roomId = 0;
    public clientId: number | null = null;
    public clientName = "";
    public address = "";
    public clientObjectId: number | null = null;

    constructor(model?: Partial<NfcTag>) {
        Object.assign(this, model);
    }
}

export default NfcTag;
