import { makeAutoObservable } from "mobx";

import * as Api from "./api";

class FilesStore {
    loadedFiles: Map<number, any> = new Map();

    async getFile(id: number) {
        try {
            if (this.loadedFiles.has(id)) {
                return this.loadedFiles.get(id);
            } else {
                const response = await Api.getFile(id);
                const url = URL.createObjectURL(response.data);
                this.loadedFiles.set(id, url);
                return url;
            }
        } catch (error: any) {
            return false;
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default FilesStore;
