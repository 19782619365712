import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Tab,
    Tabs
} from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import React, { useCallback, useState } from "react";
import {
    DashboardTabs,
    getDashboardTabsLabel
} from "services/enums/DashboardTabs";

import { AlertTab } from "../AlertTab";
import { FeedBackTab } from "../FeedBackTab";
import { OrderReportTab } from "../OrderReportTab";
import { OrderTab } from "../OrderTab";

const DashboardContent: React.FC<any> = props => {
    const {
        selectedOrderDate,
        selectedAlertDate,
        selectedTabIndex,
        setSelectedTabIndex
    } = props;

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);

    const [showAllList, setShowAllList] = useState(true);

    const handleSwitchAll = useCallback(() => {
        setShowAllList(!showAllList);
    }, [showAllList]);

    return (
        <Paper elevation={3}>
            <Box pt={2} px={2}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Tabs
                        value={selectedTabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            label={getDashboardTabsLabel(DashboardTabs.ORDERS)}
                            value={DashboardTabs.ORDERS}
                        />
                        <Tab
                            label={getDashboardTabsLabel(DashboardTabs.ALERTS)}
                            value={DashboardTabs.ALERTS}
                        />
                        <Tab
                            label={getDashboardTabsLabel(
                                DashboardTabs.FEEDBACK
                            )}
                            value={DashboardTabs.FEEDBACK}
                        />
                        <Tab
                            label={getDashboardTabsLabel(DashboardTabs.REPORT)}
                            value={DashboardTabs.REPORT}
                        />
                    </Tabs>

                    <TabPanel
                        value={selectedTabIndex}
                        index={DashboardTabs.ORDERS}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showAllList}
                                    onChange={handleSwitchAll}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="All list"
                        />
                    </TabPanel>
                </Grid>
            </Box>
            <TabPanel value={selectedTabIndex} index={DashboardTabs.ORDERS}>
                <OrderTab
                    showAllList={showAllList}
                    selectedDate={selectedOrderDate}
                />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={DashboardTabs.ALERTS}>
                <AlertTab selectedDate={selectedAlertDate} />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={DashboardTabs.FEEDBACK}>
                <FeedBackTab />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={DashboardTabs.REPORT}>
                <OrderReportTab />
            </TabPanel>
        </Paper>
    );
};

export default DashboardContent;
