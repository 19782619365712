import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Actions from "services/enums/Actions";
import OrderStatus from "services/enums/OrderStatus";
import { useStore } from "stores";
import * as yup from "yup";

function getFormControls(
    selectedOrder,
    selectedCleaningTypesToOrder,
    currentManagerId,
    action
) {
    const dateNow = DateTime.now().toString().slice(0, 16);

    const validators = yup.object().shape({
        clientId: yup.number().required("Field is required"),
        clientObjectId: yup.number().required("Field is required"),
        employeeId: yup.number().nullable(),
        extectedDate: yup
            .string()
            .trim()
            .required("Field is required")
            .test(
                "dateTest",
                "ExtectedDate can't be less than Now",
                function (value) {
                    const d1 = Date.parse(dateNow);
                    const d2 = Date.parse(String(value));
                    return d1 < d2;
                }
            )
            .nullable(),
        cleaningTypesIds: yup.array().of(yup.number().nullable()),
        comment: yup.string().trim().nullable()
    });

    const { control, handleSubmit, watch, reset, getValues } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validators),
        defaultValues: {
            id: selectedOrder.id,
            clientId: selectedOrder.clientId,
            clientObjectId: selectedOrder.clientObjectId,
            employeeId: selectedOrder.employeeId,
            managerId: currentManagerId,
            state: selectedOrder.state,
            createdDate: dateNow,
            clientObjectAlertId: selectedOrder.clientObjectAlertId,
            extectedDate:
                !selectedOrder.extectedDate && action === Actions.CREATE
                    ? dateNow
                    : selectedOrder.extectedDate,
            cleaningTypesIds: [],
            comment: selectedOrder.comment
        }
    });

    // // Temp hack
    useEffect(() => {
        reset({
            ...getValues(),
            cleaningTypesIds: selectedCleaningTypesToOrder?.map(i => i.id) ?? []
        });
    }, [selectedCleaningTypesToOrder]);

    return {
        control,
        handleSubmit,
        watch
    };
}

function getCleaningTypeSelectMenuItems(cleaningTypes) {
    const cleaningTypeSelectMenuItems = cleaningTypes.map(i => {
        return { value: i.id, label: i.name };
    });
    return cleaningTypeSelectMenuItems;
}

export function getOrderFormControls(
    selectedOrder,
    selectedCleaningTypesToOrder,
    action,
    onSubmit
) {
    const {
        ordersStore,
        clientsStore,
        сlientObjectsStore,
        employeesStore,
        managersStore,
        loginStore,
        cleaningTypesStore
    } = useStore();

    useEffect(() => {
        employeesStore.getEmployees();
        managersStore.getManagers();
        сlientObjectsStore.getClientObjects();
        clientsStore.getClients();
        cleaningTypesStore.getCleaningTypes();
    }, []);

    const currentManagerId = loginStore.user?.id;
    const selectedOrderId = selectedOrder.id;
    const disabled = action === Actions.VIEW;

    const { control, handleSubmit, watch } = getFormControls(
        selectedOrder,
        selectedCleaningTypesToOrder,
        currentManagerId,
        action
    );

    const onFormSubmit = handleSubmit(data => {
        if (action === Actions.UPDATE) {
            ordersStore.updateOrder(Number(selectedOrderId), data);
        }
        if (action === Actions.CREATE) {
            ordersStore.addOrder(data);
        }
        onSubmit();
    });

    const onFormSend = handleSubmit(data => {
        if (action === Actions.UPDATE) {
            ordersStore.updateOrder(Number(selectedOrderId), {
                ...data,
                state: OrderStatus.APPOINTED
            });
        }
        if (action === Actions.CREATE) {
            ordersStore.addOrder({
                ...data,
                state: OrderStatus.APPOINTED
            });
        }
        onSubmit();
    });

    const cleaningTypeSelectMenuItems = useMemo(
        () => getCleaningTypeSelectMenuItems(cleaningTypesStore.cleaningTypes),
        [cleaningTypesStore.cleaningTypes]
    );

    const clientSelectMenuItems = useMemo(
        () =>
            clientsStore.clients.map(i => {
                return { value: i.id, label: i.name };
            }),
        [clientsStore.clients]
    );

    const watchClientId = watch("clientId");
    const watchClientObjectId = watch("clientObjectId");
    const watchEployeeId = watch("employeeId");

    const sendDisabled =
        selectedOrder.state !== OrderStatus.ON_HOLD || !watchEployeeId;

    const objectAddress = сlientObjectsStore.clientObjects
        .filter(i => i.id === watchClientObjectId)
        .map(i => i.address);

    const objectSelectMenuItems = useMemo(
        () =>
            сlientObjectsStore.clientObjects
                .filter(i => i.clientId === watchClientId)
                .map(i => {
                    return { value: i.id, label: i.name };
                }),
        [сlientObjectsStore.clientObjects, watchClientId]
    );

    const clientObjectIdDisabled = useMemo(
        () => objectSelectMenuItems.length === 0 || disabled,
        [objectSelectMenuItems, disabled]
    );

    const employeeSelectMenuItems = useMemo(
        () =>
            employeesStore.employees.map(i => {
                return { value: i.id, label: i.name };
            }),
        [employeesStore.employees]
    );

    return {
        onFormSubmit,
        onFormSend,
        sendDisabled,
        control,
        clientSelectMenuItems,
        disabled,
        objectSelectMenuItems,
        clientObjectIdDisabled,
        objectAddress,
        employeeSelectMenuItems,
        cleaningTypeSelectMenuItems
    };
}
