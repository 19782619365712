import { Box, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router";
import Actions from "services/enums/Actions";
import SistemRole from "services/enums/SistemRole";
import { useStore } from "stores";

import { ChangePasswordTab } from "../ChangePasswordTab";
import { ProfileTab } from "../ProfileTab";

const ManagerProfileContent: React.FC<any> = props => {
    const { selectedManager } = props;

    const { loginStore } = useStore();
    const canChangePassword = loginStore.user?.role === SistemRole.ADMIN;

    const { state } = useLocation<{ action?: Actions }>();
    const disabled = state?.action !== Actions.UPDATE;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);
    return (
        <Paper elevation={3}>
            <Box px={2} py={2}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Tabs
                        value={selectedTabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Profile" />
                        {canChangePassword && !disabled && (
                            <Tab label="Change password" />
                        )}
                    </Tabs>
                </Grid>
                <Box pt={2}>
                    <TabPanel value={selectedTabIndex} index={0}>
                        <ProfileTab
                            selectedEmployee={selectedManager}
                            disabled={disabled}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={1}>
                        <ChangePasswordTab selectedEmployee={selectedManager} />
                    </TabPanel>
                </Box>
            </Box>
        </Paper>
    );
};

export default observer(ManagerProfileContent);
