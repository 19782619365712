export const uploadFile = args => {
    const { onUpload } = args;

    const virtualInput = document.createElement("input");

    virtualInput.type = "file";
    virtualInput.accept = "image/png, image/gif, image/jpeg, image/jpg";

    virtualInput.onchange = () => {
        onUpload(Array.from(virtualInput.files ?? []));
    };

    virtualInput.click();
};
