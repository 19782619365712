import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { ToolsContent } from "./Components/ToolsContent";
import { ToolsHeader } from "./Components/ToolsHeader";

const Tools: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<ToolsHeader />}
                contentComponent={<ToolsContent />}
            />
        </HeaderWrapper>
    );
};

export default Tools;
