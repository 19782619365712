import { CircularProgress } from "@material-ui/core";
import { appUrls } from "App/routes/appUrls";
import { observer } from "mobx-react-lite";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "stores";

import { PrivateRouteProps } from "./interfaces";

const AuthorizedPrivateRoute: React.FC<PrivateRouteProps> = ({
    component: Component,
    ...rest
}) => {
    const { loginStore } = useStore();
    const redirect = !loginStore.user;
    const loading = !loginStore.authorizationChecked;
    return (
        <>
            {loading ? (
                <CircularProgress />
            ) : (
                <Route
                    {...rest}
                    render={
                        //props => <Component {...props} />
                        props =>
                            redirect ? (
                                <Redirect to={appUrls.login} />
                            ) : (
                                <Component {...props} />
                            )
                    }
                />
            )}
        </>
    );
};

export default observer(AuthorizedPrivateRoute);
