enum AlertState {
    NEW,
    CONFIRMED,
    ATTENTION,
    DONE
}

export const getAlertStateLabel = (action: AlertState) => {
    switch (action) {
        case AlertState.NEW:
            return "new";
        case AlertState.CONFIRMED:
            return "confirmed";
        case AlertState.ATTENTION:
            return "appointed";
        case AlertState.DONE:
            return "done";
    }
};

export const alertStateMenuItems = [
    { value: AlertState.NEW, label: "new" },
    { value: AlertState.CONFIRMED, label: "confirmed" },
    { value: AlertState.ATTENTION, label: "appointed" },
    { value: AlertState.DONE, label: "done" }
];

export default AlertState;
