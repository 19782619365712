import { Box, Paper } from "@material-ui/core";
import { SearchField } from "components/molecules/SearchField";
import { NfcTagsTable } from "components/organisms/Tables/NfcTagsTable";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const NfcTagsContent: React.FC = () => {
    const [searchValue, setSearchValue] = useState("");

    return (
        <Paper elevation={3}>
            <Box p={2}>
                <SearchField
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
            </Box>
            <NfcTagsTable searchString={searchValue} />
        </Paper>
    );
};

export default observer(NfcTagsContent);
