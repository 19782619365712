import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ConfirmActionDialogWrapper } from "components/molecules/ConfirmActionDialogWrapper";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

const TrainingsTableControles: React.FC<any> = props => {
    const { canEdit, onViewClick, onDeleteClick, onUpdateClick } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = useCallback((event: any) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const itemClick = useCallback(
        actionCallback => () => {
            actionCallback();
            handleClose();
        },
        []
    );

    return (
        <>
            <IconButton onClick={handleClick} color="primary">
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={itemClick(onViewClick)}>{"view"}</MenuItem>
                <MenuItem onClick={itemClick(onUpdateClick)}>{"edit"}</MenuItem>
                {canEdit && (
                    <ConfirmActionDialogWrapper
                        onConfirmClick={itemClick(onDeleteClick)}
                    >
                        {openConfirmActionDialog => (
                            <MenuItem onClick={openConfirmActionDialog}>
                                {"delete"}
                            </MenuItem>
                        )}
                    </ConfirmActionDialogWrapper>
                )}
            </Menu>
        </>
    );
};
export default observer(TrainingsTableControles);
