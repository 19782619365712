import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";

import { ClientContent } from "./Components/ClientContent";
import { ClientHeader } from "./Components/ClientHeader";

const Clients: React.FC = () => {
    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={<ClientHeader />}
                contentComponent={<ClientContent />}
            />
        </HeaderWrapper>
    );
};

export default Clients;
