import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getAdmins = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    searchString?: string
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/Admins${params}`);
};
export const getAdmin = (id: number) => {
    return $axios.get(`/api/Admins/${id}`);
};

export const setAdmin = (data: any) => {
    return $axios.post(`/api/Admins/SignUp`, data);
};

export const updateAdmin = (id: number, data: any) => {
    return $axios.put(`/api/Admins/${id}`, data);
};

export const deleteAdmin = (id: number) => {
    return $axios.delete(`/api/Admins/${id}`);
};

export const updatePassword = (data: any) => {
    return $axios.post(`/api/Admins/ChangePassword`, data);
};

export const getPhoto = (id: number) => {
    return $axios.get(`/api/Admins/${id}/photo`, {
        responseType: "blob"
    });
};

export const setPhoto = (id: number, data: any) => {
    return $axios.post(`/api/Admins/${id}/photo`, data);
};
