import { Chip } from "@material-ui/core";
import { ConfirmActionDialogWrapper } from "components/molecules/ConfirmActionDialogWrapper";
import { ClientObjectModal } from "components/organisms/Modals/ClientObjectModal";
import {
    BaseTable,
    getBaseActionsControls,
    getBaseTableControls
} from "components/organisms/Tables/BaseTable";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import SistemRole from "services/enums/SistemRole";
import { ClientObject } from "services/models/ClientObjectModel";
import { useStore } from "stores";

const ClientObjectsTable: React.FC<any> = props => {
    const { searchString } = props;
    const { сlientObjectsStore, loginStore } = useStore();

    const canAdd = loginStore.user?.role === SistemRole.ADMIN;

    const {
        order,
        sortBy,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort
    } = getBaseTableControls();

    const {
        isModalOpen,
        closeModal,
        action,
        selectedItemId,
        onUpdateClick,
        onViewClick
    } = getBaseActionsControls();

    const onDeleteClick = useCallback(
        selectedId => () => {
            сlientObjectsStore.deleteClientObject(selectedId);
        },
        []
    );

    const clientObjectsHeadCells = [
        { id: "id", label: "Id", sorted: true },
        { id: "clientName", label: "Client", sorted: true },
        { id: "name", label: "Object's name", sorted: true },
        { id: "address", label: "Address", sorted: true },
        { id: "roomsCount", label: "Number of rooms", sorted: true },
        { id: "totalSquare", label: "Total square", sorted: true },
        { id: "controls", label: "", sorted: false }
    ];

    const getClientObjectsRowContent = useCallback(
        (row: ClientObject) => ({
            rowContent: [
                row.id,
                row.clientName,
                row.name,
                row.address,
                row.roomsCount,
                row.totalSquare,
                <>
                    <Chip
                        style={{ margin: "2px" }}
                        size="small"
                        label="view"
                        onClick={onViewClick(row.id)}
                    />
                    {canAdd && (
                        <>
                            <Chip
                                style={{ margin: "2px" }}
                                size="small"
                                label="edit"
                                onClick={onUpdateClick(row.id)}
                            />
                            <ConfirmActionDialogWrapper
                                onConfirmClick={onDeleteClick(row.id)}
                            >
                                {openConfirmActionDialog => (
                                    <Chip
                                        style={{ margin: "2px" }}
                                        size="small"
                                        label="delete"
                                        onClick={openConfirmActionDialog}
                                    />
                                )}
                            </ConfirmActionDialogWrapper>
                        </>
                    )}
                </>
            ]
        }),
        []
    );

    const getClientObjects = useCallback(() => {
        сlientObjectsStore.getClientObjects(
            page * rowsPerPage,
            rowsPerPage,
            sortBy,
            order,
            searchString
        );
    }, [page, rowsPerPage, sortBy, order, searchString]);

    useEffect(() => {
        getClientObjects();
    }, [getClientObjects]);

    useEffect(() => {
        if (сlientObjectsStore.needUpdate) {
            getClientObjects();
        }
    }, [getClientObjects, сlientObjectsStore.needUpdate]);

    const clientObjectsRowCells = сlientObjectsStore.clientObjects.map(row =>
        getClientObjectsRowContent(row)
    );

    return (
        <>
            <BaseTable
                headCells={clientObjectsHeadCells}
                rowCells={clientObjectsRowCells}
                // TODO add totalCount and pagination
                rowCellsСount={сlientObjectsStore.totalCount ?? 0}
                tableControls={{
                    order,
                    sortBy,
                    page,
                    rowsPerPage,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort
                }}
            />
            <ClientObjectModal
                isModalOpen={isModalOpen}
                selectedClientObjectId={selectedItemId}
                action={action}
                handleClose={closeModal}
            />
        </>
    );
};

export default observer(ClientObjectsTable);
