import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: "flex",
            height: "100%"
        },
        content: {
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column"
        },
        header: {
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(2.5, 5)
        },
        children: {
            flexGrow: 1,
            overflow: "hidden"
        }
    })
);
