import { CircularProgress } from "@material-ui/core";
import { OrderForm } from "components/organisms/Forms/OrderForm";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Actions from "services/enums/Actions";
import { Order } from "services/models/OrderModel";
import { useStore } from "stores";

import { BaseModal } from "../BaseModal";

const OrderModal: React.FC<any> = props => {
    const { isModalOpen, handleClose, selectedOrderId, action } = props;
    const { ordersStore } = useStore();

    useEffect(() => {
        if (selectedOrderId && isModalOpen) {
            ordersStore.getOrderById(selectedOrderId);
            ordersStore.getCleaningTypesToOrder(selectedOrderId);
        }
    }, [selectedOrderId, isModalOpen]);

    const selectedOrder =
        action === Actions.CREATE ? new Order() : ordersStore.selectedOrder;
    return (
        <BaseModal isModalOpen={isModalOpen} handleClose={handleClose}>
            {ordersStore.loaded && isModalOpen ? (
                selectedOrder ? (
                    <OrderForm
                        selectedOrder={selectedOrder}
                        selectedCleaningTypesToOrder={
                            ordersStore.selectedCleaningTypesToOrder
                        }
                        action={action}
                        onSubmit={handleClose}
                    />
                ) : (
                    "Order not found"
                )
            ) : (
                <CircularProgress />
            )}
        </BaseModal>
    );
};
export default observer(OrderModal);
