import { Box, FormHelperText, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { SelectController } from "components/molecules/FormControls/SelectController";
import { TextFieldController } from "components/molecules/FormControls/TextFieldController";
import { observer } from "mobx-react-lite";
import React from "react";
import { getActionLabel } from "services/enums/Actions";

import { getClientObjectFormControls } from "./ClientObjectForm.utils";
import { ClientObjectFormProps, ClientObjectFormCallProps } from "./interfaces";

const ClientObjectForm: React.FC<
    ClientObjectFormProps & ClientObjectFormCallProps
> = props => {
    const { selectedClientObjectId, action, onSubmit } = props;

    const {
        control,
        onFormSubmit,
        disabled,
        clientSelectMenuItems,
        roomsField,
        handleAddRoom,
        handleRemoveRoom,
        errors
    } = getClientObjectFormControls(selectedClientObjectId, action, onSubmit);

    const roomsErrorMessage = errors.rooms?.message;

    return (
        <form onSubmit={onFormSubmit}>
            <SelectController
                control={control}
                name="clientId"
                label="Client"
                menuItems={clientSelectMenuItems}
                formControlProps={{
                    disabled,
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true
                }}
            />
            <TextFieldController
                control={control}
                disabled={disabled}
                name="name"
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Object's name"
            />
            <TextFieldController
                control={control}
                disabled={disabled}
                name="address"
                variant="outlined"
                margin="normal"
                fullWidth
                id="address"
                label="Address"
            />
            {roomsField.map((item, index) => {
                return (
                    <Grid container direction="row" key={item.id} spacing={1}>
                        <Grid item xs>
                            <TextFieldController
                                control={control}
                                disabled={disabled}
                                name={`rooms.${index}.name`}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={`rooms.${index}.name`}
                                label="Room name"
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldController
                                control={control}
                                disabled={disabled}
                                name={`rooms.${index}.surfaceArea`}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={`rooms.${index}.surfaceArea`}
                                label="Surface area"
                                type="number"
                            />
                        </Grid>
                        {!disabled && (
                            <Grid item>
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleRemoveRoom(index)}
                                    >
                                        {"Delete"}
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
            {!disabled && (
                <>
                    <Box mb={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddRoom}
                        >
                            {"Add room"}
                        </Button>
                        {roomsField.length === 0 && (
                            <FormHelperText error={!!roomsErrorMessage}>
                                {roomsErrorMessage}
                            </FormHelperText>
                        )}
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        {getActionLabel(action)}
                    </Button>
                </>
            )}
        </form>
    );
};
export default observer(ClientObjectForm);
