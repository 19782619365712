import {
    DeviceForm,
    DeviceFormProps
} from "components/organisms/Forms/DeviceForm";
import React from "react";

import { BaseModal, BaseModalCallProps, BaseModalProps } from "../BaseModal";

const DeviceModal: React.FC<
    DeviceFormProps & BaseModalProps & BaseModalCallProps
> = props => {
    const { isModalOpen, handleClose, selectedDeviceId, action } = props;
    return (
        <BaseModal isModalOpen={isModalOpen} handleClose={handleClose}>
            <DeviceForm
                selectedDeviceId={selectedDeviceId}
                action={action}
                onSubmit={handleClose}
            />
        </BaseModal>
    );
};
export default DeviceModal;
