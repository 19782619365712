import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { ClientProfileHeaderProps } from "./interfaces";

const ClientProfileHeader: React.FC<ClientProfileHeaderProps> = props => {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">{props.header}</Typography>
        </Grid>
    );
};

export default observer(ClientProfileHeader);
