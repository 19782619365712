export enum DashboardTabs {
    ORDERS,
    ALERTS,
    FEEDBACK,
    REPORT
}

export const getDashboardTabsLabel = (tab: DashboardTabs) => {
    switch (tab) {
        case DashboardTabs.ORDERS:
            return "Orders";
        case DashboardTabs.ALERTS:
            return "Alerts";
        case DashboardTabs.FEEDBACK:
            return "Feedback";
        case DashboardTabs.REPORT:
            return "Report";
    }
};
