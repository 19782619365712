import { ClientObjectRoom } from "../ClientObjectRoomModel";

class ClientObject {
    public id = 0;
    public clientId = 0;
    public clientName = "";
    public name = "";
    public address = "";
    public roomsCount = 0;
    public totalSquare = 0;
    public clientObjectTypeId = "";
    public rooms: ClientObjectRoom[] = [];

    constructor(model?: Partial<ClientObject>) {
        Object.assign(this, model);
        this.roomsCount = model?.rooms?.length ?? 0;
        this.totalSquare =
            model?.rooms?.reduce(
                (sum, current) => sum + current.surfaceArea,
                0
            ) ?? 0;
    }
}

export default ClientObject;
