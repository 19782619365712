import { ImageList } from "@material-ui/core";
import React from "react";

import { ImageReportListItem } from "../ImageReportListItem";
import useStyles from "./styles";

const ImageReportList: React.FC<any> = props => {
    const { photoReports } = props;
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <ImageList className={classes.imageList} cols={2}>
                {photoReports.map(id => (
                    <ImageReportListItem key={id} photoReportId={id} />
                ))}
            </ImageList>
        </div>
    );
};
export default ImageReportList;
