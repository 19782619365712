import React from "react";

import {
    ClientObjectForm,
    ClientObjectFormProps
} from "../../Forms/ClientObjectForm";
import { BaseModal, BaseModalCallProps, BaseModalProps } from "../BaseModal";

const ClientObjectModal: React.FC<
    ClientObjectFormProps & BaseModalProps & BaseModalCallProps
> = props => {
    const { isModalOpen, handleClose, selectedClientObjectId, action } = props;
    return (
        <BaseModal isModalOpen={isModalOpen} handleClose={handleClose}>
            <ClientObjectForm
                selectedClientObjectId={selectedClientObjectId}
                action={action}
                onSubmit={handleClose}
            />
        </BaseModal>
    );
};
export default ClientObjectModal;
