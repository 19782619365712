import SortDirection from "services/enums/SortDirection";
import { $axios } from "services/http";

export const getClientObjects = (
    skip = 0,
    take?: number,
    sortBy?: string,
    direction?: SortDirection,
    searchString?: string
) => {
    let params = `?Skip=${skip}`;
    if (take) params += `&Take=${take}`;
    if (sortBy) params += `&SortBy=${sortBy}`;
    if (direction) params += `&SortDirection=${direction}`;
    if (searchString) params += `&Query=${searchString}`;
    return $axios.get(`/api/ClientObjects${params}`);
};

export const getClientObjectById = (id: number) => {
    return $axios.get(`/api/ClientObjects/${id}`);
};

export const setClientObject = (data: any) => {
    return $axios.post(`/api/ClientObjects`, data);
};

export const updateClientObject = (id: number, data: any) => {
    return $axios.put(`/api/ClientObjects/${id}`, data);
};

export const deleteClientObject = (id: number) => {
    return $axios.delete(`/api/ClientObjects/${id}`);
};
