import { makeAutoObservable } from "mobx";
import SortDirection from "services/enums/SortDirection";
import { ClientObjectAlert } from "services/models/ClientObjectAlertModels";
import { Feedback } from "services/models/FeedbackModel";

import * as Api from "./api";

class FeedbacksStore {
    totalCount?: number;
    feedbacks: Feedback[] = [];
    needUpdate = false;

    async getFeedbacks(
        skip?: number,
        take?: number,
        sortBy?: string,
        direction?: SortDirection,
        searchString?: string
    ) {
        try {
            const { data } = await Api.getFeedbacks(
                skip,
                take,
                sortBy,
                direction,
                searchString
            );
            this.setFeedbacks(data.result);
            this.setTotalCount(Number(data.totalCount));
            this.setUpdateRequest(false);
        } catch (error: any) {}
    }

    async addFeedbacks(feedback: any) {
        try {
            const { data } = await Api.setFeedbacks(feedback);
            if (data) {
                this.setUpdateRequest(true);
            }
        } catch (error: any) {}
    }

    private setFeedbacks(feedbacks: Feedback[]) {
        this.feedbacks = feedbacks.map(i => new Feedback(i));
    }

    private setUpdateRequest(needUpdate: boolean) {
        this.needUpdate = needUpdate;
    }

    private setTotalCount(count: number) {
        this.totalCount = count;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default FeedbacksStore;
