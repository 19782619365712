import { Menu, MenuItem, Typography } from "@material-ui/core";
import { appUrls } from "App/routes/appUrls";
import { TableUserAvatar } from "components/atoms/TableUserAvatar";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { getSistemRoleLabel } from "services/enums/SistemRole";
import { useStore } from "stores";

import useStyles from "./styles";

const CurrentUser: React.FC = () => {
    const classes = useStyles();
    const { loginStore } = useStore();

    const name = loginStore.user?.name;
    const role = getSistemRoleLabel(loginStore.user?.role);
    const avatarSrc = loginStore.avatarSrc;

    const logout = useCallback(() => {
        if (loginStore.user) {
            loginStore.logout();
        }
    }, [loginStore.user]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();
    const openModalProfile = () => {
        handleClose();
        history.push(appUrls.myProfile);
    };

    return (
        <>
            <div className={classes.wrapper} onClick={handleClick}>
                <TableUserAvatar logoSrc={avatarSrc} loaded={true} />
                <div className={classes.content}>
                    <Typography variant="body2">{name}</Typography>
                    <Typography variant="caption" color="textSecondary">
                        {role}
                    </Typography>
                </div>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={openModalProfile}>{"Account"}</MenuItem>
                <MenuItem onClick={logout}>{"Logout"}</MenuItem>
            </Menu>
        </>
    );
};
export default observer(CurrentUser);
