import { Box, Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { TabPanel } from "components/atoms/TabPanel";
import { BasePage } from "components/molecules/BasePage";
import { HeaderWrapper } from "components/molecules/HeaderWrapper";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useStore } from "stores";

import { ChangeProfilePasswordTab } from "./Components/ChangeProfilePasswordTab";
import { ProfileTab } from "./Components/ProfileTab";

const MyProfile: React.FC = () => {
    const { loginStore } = useStore();

    const selectedUser = loginStore.user;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabChange = useCallback((event, index) => {
        setSelectedTabIndex(index);
    }, []);

    return (
        <HeaderWrapper>
            <BasePage
                headerComponent={
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="h5">{"Your profile"}</Typography>
                    </Grid>
                }
                contentComponent={
                    <Paper elevation={3}>
                        <Box px={2} py={2}>
                            <Grid
                                container
                                justify="space-between"
                                alignItems="baseline"
                            >
                                <Tabs
                                    value={selectedTabIndex}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Profile" />

                                    <Tab label="Change password" />
                                </Tabs>
                            </Grid>
                            <Box pt={2}>
                                <TabPanel value={selectedTabIndex} index={0}>
                                    <ProfileTab selectedUser={selectedUser} />
                                </TabPanel>
                                <TabPanel value={selectedTabIndex} index={1}>
                                    <ChangeProfilePasswordTab
                                        selectedUser={selectedUser}
                                    />
                                </TabPanel>
                            </Box>
                        </Box>
                    </Paper>
                }
            />
        </HeaderWrapper>
    );
};

export default observer(MyProfile);
